import React from "react";
import { Grid, Paper } from "@mui/material";
import classes from "./ExploreOurServices.module.css";
import { exploreServiceContnet } from "../../common/ExploreServiceContent";


const ExploreOurServices = () => {
  return (
    <>
      <div className={classes.explore_service_container}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div className={classes.explore_contnet}>
              <h1 className={classes.primary_heading}>Explore Our Services</h1>
              <p className={classes.primary_contnet}>
                Our diverse range of AI-powered online courses offers
                personalized learning paths, practice sessions, and efficiency
                enhancements to help you excel.
              </p>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          {exploreServiceContnet.map((cont) => (
            <Grid item xs={12} sm={12} md={12} lg={4}>
              <Paper
                style={{
                  textAlign: "center",
                  height: "100%",
                }}
              >
                <img
                  src={cont.image}
                  alt="programs"
                  className={classes.image}
                />
                <div className={classes.dot_circle}>
                  <h2>&#x2299;</h2>
                  <p>{cont.service}</p>
                </div>
                <div
                  style={{
                    padding: "20px 20px",
                    fontSize: "1.5rem",
                    textAlign: "left",
                  }}
                >
                  <h2>{cont.title}</h2>
                  <p
                    style={{
                      fontWeight: "400",
                      textAlign: "justify",
                      fontSize: "1.7rem",
                      marginTop: "1.5rem",
                    }}
                  >
                    {cont.description}
                  </p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      color: "#12141dcc",
                      fontSize: "1.3rem",
                      fontWeight: "600",
                      marginTop: "3rem",
                    }}
                  >
                    <h2>{cont.serviceGain}</h2>
                    <h2>{cont.serviceGain1}</h2>
                  </div>
                </div>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </div>
    </>
  );
};

export default ExploreOurServices;
