import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GridActionsCellItem } from "@mui/x-data-grid";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import StudentsRowEdit from "./StudentsRowEdit";
import {
  getStudentsData,
  setAddStudents,
  setSelectedStudentsRowData,
  setStudentEditData,
} from "../../../Redux/studentsSlice";
import Table from "../../../common/Table";
import AddStudent from "./AddStudents";
import StudentsRowUpdate from "./StudentRowUpdate";
import { useNavigate } from "react-router-dom";

const StudentsData = () => {
  const studentsData = useSelector(
    (state) => state.studentReducer.studentsData
  );
  const editStudentData = useSelector(
    (state) => state.studentReducer.editStudentData
  );
  const selectedStudentsRowData = useSelector(
    (state) => state.studentReducer.selectedStudentsRowData
  );
  const loading = useSelector((state) => state.studentReducer.loading);
  const toggleOpen = useSelector((state) => state.boolean.toggleOpen);
  const addStudent = useSelector((state) => state.studentReducer.addStudents);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const columns = [
    {
      field: "program",
      headerName: "Program",
      minWidth: 117,
      flex: 1,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "fullName",
      headerName: "Name",
      minWidth: 200,
      flex: 1,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "studentMobileNumber",
      headerName: "Mobile",
      minWidth: 120,
      flex: 1,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "studentEmailAddress",
      headerName: "Email",
      minWidth: 165,
      flex: 1,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "parentName",
      headerName: "Parent Name",
      minWidth: 150,
      flex: 1,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "relationship",
      headerName: "Relationship",
      minWidth: 160,
      flex: 1,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "Syllabus",
      headerName: "Syllabus",
      minWidth: 155,
      flex: 1,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "dob",
      headerName: "Date of Birth",
      minWidth: 160,
      flex: 1,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "schoolName",
      headerName: "School Name",
      minWidth: 160,
      flex: 1,
      align: "left",
      headerAlign: "left",
    },

    {
      field: "currentClass",
      headerName: "Current Class",
      minWidth: 155,
      flex: 1,
      align: "left",
      headerAlign: "left",
    },

    {
      field: "country",
      headerName: "Country",
      minWidth: 155,
      flex: 1,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "state",
      headerName: "State",
      minWidth: 155,
      flex: 1,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "isRegFeePaid",
      headerName: "IsRegFeePaid",
      minWidth: 117,
      flex: 1,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={
            <EditOutlinedIcon
              style={{ fontSize: "2rem", color: "#1a2e44", cursor: "pointer" }}
            />
          }
          label="Edit"
          onClick={() => handleEdit(params.row)}
        />,
        // <GridActionsCellItem
        //   icon={
        //     <DeleteOutlineOutlinedIcon
        //       style={{ fontSize: "2rem", color: "#1a2e44", cursor: "pointer" }}
        //     />
        //   }
        //   label="Delete"
        // />,
      ],
    },
  ];

  const handleEdit = (rowData) => {
    dispatch(setStudentEditData(!editStudentData));
    dispatch(setSelectedStudentsRowData(rowData));
  };

  const handleAddFaculty = () => {
    dispatch(setAddStudents(!addStudent));
  };

  const formattedStudentsData = studentsData.map((student) => ({
    ...student,
    fullName: `${student.studentFirstName} ${student.studentLastName}`,
  }));

  useEffect(() => {
    if (studentsData.length === 0) {
      dispatch(getStudentsData());
    }
  }, [dispatch, studentsData]);

  return (
    <div className={`table_content ${toggleOpen ? "open" : "close"}`}>
      <div className="table_heading">
        <h2>Student details</h2>
      </div>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "25%",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          {/* <button className="add_faculty" onClick={handleAddFaculty}>
            +Add Student
          </button> */}
          <Table
            columns={columns}
            rows={formattedStudentsData}
            getRowId={(row) => row._id}
          />
        </>
      )}
      {addStudent && <AddStudent />}
      {editStudentData && <StudentsRowUpdate />}
    </div>
  );
};

export default StudentsData;
