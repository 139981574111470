import React from "react";
import classes from "./FeatureProjects.module.css";
import { Grid } from "@mui/material";
import Button from "../../common/Button";
import EastIcon from "@mui/icons-material/East";

const FeatureProjects = () => {
  return (
    <>
      <div className={classes.future_project_section}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <h1 className={classes.peimary_heading}>Featured Projects</h1>
          </Grid>
        </Grid>
        <Grid container spacing={7}>
          <Grid item xs={12} sm={12} md={12} lg={5}>
            <div className={classes.aibot}>
              <img
                src="https://res.cloudinary.com/dbyioi2qq/image/upload/v1714071121/lwlj4xrys9h2kcyca55i.jpg"
                alt="aibot"
                style={{
                  height: "65vh",
                  width: "100%",
                  objectFit: "cover",
                  marginBottom: "3rem",
                }}
              />
              <h1>Modernization Project</h1>
              <p>
                Collaborative Workshops elevate business strategies with
                disruptive technology insights. Explore and transform.
              </p>
              <Button className={classes.feature_projects_btn}>
                Learn More
                <EastIcon style={{ fontSize: "2rem" }} />
              </Button>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={7} sx={{ mt: 12 }}>
            <div className={classes.aibot}>
              <img
                src="https://res.cloudinary.com/dbyioi2qq/image/upload/v1714071121/pheiguipe2kh560vfom8.jpg"
                alt="aibot"
                style={{
                  maxWidth: "100%",
                  height: "90vh",
                  objectFit: "cover",
                  marginBottom: "3rem",
                }}
              />
              <h1>Collaboration Project</h1>
              <p>
                Uncover the potential of comprehensive project management
                solutions, streamlining processes and enhancing productivity.
              </p>
              <Button className={classes.feature_projects_btn}>
                Engage Today
                <EastIcon style={{ fontSize: "2rem" }} />
              </Button>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={7}>
          <Grid item xs={12} sm={12} md={12} lg={5} sx={{ mt: -26 }}>
            <div className={classes.aibot}>
              <img
                src="https://res.cloudinary.com/dbyioi2qq/image/upload/v1714071121/vqk4tb6kxcza7myefzyc.jpg"
                alt="aibot"
                style={{
                  height: "65vh",
                  width: "100%",
                  objectFit: "cover",
                  marginBottom: "3rem",
                }}
              />
              <h1>Modernization Project</h1>
              <p>
                Explore how modernization strategies can redefine your IT
                landscape, setting a new standard for efficiency and innovation.
              </p>
              <Button className={classes.feature_projects_btn}>
                Discover More
                <EastIcon style={{ fontSize: "2rem" }} />
              </Button>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={7} sx={{ mt: 7 }}>
            <div className={classes.aibot}>
              <img
                src="https://res.cloudinary.com/dbyioi2qq/image/upload/v1714071121/gtr4demvuecttqjynkhg.jpg"
                alt="aibot"
                style={{
                  width: "100%",
                  height: "55vh",
                  objectFit: "cover",
                  marginBottom: "3rem",
                }}
              />
              <h1>Project Management</h1>
              <p>
                Modernization Strategies focus on the crucial update of IT
                infrastructure for future success.
              </p>
              <Button className={classes.feature_projects_btn}>
                Learn More
                <EastIcon style={{ fontSize: "2rem" }} />
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default FeatureProjects;
