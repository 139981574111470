import React from "react";
import { ErrorMessage, Field, Formik, Form } from "formik";
import { Grid, TextField } from "@mui/material";
import classes from "./ContactFooter.module.css";
import Button from "../Button";
import { contactFormSchema } from "../../utils/validationSchemas/ContactFormSchema";
import { useDispatch } from "react-redux";
import { postContactData } from "../../Redux/ContactFormSlice";

const ContactFooterForm = () => {
  const dispatch = useDispatch();

  const handleContactForm = (values, resetForm) => {
    dispatch(postContactData(values));
    resetForm();
  };

  return (
    <>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          emailAddress: "",
          phoneNumber: "",
          message: "",
        }}
        onSubmit={(values, { resetForm }) => {
          handleContactForm(values, resetForm);
        }}
        validationSchema={contactFormSchema}
      >
        {({ handleSubmit, isValid, values, dirty }) => (
          <Form onSubmit={handleSubmit} className={classes.getintouch_form}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} lg={12}>
                <h1 className={classes.primary_heading}>Get in touch</h1>
                <p className={classes.primary_cont}>
                  Our friendly team would love to hear from you.
                </p>
              </Grid>
            </Grid>
            <Grid container spacing={1} sx={{ mb: 2 }}>
              <Grid item xs={12} md={12} lg={6}>
                <h3 className={classes.form_label}>First Name</h3>
                <Field
                  as={TextField}
                  type="text"
                  placeholder="First Name"
                  name="firstName"
                  fullWidth
                  inputProps={{
                    style: {
                      fontSize: "1.5rem",
                      letterSpacing: "1px",
                      backgroundColor: "#fff",
                    },
                  }}
                />
                <ErrorMessage
                  name="firstName"
                  component="div"
                  className="error"
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <h3 className={classes.form_label}>Last Name</h3>
                <Field
                  type="text"
                  name="lastName"
                  as={TextField}
                  fullWidth
                  variant="outlined"
                  placeholder="Last Name"
                  InputProps={{
                    style: {
                      fontSize: "1.5rem",
                      letterSpacing: "1px",
                      backgroundColor: "#fff",
                      border: "none",
                    },
                  }}
                />
                <ErrorMessage
                  name="lastName"
                  component="div"
                  className="error"
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} sx={{ mb: 2 }}>
              <Grid item xs={12} md={12} lg={6}>
                <h3 className={classes.form_label}>Email</h3>
                <Field
                  as={TextField}
                  type="email"
                  name="emailAddress"
                  fullWidth
                  variant="outlined"
                  placeholder="Email Id"
                  InputProps={{
                    style: {
                      fontSize: "1.5rem",
                      letterSpacing: "1px",
                      backgroundColor: "#fff",
                      border: "none",
                    },
                  }}
                />
                <ErrorMessage
                  name="emailAddress"
                  component="div"
                  className="error"
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <h3 className={classes.form_label}>Phone Number</h3>
                <Field
                  as={TextField}
                  name="phoneNumber"
                  type="number"
                  fullWidth
                  variant="outlined"
                  placeholder="Phone Number"
                  InputProps={{
                    style: {
                      fontSize: "1.5rem",
                      letterSpacing: "1px",
                      backgroundColor: "#fff",
                      border: "none",
                    },
                    onWheel: (event) => event.currentTarget.blur(),
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                  }}
                  sx={{
                    "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                      {
                        WebkitAppearance: "none",
                        margin: 0,
                      },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                  }}
                />
                <ErrorMessage
                  name="phoneNumber"
                  component="div"
                  className="error"
                />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12} lg={12} sx={{ mb: 3 }}>
                <h3 className={classes.form_label}>Message</h3>
                <Field
                  name="message"
                  as={TextField}
                  fullWidth
                  variant="outlined"
                  placeholder="Message"
                  InputProps={{
                    style: {
                      fontSize: "1.5rem",
                      letterSpacing: "1px",
                      backgroundColor: "#fff",
                      border: "none",
                    },
                  }}
                />
                <ErrorMessage
                  name="message"
                  component="div"
                  className="error"
                />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12} lg={12}>
                <Button
                  className={`${classes.submit_btn} ${
                    isValid && dirty ? undefined : classes.disabled_btn
                  }
                  `}
                  disabled={!isValid || !dirty}
                  type="submit"
                >
                  SUBMIT
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ContactFooterForm;
