import React from "react";
import { Grid } from "@mui/material";
import ContactFooterForm from "./ContactFooterForm";
import ContactFooterCont from "./ContactFooterCont";
import CopyRights from "../../pages/HomePage/CopyRights";

const ContactFooter = () => {
  return (
    <>
      <Grid container spacing={0} id="footer_content">
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
          sx={{ backgroundColor: "#f4f6f8" }}
        >
          <ContactFooterForm />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
          sx={{
            backgroundColor: "#aec8d64d",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <ContactFooterCont />
          <CopyRights />
        </Grid>
      </Grid>
    </>
  );
};

export default ContactFooter;
