import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { authHeader } from "../common/authHeader";

export const showSuccessMessage = () => {
  toast.success("Login Successfully!", {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    // transition: "Bounce",
    style: {
      background: "#4CAF50",
      color: "#FFFFFF",
      fontSize: "1.5rem",
    },
  });
};

export const showErrorMessage = () => {
  toast.error("Invalid Email or Password!", {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    // transition: "Bounce",
    style: {
      background: "red",
      color: "#FFFFFF",
      fontSize: "1.5rem",
    },
  });
};

const loginSlice = createSlice({
  name: "login",
  initialState: {
    mobileNumber: "",
    password: "",
    loading: false,
    error: null,
    modelOpen: false,
    token: localStorage.getItem("token") || null,
    studentData: [],
    studentId: null,
    modelRegisterOpen: false,
    userType: "",
  },
  reducers: {
    logUser: (state, action) => {
      return { ...state, ...action.payload };
    },
    startLoading: (state) => {
      state.loading = true;
    },
    loginSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.token = action.payload.token;
      state.studentId = action.payload.Id;
      state.userType = action.payload.userType;
      state.modelOpen = false;
      showSuccessMessage();
    },

    logoutUser: (state) => {
      localStorage.clear();
      // localStorage.removeItem("token");
      // localStorage.removeItem("studentData");
      state.token = null;
      state.studentId = null;
      state.studentData = null;
    },
    loginFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      showErrorMessage();
    },
    setPopupOpen: (state, action) => {
      console.log("Setting modelOpen to:", action.payload);
      state.modelOpen = action.payload;
    },

    setModelOpen: (state, action) => {
      state.modelRegisterOpen = action.payload;
    },
    setStudentData: (state, action) => {
      state.studentData = action.payload;
    },
    setUserType: (state, action) => {
      state.userType = action.payload;
    },
  },
});

export const {
  logUser,
  startLoading,
  loginFailure,
  loginSuccess,
  setPopupOpen,
  setStudentData,
  logoutUser,
  setModelOpen,
  setUserType,
} = loginSlice.actions;
export default loginSlice.reducer;
export const loginUserAsync =
  (studentEmailAddress, password, navigate) => (dispatch) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/auth/login`, {
        studentEmailAddress,
        password,
      })
      .then((response) => {
        const responseData = response.data.data; // Access the `data` object from the response
        if (
          responseData &&
          responseData.data.Id &&
          responseData.data.token &&
          responseData.data.userType
        ) {
          const { Id, token, userType } = responseData.data; // Destructure the Id and token properties

          // window.location.reload();
          if (userType === "Admin") {
            dispatch(
              loginSuccess({ payload: { token, studentId: Id, userType } })
            );
            // showSuccessMessage();
            dispatch(storeToken(token));
            dispatch(setUserType(userType));
            navigate("/dashboard");
          } else {
            alert(
              `Please contact info@certaintyrankacademy.com for login details`
            );
          }

          // Fetch student data using the stored token
          dispatch(fetchStudentData(token, Id));
        } else {
          // Handle case where Id or token is missing in the response
          dispatch(loginFailure("Invalid response from the server"));
          showErrorMessage("Invalid response from the server");
        }
      })
      .catch((error) => {
        // Handle network errors or server errors
        console.error("Error:", error);
        dispatch(loginFailure("Network error"));
        // showErrorMessage("Network error");
      });
  };

// Redux action to store the token in the state
export const storeToken = (token) => (dispatch) => {
  localStorage.setItem("token", token);
  dispatch(logUser({ token }));
};

export const fetchStudentData = (token, studentId) => (dispatch) => {
  const requestOptions = {
    headers: authHeader(),
  };
  axios
    .get(
      `${process.env.REACT_APP_API_URL}/student/${studentId}`,
      requestOptions
    )
    .then((res) => {
      // Store the fetched student data in the state
      dispatch(setStudentData(res.data.data));
    })
    .catch((error) => {
      console.error("Error fetching student data:", error);
    });
};
