import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import krishna from "../../assets/images/KrishnaBayanna modified.png";
import tushaar from "../../assets/images/Tushaar-1.png";
import narayana from "../../assets/images/Capture-removebg-preview (2) (1).png";
import gino from "../../assets/images/gino4.png";
import lakshmi from "../../assets/images/Lakshmi.png";
import classes from "./Directors.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  setDirectorsModalOpen,
  setLaksmiModalOpen,
  setNarayananModalOpen,
} from "../../Redux/excutiveDirectorsSlice";
import ExcutiveDirectorsPage from "../HomePage/ExcutiveDirectorsPage";
import { useNavigate } from "react-router-dom";
import NarayananPage from "../HomePage/NarayananPage";
import CircularProgress from "@mui/material/CircularProgress";
import TusharPage from "../HomePage/TusharPage";
import { setTushaarModalOpen } from "../../Redux/excutiveDirectorsSlice";
import GinoPage from "../HomePage/GinoPage";
import { setGinoModalOpen } from "../../Redux/excutiveDirectorsSlice";
import { LazyImage } from "../../utils/LazyImage";
import LakshmiPage from "../HomePage/LakshmiPage";

const Directors = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const directorsModal = useSelector(
    (state) => state.excutiveDirectorsSlice.directorsModalOpen
  );

  const narayananModal = useSelector(
    (state) => state.excutiveDirectorsSlice.narayananModalOpen
  );
  const tushaarModal = useSelector(
    (state) => state.excutiveDirectorsSlice.tushaarModalOpen
  );
  const ginoModal = useSelector(
    (state) => state.excutiveDirectorsSlice.ginoModalOpen
  );

  const laksmiModal = useSelector(
    (state) => state.excutiveDirectorsSlice.laksmiModal
  );

  const [loading, setLoading] = useState(true);

  const handleKrishnaModel = () => {
    dispatch(setDirectorsModalOpen(!directorsModal));
    navigate("/leadership/krishna");
  };
  const handleNarayananModal = () => {
    dispatch(setNarayananModalOpen(!narayananModal));
    navigate("/leadership/narayanan");
  };

  const handleTushaarModal = () => {
    dispatch(setTushaarModalOpen(!tushaarModal));
    navigate("/leadership/tushaar");
  };
  const handleGinoModal = () => {
    dispatch(setGinoModalOpen(!ginoModal));
    navigate("/leadership/gino");
  };
  const handleLakshmiModal = () => {
    dispatch(setLaksmiModalOpen(!laksmiModal));
    navigate("/leadership/lakshmiMarthala");
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);
    return clearInterval(() => clearTimeout(timer));
  }, []);

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Grid
          container
          spacing={1}
          sx={{ mt: 0.2 }}
          className={
            loading
              ? classes.directors_container_loading
              : classes.directors_container
          }
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className={classes.directors_image_box}>
              <div
                className={classes.directors_image}
                onClick={handleKrishnaModel}
              >
                <LazyImage src={krishna} alt="krishna Bayanna" />
                <div className={classes.person_details}>
                  <div className={classes.person_name}>
                    <h3>Krishna Bayanna</h3>
                    <p>CEO/CTO</p>
                  </div>
                  <div className={classes.linkidin_svg}>
                    <svg
                      data-bbox="0 0 121.999 122"
                      viewBox="0 0 122 122"
                      height="30"
                      width="30"
                      xmlns="http://www.w3.org/2000/svg"
                      data-type="shape"
                    >
                      <g>
                        <path d="M2.007 40.509h25.29V122H2.007V40.509zM14.652 0c8.083 0 14.652 6.581 14.652 14.692 0 8.103-6.569 14.684-14.652 14.684C6.551 29.376 0 22.795 0 14.692 0 6.581 6.551 0 14.652 0"></path>
                        <path d="M43.154 40.509h24.248v11.143h.348c3.375-6.41 11.622-13.162 23.922-13.162 25.602 0 30.327 16.872 30.327 38.815V122H96.728V82.37c0-9.453-.167-21.608-13.138-21.608-13.157 0-15.164 10.302-15.164 20.928V122H43.154V40.509z"></path>
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
              <div
                className={classes.directors_image}
                onClick={handleNarayananModal}
              >
                <LazyImage src={narayana} alt="P.S Narayanan" />
                <div className={classes.person_details}>
                  <div className={classes.person_name}>
                    <h3>Suresh Narayanan</h3>
                    <p>Chief Operating Officer</p>
                  </div>
                  <div className={classes.linkidin_svg}>
                    <svg
                      data-bbox="0 0 121.999 122"
                      viewBox="0 0 122 122"
                      height="30"
                      width="30"
                      xmlns="http://www.w3.org/2000/svg"
                      data-type="shape"
                    >
                      <g>
                        <path d="M2.007 40.509h25.29V122H2.007V40.509zM14.652 0c8.083 0 14.652 6.581 14.652 14.692 0 8.103-6.569 14.684-14.652 14.684C6.551 29.376 0 22.795 0 14.692 0 6.581 6.551 0 14.652 0"></path>
                        <path d="M43.154 40.509h24.248v11.143h.348c3.375-6.41 11.622-13.162 23.922-13.162 25.602 0 30.327 16.872 30.327 38.815V122H96.728V82.37c0-9.453-.167-21.608-13.138-21.608-13.157 0-15.164 10.302-15.164 20.928V122H43.154V40.509z"></path>
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
              <div
                className={classes.directors_image}
                onClick={handleTushaarModal}
              >
                <LazyImage src={tushaar} alt="tushaar Reddy" />
                <div className={classes.person_details}>
                  <div className={classes.person_name}>
                    <h3> Dr. Tushaar Jagadish</h3>
                    <p>Director - Academics</p>
                  </div>
                  <div className={classes.linkidin_svg}>
                    <svg
                      data-bbox="0 0 121.999 122"
                      viewBox="0 0 122 122"
                      height="30"
                      width="30"
                      xmlns="http://www.w3.org/2000/svg"
                      data-type="shape"
                    >
                      <g>
                        <path d="M2.007 40.509h25.29V122H2.007V40.509zM14.652 0c8.083 0 14.652 6.581 14.652 14.692 0 8.103-6.569 14.684-14.652 14.684C6.551 29.376 0 22.795 0 14.692 0 6.581 6.551 0 14.652 0"></path>
                        <path d="M43.154 40.509h24.248v11.143h.348c3.375-6.41 11.622-13.162 23.922-13.162 25.602 0 30.327 16.872 30.327 38.815V122H96.728V82.37c0-9.453-.167-21.608-13.138-21.608-13.157 0-15.164 10.302-15.164 20.928V122H43.154V40.509z"></path>
                      </g>
                    </svg>
                  </div>
                </div>
              </div>

              <div
                className={classes.directors_image}
                onClick={handleGinoModal}
              >
                <LazyImage src={gino} alt="P.S Narayanan" />
                <div className={classes.person_details}>
                  <div className={classes.person_name}>
                    <h3>Gino Valverde</h3>
                    <p>Director - Consulting</p>
                  </div>
                  <div className={classes.linkidin_svg}>
                    <svg
                      data-bbox="0 0 121.999 122"
                      viewBox="0 0 122 122"
                      height="30"
                      width="30"
                      xmlns="http://www.w3.org/2000/svg"
                      data-type="shape"
                    >
                      <g>
                        <path d="M2.007 40.509h25.29V122H2.007V40.509zM14.652 0c8.083 0 14.652 6.581 14.652 14.692 0 8.103-6.569 14.684-14.652 14.684C6.551 29.376 0 22.795 0 14.692 0 6.581 6.551 0 14.652 0"></path>
                        <path d="M43.154 40.509h24.248v11.143h.348c3.375-6.41 11.622-13.162 23.922-13.162 25.602 0 30.327 16.872 30.327 38.815V122H96.728V82.37c0-9.453-.167-21.608-13.138-21.608-13.157 0-15.164 10.302-15.164 20.928V122H43.154V40.509z"></path>
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
              <div
                className={classes.directors_image}
                onClick={handleLakshmiModal}
              >
                <LazyImage src={lakshmi} alt="Lakshmi Marthala" />
                <div className={classes.person_details}>
                  <div className={classes.person_name}>
                    <h3>Lakshmi Marthala</h3>
                    <p>Director - HR</p>
                  </div>
                  <div className={classes.linkidin_svg}>
                    <svg
                      data-bbox="0 0 121.999 122"
                      viewBox="0 0 122 122"
                      height="30"
                      width="30"
                      xmlns="http://www.w3.org/2000/svg"
                      data-type="shape"
                    >
                      <g>
                        <path d="M2.007 40.509h25.29V122H2.007V40.509zM14.652 0c8.083 0 14.652 6.581 14.652 14.692 0 8.103-6.569 14.684-14.652 14.684C6.551 29.376 0 22.795 0 14.692 0 6.581 6.551 0 14.652 0"></path>
                        <path d="M43.154 40.509h24.248v11.143h.348c3.375-6.41 11.622-13.162 23.922-13.162 25.602 0 30.327 16.872 30.327 38.815V122H96.728V82.37c0-9.453-.167-21.608-13.138-21.608-13.157 0-15.164 10.302-15.164 20.928V122H43.154V40.509z"></path>
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      )}
      {directorsModal && <ExcutiveDirectorsPage />}
      {narayananModal && <NarayananPage />}
      {tushaarModal && <TusharPage />}
      {ginoModal && <GinoPage />}
      {laksmiModal && <LakshmiPage />}
    </>
  );
};

export default Directors;
