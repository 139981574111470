import React, { useEffect } from "react";
import FutureOfAI from "./FutureOfAI";
import Revolution from "./Revolution";
import RevolutionOptions from "./RevolutionOptions";
import AllAtItsBest from "./AllAtItsBest";
import ExploreOurServices from "./ExploreOurServices";
import Headerv2 from "./Headerv2";
import ContactFooter from "../../common/contactFooter/ContactFooter";
import CoursePriceDetails from "./CoursePriceDetails";
import MiniHeader from "../HomePage/MiniHeader";

const DiscoverMore = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div style={{ backgroundColor: "#fff" }}>
        <MiniHeader />
        <Headerv2 />
        <FutureOfAI />
        <Revolution />
        <RevolutionOptions />
        <CoursePriceDetails />
        {/* <AILearning /> */}
        <AllAtItsBest />
        <ExploreOurServices />
        {/* <Connect /> */}
        {/* <GetInTouchContactData /> */}
        {/* <Footer /> */}
        <ContactFooter />
      </div>
    </>
  );
};

export default DiscoverMore;
