import React, { useEffect, useState } from "react";
import logo from "../../../components/assets/images/cra_logo_V1 (2).png";
import { FiMenu } from "react-icons/fi";
import { MdOutlineClose } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

const Headerv2 = () => {
  const navigate = useNavigate();

  const [toggleOpen, setToggleOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [activeLink, setActiveLink] = useState("home");

  const handleActiveLink = (menuItem) => {
    setToggleOpen(!toggleOpen);
    setActiveLink(menuItem);
  };

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    setIsSticky(scrollPosition > 0);
  };

  const menuHandler = () => {
    setToggleOpen(!toggleOpen);
  };

  const handleLogo = () => {
    navigate("/");
  };

  const handlePrevious = () => {
    navigate("/");
  };


  const handleHome = () => {
    const noticeBoardSection = document.getElementById("home");
    if (noticeBoardSection) {
      const offset = 100;
      const scrollTop = noticeBoardSection.offsetTop - offset;
      window.scrollTo({
        top: scrollTop,
        behavior: "smooth",
      });
    }
  };

  const handleAbout = () => {
    const noticeBoardSection = document.getElementById("revolution_slider");
    if (noticeBoardSection) {
      const offset = 100;
      const scrollTop = noticeBoardSection.offsetTop - offset;
      window.scrollTo({
        top: scrollTop,
        behavior: "smooth",
      });
    }
  };

  const handleFeatures = () => {
    const noticeBoardSection = document.getElementById("register");
    if (noticeBoardSection) {
      const offset = 100;
      const scrollTop = noticeBoardSection.offsetTop - offset;
      window.scrollTo({
        top: scrollTop,
        behavior: "smooth",
      });
    }
  };

  const handleContact = () => {
    const noticeBoardSection = document.getElementById("footer_content");
    if (noticeBoardSection) {
      const offset = 100;
      const scrollTop = noticeBoardSection.offsetTop - offset;
      window.scrollTo({
        top: scrollTop,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <header className={`header ${isSticky ? "sticky" : ""} headerv2`}>
        <div style={{ display: "flex", alignItems: "center", gap: "0rem" }}>
          <img src={logo} alt="logo" className="logo" onClick={handleLogo} />
          <div>
            <div className="back_cont" onClick={handlePrevious}>
              <NavigateBeforeIcon
                style={{ fontSize: "2rem", color: "#0077b6" }}
              />
              <p
                style={{
                  fontSize: "1.5rem",
                  color: "#0077b6",
                  fontWeight: "550",
                }}
              >
                Home
              </p>
            </div>
          </div>
        </div>
        <nav>
          <ul className={`menu_lists ${toggleOpen ? "show" : ""}`}>
            <li>
              <Link
                className={`menu_list ${
                  activeLink === "home" ? "activeMenuItem" : ""
                }`}
                onClick={() => {
                  handleHome()
                  handleActiveLink("home");
                }}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                className={`menu_list ${
                  activeLink === "about" ? "activeMenuItem" : ""
                }`}
                onClick={() => {
                  handleAbout();
                  handleActiveLink("about");
                }}
              >
                About
              </Link>
            </li>
            <li>
              <Link
                className={`menu_list ${
                  activeLink === "features" ? "activeMenuItem" : ""
                }`}
                onClick={() => {
                  handleFeatures();
                  handleActiveLink("features");
                }}
              >
                Register
              </Link>
            </li>
            <li>
              <Link
                className={`menu_list ${
                  activeLink === "contact" ? "activeMenuItem" : ""
                }`}
                onClick={() => {
                  handleContact();
                  handleActiveLink("contact");
                }}
              >
                Contact
              </Link>
            </li>
            {/* <li>
              <button className={`${token ? "" : "menu_list btn btn_signup"}`}>
                SignUp
              </button>
            </li> */}
          </ul>
        </nav>
        <div className="menu" onClick={menuHandler}>
          <FiMenu
            name="menu-outline"
            className={`menu_icon ${toggleOpen ? "hide" : ""}`}
          />
          <MdOutlineClose
            name="menu-outline"
            className={`menu_icon ${toggleOpen ? "" : "hide"}`}
          />
        </div>
      </header>
    </>
  );
};

export default Headerv2;
