import React, { useState } from "react";
import styles from "./NewAccordian.module.css";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import classes from "../pages/AboutPage/JeeMainExam.module.css";
import { Link } from "react-router-dom";
import Button from "./Button";

const NewAccordion = () => {
  const [accordions, setAccordions] = useState({
    "Secondary Education in India": false,
    NEET: false,
    JEE: false,
    "CETS in different states": false,
  });
  const [knowMoreToggle, setKnowMoreToggle] = useState(false);

  const areAllAccordionsOpen = Object.values(accordions).every(
    (isOpen) => isOpen
  );
  const toggleAllLabel = areAllAccordionsOpen
    ? "Collapse all -"
    : "Expand all +";

  const toggleAll = () => {
    const newIsOpen = !areAllAccordionsOpen;
    setAccordions((prevState) => {
      const newState = { ...prevState };
      Object.keys(newState).forEach((key) => {
        newState[key] = newIsOpen;
      });
      return newState;
    });
  };

  const toggleAccordion = (title) => {
    setAccordions((prevState) => ({
      ...prevState,
      [title]: !prevState[title],
    }));
  };

  const handleKnowMore = () => {
    setKnowMoreToggle(!knowMoreToggle);
  };

  return (
    <div id="app" className={styles.app}>
      <header className={styles.header}>
        <h1>Competitive Exams Information</h1>
      </header>
      <div className={styles.accordions}>
        <div className={styles.container}>
          <div className={styles.accordions__controls}>
            <button
              className={styles.accordions__control}
              onClick={toggleAll}
              dangerouslySetInnerHTML={{ __html: toggleAllLabel }}
            />
          </div>

          <section className={styles.accordions__accordion}>
            <div className={styles.accordions__header}>
              <div className={styles.accordions__container}>
                <h3 className={styles.accordions__title}>
                  Secondary Education
                </h3>
                <button
                  className={`${styles.accordions__button} ${
                    accordions["Secondary Education in India"]
                      ? styles.accordions__buttonActive
                      : ""
                  }`}
                  onClick={() =>
                    toggleAccordion("Secondary Education in India")
                  }
                >
                  <div className={styles.accordions__icon}>
                    {accordions["Secondary Education in India"] ? "-" : "+"}
                  </div>
                </button>
              </div>
            </div>
            <div
              className={`${styles.accordions__content} ${
                accordions["Secondary Education in India"]
                  ? styles.accordions__contentExpanded
                  : ""
              }`}
            >
              <div className={classes.jee_main_exam_section}>
                <h1>Secondary Education in India</h1>
                <ul>
                  <li>
                    <CheckBoxOutlinedIcon
                      style={{
                        color: "#FF9622",
                        fontSize: "2.6rem",
                        marginTop: "0.5rem",
                      }}
                    />
                    Secondary education in India spans 2 to 3 years, covering
                    8th, 9th, and 10th standards for students aged 13 to 16
                    years, culminating in the class 10th board examinations.
                  </li>
                  <li>
                    <CheckBoxOutlinedIcon
                      style={{
                        color: "#FF9622",
                        fontSize: "2.6rem",
                        marginTop: "0.5rem",
                      }}
                    />
                    Traditionally, secondary education begins around age 11 to
                    13 and ends around age 15 to 18, with a diminishing
                    distinction between elementary and secondary education in
                    curricula and organization.
                  </li>
                  <li>
                    <CheckBoxOutlinedIcon
                      style={{
                        color: "#FF9622",
                        fontSize: "2.6rem",
                        marginTop: "0.5rem",
                      }}
                    />
                    Secondary education starts in grade 9 and extends to grade
                    12, divided into two two-year cycles: General/Lower
                    Secondary School (Standard X) and Upper/Senior Secondary
                    School (Standard XII).
                  </li>
                  <li>
                    <CheckBoxOutlinedIcon
                      style={{
                        color: "#FF9622",
                        fontSize: "2.6rem",
                        marginTop: "0.5rem",
                      }}
                    />
                    As of 2021-22, the Gross Enrollment Ratio (GER) for
                    secondary education in India is 57.6%, indicating increased
                    accessibility. Secondary education is a critical phase
                    during students' transition into adolescence.
                  </li>
                  <li>
                    <CheckBoxOutlinedIcon
                      style={{
                        color: "#FF9622",
                        fontSize: "2.6rem",
                        marginTop: "0.5rem",
                      }}
                    />
                    Its educational objectives include providing basic skills
                    and laying the groundwork for lifelong learning.
                  </li>
                  <li>
                    <CheckBoxOutlinedIcon
                      style={{
                        color: "#FF9622",
                        fontSize: "2.6rem",
                        marginTop: "0.5rem",
                      }}
                    />
                    India boasts three main national boards: Central Board of
                    Secondary Education (CBSE), Council for the Indian School
                    Certificate Examinations (CISCE), and National Institute of
                    Open Schooling (NIOS), alongside state and private education
                    boards.
                  </li>
                  <li>
                    <CheckBoxOutlinedIcon
                      style={{
                        color: "#FF9622",
                        fontSize: "2.6rem",
                        marginTop: "0.5rem",
                      }}
                    />
                    Certainty Rank Academy aims to provide top-notch online,
                    recorded, classes to promote education, with objectives
                    including reducing education costs, bridging the gap between
                    academia and industry, and overcoming infrastructural and
                    socio-cultural barriers.
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section className={styles.accordions__accordion}>
            <div className={styles.accordions__header}>
              <div className={styles.accordions__container}>
                <h3 className={styles.accordions__title}>NEET</h3>
                <button
                  className={`${styles.accordions__button} ${
                    accordions["NEET"] ? styles.accordions__buttonActive : ""
                  }`}
                  onClick={() => toggleAccordion("NEET")}
                >
                  <div className={styles.accordions__icon}>
                    {accordions["NEET"] ? "-" : "+"}
                  </div>
                </button>
              </div>
            </div>
            <div
              className={`${styles.accordions__content} ${
                accordions["NEET"] ? styles.accordions__contentExpanded : ""
              }`}
            >
              <div className={classes.jee_main_exam_section}>
                <h1>NEET Competitive Examination in India</h1>
                <ul>
                  <li>
                    <CheckBoxOutlinedIcon
                      style={{
                        color: "#FF9622",
                        fontSize: "2.6rem",
                        marginTop: "0.5rem",
                      }}
                    />
                    Purpose: Gateway to undergraduate medical programs in India.
                  </li>
                  <li>
                    <CheckBoxOutlinedIcon
                      style={{
                        color: "#FF9622",
                        fontSize: "2.6rem",
                        marginTop: "0.5rem",
                      }}
                    />
                    Administered by: National Testing Agency (NTA), under the
                    Ministry of Education, Government of India.
                  </li>
                  <li>
                    <CheckBoxOutlinedIcon
                      style={{
                        color: "#FF9622",
                        fontSize: "2.6rem",
                        marginTop: "0.5rem",
                      }}
                    />
                    Eligibility: Minimum age of 17 years by admission or before
                    December 31st of the joining year. No upper age limit for
                    undergraduate applicants. Must have passed Physics,
                    Chemistry, Biology/Biotechnology, and English individually.
                    Minimum aggregate of 50% in Physics, Chemistry, and
                    Biology/Biotechnology.
                  </li>
                  <li>
                    <CheckBoxOutlinedIcon
                      style={{
                        color: "#FF9622",
                        fontSize: "2.6rem",
                        marginTop: "0.5rem",
                      }}
                    />
                    Test Structure: Sections: Physics, Chemistry, and Biology.
                  </li>
                  <li>
                    <CheckBoxOutlinedIcon
                      style={{
                        color: "#FF9622",
                        fontSize: "2.6rem",
                        marginTop: "0.5rem",
                      }}
                    />
                    Duration: Three hours. All questions must be attempted for
                    qualification.
                  </li>
                </ul>
                <div className={styles.knowMore_btn_box}>
                  <Button
                    onClick={handleKnowMore}
                    className={styles.knowMore_btn}
                  >
                    {knowMoreToggle ? "Show Less" : "Show More"}
                  </Button>
                </div>
              </div>
              {knowMoreToggle && (
                <div className={classes.jee_main_exam_section}>
                  <h1>Courses Under NEET UG 2024</h1>
                  <ul>
                    <li>Bachelor of Medicine and Bachelor of Surgery (MBBS)</li>
                    <li>Bachelor of Dental Surgery (BDS)</li>
                    <li>Bachelor of Ayurvedic Medicine and Surgery (BAMS)</li>
                    <li>Bachelor of Veterinary Science (BVSc)</li>
                    <li>Bachelor of Homeopathic Medicine and Surgery (BHMS)</li>
                    <li>Bachelor of Unani Medicine and Surgery (BUMS)</li>
                    <li>Bachelor of Physiotherapy (B.P.T)</li>
                    <li>Bachelor of Naturopathy and Yoga systems (BYNS)</li>
                    <li>Bachelor of Siddha Medicine and Surgery (BSMS)</li>
                    <li>
                      Bachelor of Veterinary Science and Animal Husbandry (BVSc
                      & AH)
                    </li>
                  </ul>
                  <h1>Paramedical Courses Under NEET 2024</h1>
                  <ul>
                    <li>Bachelor of Occupational Therapy</li>
                    <li>B.Sc Nursing</li>
                    <li>B.Sc in Dialysis Therapy</li>
                    <li>B.Sc. Medical Lab Technology</li>
                    <li>B.Sc in Optometry</li>
                    <li>B.Sc in X-Ray Technology</li>
                    <li>B.Sc in Nuclear Medicine Technology</li>
                    <li>B.Sc in Operation Theatre Technology</li>
                    <li>B.Sc. in Medical Record Technology</li>
                    <li>B.Sc. in Medical Imaging Technology</li>
                    <li>B.Sc. in Anesthesia Technology</li>
                    <li>Bachelor of Radiation Technology</li>
                    <li>B.Sc. in Agriculture</li>
                  </ul>
                </div>
              )}
            </div>
          </section>

          <section className={styles.accordions__accordion}>
            <div className={styles.accordions__header}>
              <div className={styles.accordions__container}>
                <h3 className={styles.accordions__title}>JEE</h3>
                <button
                  className={`${styles.accordions__button} ${
                    accordions["JEE"] ? styles.accordions__buttonActive : ""
                  }`}
                  onClick={() => toggleAccordion("JEE")}
                >
                  <div className={styles.accordions__icon}>
                    {accordions["JEE"] ? "-" : "+"}
                  </div>
                </button>
              </div>
            </div>
            <div
              className={`${styles.accordions__content} ${
                accordions["JEE"] ? styles.accordions__contentExpanded : ""
              }`}
            >
              <div className={classes.jee_main_exam_section}>
                <h1>JEE Main Competitive Examination in India</h1>
                <ul>
                  <li>
                    <CheckBoxOutlinedIcon
                      style={{
                        color: "#FF9622",
                        fontSize: "2.6rem",
                        marginTop: "0.5rem",
                      }}
                    />
                    Purpose: Entry into top engineering universities, colleges,
                    and institutions in India.
                  </li>
                  <li>
                    <CheckBoxOutlinedIcon
                      style={{
                        color: "#FF9622",
                        fontSize: "2.6rem",
                        marginTop: "0.5rem",
                      }}
                    />
                    Administered by: National Testing Agency; Syllabus approved
                    by CBSE board.
                  </li>
                  <li>
                    <CheckBoxOutlinedIcon
                      style={{
                        color: "#FF9622",
                        fontSize: "2.6rem",
                        marginTop: "0.5rem",
                      }}
                    />
                    Acronym: JEE-Main
                  </li>
                  <li>
                    <CheckBoxOutlinedIcon
                      style={{
                        color: "#FF9622",
                        fontSize: "2.6rem",
                        marginTop: "0.5rem",
                      }}
                    />
                    Countries/Regions: India
                  </li>
                  <li>
                    <CheckBoxOutlinedIcon
                      style={{
                        color: "#FF9622",
                        fontSize: "2.6rem",
                        marginTop: "0.5rem",
                      }}
                    />
                    Knowledge/Skills Tested
                  </li>
                  <li>
                    <CheckBoxOutlinedIcon
                      style={{
                        color: "#FF9622",
                        fontSize: "2.6rem",
                        marginTop: "0.5rem",
                      }}
                    />
                    Paper 1: Physics, Chemistry, and Mathematics.
                  </li>
                  <li>
                    <CheckBoxOutlinedIcon
                      style={{
                        color: "#FF9622",
                        fontSize: "2.6rem",
                        marginTop: "0.5rem",
                      }}
                    />
                    Paper 2 (for B.Arch. only): Mathematics, Aptitude, Drawing,
                    and Planning.
                  </li>
                  <li>
                    <CheckBoxOutlinedIcon
                      style={{
                        color: "#FF9622",
                        fontSize: "2.6rem",
                        marginTop: "0.5rem",
                      }}
                    />
                    Languages: English, Assamese, Bengali, Gujarati, Hindi,
                    Kannada, Malayalam, Marathi, Odia, Punjabi, Tamil, Telugu,
                    and Urdu.
                  </li>
                  <li>
                    <CheckBoxOutlinedIcon
                      style={{
                        color: "#FF9622",
                        fontSize: "2.6rem",
                        marginTop: "0.5rem",
                      }}
                    />
                    Frequency: Offered twice per year, in January and April.
                  </li>
                  <li>
                    <CheckBoxOutlinedIcon
                      style={{
                        color: "#FF9622",
                        fontSize: "2.6rem",
                        marginTop: "0.5rem",
                      }}
                    />
                    Eligibility:
                  </li>
                  <li>
                    <CheckBoxOutlinedIcon
                      style={{
                        color: "#FF9622",
                        fontSize: "2.6rem",
                        marginTop: "0.5rem",
                      }}
                    />
                    Minimum age of 17 years by admission or before December 31st
                    of the joining year.
                  </li>
                  <li>
                    <CheckBoxOutlinedIcon
                      style={{
                        color: "#FF9622",
                        fontSize: "2.6rem",
                        marginTop: "0.5rem",
                      }}
                    />
                    Minimum 75% marks in class 12 required for admission in
                    NITs, IIITs, and GFTIs. No minimum requirement for appearing
                    in JEE Mains.
                  </li>
                </ul>
              </div>
            </div>
          </section>

          {/* <section className={styles.accordions__accordion}>
            <div className={styles.accordions__header}>
              <div className={styles.accordions__container}>
                <h3 className={styles.accordions__title}>KCET</h3>
                <button
                  className={`${styles.accordions__button} ${
                    accordions["KCET"] ? styles.accordions__buttonActive : ""
                  }`}
                  onClick={() => toggleAccordion("KCET")}
                >
                  <div className={styles.accordions__icon}>
                    {accordions["KCET"] ? "-" : "+"}
                  </div>
                </button>
              </div>
            </div>
            <div
              className={`${styles.accordions__content} ${
                accordions["KCET"] ? styles.accordions__contentExpanded : ""
              }`}
            >
              <div className={classes.jee_main_exam_section}>
                <h1>KCET Competitive Examination in Karnataka</h1>
                <ul>
                  <li>
                    <CheckBoxOutlinedIcon
                      style={{
                        color: "#FF9622",
                        fontSize: "2.6rem",
                        marginTop: "0.5rem",
                      }}
                    />
                    Purpose: Admission to professional courses including
                    Engineering Technology, Bachelor of Pharmacy (BPharma),
                    Diploma in Pharmacy (DPharma), Agriculture courses (Farm
                    Science), and Veterinary courses.
                  </li>
                  <li>
                    <CheckBoxOutlinedIcon
                      style={{
                        color: "#FF9622",
                        fontSize: "2.6rem",
                        marginTop: "0.5rem",
                      }}
                    />
                    Administered by: Karnataka Examination Authority (KEA).
                  </li>
                  <li>
                    <CheckBoxOutlinedIcon
                      style={{
                        color: "#FF9622",
                        fontSize: "2.6rem",
                        marginTop: "0.5rem",
                      }}
                    />
                    Languages: Kannada, English.
                  </li>
                  <li>
                    <CheckBoxOutlinedIcon
                      style={{
                        color: "#FF9622",
                        fontSize: "2.6rem",
                        marginTop: "0.5rem",
                      }}
                    />
                    Full Exam Name: The Karnataka Common Entrance Test (KCET)
                  </li>
                  <li>
                    Note : NEET, conducted by the National Testing Agency, is
                    specifically for admission to medical and dental courses,
                    while KCET focuses on engineering, agriculture, pharmacy,
                    and related fields.
                  </li>
                </ul>
              </div>
            </div>
          </section> */}
          <section className={styles.accordions__accordion}>
            <div className={styles.accordions__header}>
              <div className={styles.accordions__container}>
                <h3 className={styles.accordions__title}>
                  CET's in different states
                </h3>
                <button
                  className={`${styles.accordions__button} ${
                    accordions["CETS in different states"]
                      ? styles.accordions__buttonActive
                      : ""
                  }`}
                  onClick={() => toggleAccordion("CETS in different states")}
                >
                  <div className={styles.accordions__icon}>
                    {accordions["CETS in different states"] ? "-" : "+"}
                  </div>
                </button>
              </div>
            </div>
            <div
              className={`${styles.accordions__content} ${
                accordions["CETS in different states"]
                  ? styles.accordions__contentExpanded
                  : ""
              }`}
            >
              <div className={classes.jee_main_exam_section}>
                <h1>CET's in different states</h1>
                <ul>
                  <li>
                    <CheckBoxOutlinedIcon
                      style={{
                        color: "#FF9622",
                        fontSize: "2.6rem",
                        marginTop: "0.5rem",
                      }}
                    />
                    <p>
                      KCET (Karnataka Common Entrance Test) - For students in
                      Karnataka.
                    </p>
                  </li>
                  <li>
                    <CheckBoxOutlinedIcon
                      style={{
                        color: "#FF9622",
                        fontSize: "2.6rem",
                        marginTop: "0.5rem",
                      }}
                    />
                    TNEA (Tamil Nadu Engineering Admissions) - Not exactly a
                    test, but an admission process based on 12th standard scores
                    for students in Tamil Nadu.
                  </li>
                  <li>
                    <CheckBoxOutlinedIcon
                      style={{
                        color: "#FF9622",
                        fontSize: "2.6rem",
                        marginTop: "0.5rem",
                      }}
                    />
                    MHT CET (Maharashtra Health and Technical Common Entrance
                    Test) - For students in Maharashtra.
                  </li>
                  <li>
                    <CheckBoxOutlinedIcon
                      style={{
                        color: "#FF9622",
                        fontSize: "2.6rem",
                        marginTop: "0.5rem",
                      }}
                    />
                    AP EAMCET (Andhra Pradesh Engineering, Agriculture, and
                    Medical Common Entrance Test) - Now known as AP EAPCET for
                    Andhra Pradesh.
                  </li>
                  <li>
                    <CheckBoxOutlinedIcon
                      style={{
                        color: "#FF9622",
                        fontSize: "2.6rem",
                        marginTop: "0.5rem",
                      }}
                    />
                    TS EAMCET (Telangana State Engineering, Agriculture, and
                    Medical Common Entrance Test) - For students in Telangana.
                  </li>
                  <li>
                    <CheckBoxOutlinedIcon
                      style={{
                        color: "#FF9622",
                        fontSize: "2.6rem",
                        marginTop: "0.5rem",
                      }}
                    />
                    KEAM (Kerala Engineering Architecture Medical Examination) -
                    For students in Kerala.
                  </li>
                  <li>
                    <CheckBoxOutlinedIcon
                      style={{
                        color: "#FF9622",
                        fontSize: "2.6rem",
                        marginTop: "0.5rem",
                      }}
                    />
                    WBJEE (West Bengal Joint Entrance Examinations) - For
                    students in West Bengal.
                  </li>
                  <li>
                    <CheckBoxOutlinedIcon
                      style={{
                        color: "#FF9622",
                        fontSize: "2.6rem",
                        marginTop: "0.5rem",
                      }}
                    />
                    GUJCET (Gujarat Common Entrance Test) - For students in
                    Gujarat.
                  </li>
                  <li>
                    <CheckBoxOutlinedIcon
                      style={{
                        color: "#FF9622",
                        fontSize: "2.6rem",
                        marginTop: "0.5rem",
                      }}
                    />
                    BCECE (Bihar Combined Entrance Competitive Examination) -
                    For students in Bihar.
                  </li>
                  <li>
                    <CheckBoxOutlinedIcon
                      style={{
                        color: "#FF9622",
                        fontSize: "2.6rem",
                        marginTop: "0.5rem",
                      }}
                    />
                    JCECE (Jharkhand Combined Entrance Competitive Examination)
                    - For students in Jharkhand.
                  </li>
                </ul>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default NewAccordion;
