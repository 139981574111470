import React from "react";
import * as Yup from "yup";
import { Box, Button, Grid, Modal, TextField } from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { setAddStudents } from "../../../Redux/studentsSlice";

const SignupSchema = Yup.object().shape({
  studentName: Yup.string().required("Student Name is required"),
  parentName: Yup.string().required("Parent Name is required"),

  studentMobileNumber: Yup.string().required(
    "Student Mobile Number is required"
  ),
  parentMobileNumber: Yup.string().required("Parent Mobile Number is required"),
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  maxHeight: "90vh",
  overflowY: "auto",
  bgcolor: "background.paper",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 4,
};

const AddStudent = () => {
  const dispatch = useDispatch();

  const addStudent = useSelector((state) => state.studentReducer.addStudents);

  const closeHandler = () => {
    dispatch(setAddStudents(!addStudent));
  };

  const handleFormSubmit = (values) => {
    console.log(values);
  };

  return (
    <>
      <Modal
        keepMounted
        open={addStudent}
        onClose={closeHandler}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <Formik
            initialValues={{
              studentName: "",
              studentMobileNumber: "",
              parentName: "",
              parentMobileNumber: "",
            }}
            validationSchema={SignupSchema}
            onSubmit={(values, { resetForm }) =>
              handleFormSubmit(values, { resetForm })
            }
          >
            {({ handleSubmit, isValid, values, dirty, resetForm }) => (
              <Form
                onSubmit={handleSubmit}
                style={{
                  padding: "0 2rem",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} lg={12}>
                    <CloseIcon
                      style={{
                        color: "#000",
                        fontSize: "3rem",
                        cursor: "pointer",
                        //   position: "absolute",
                        //   top: "5px",
                        //   right: "calc(120% + 10px)",
                        //   zIndex: 1,
                        float: "right",
                      }}
                      className="close_icon"
                      onClick={closeHandler}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} lg={12}>
                    <Field
                      as={TextField}
                      type="text"
                      placeholder="Student Name"
                      name="studentName"
                      fullWidth
                    />
                    <ErrorMessage
                      name="studentName"
                      component="div"
                      className="error"
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <Field
                      as={TextField}
                      type="text"
                      placeholder="Student Mobile Number"
                      name="studentMobileNumber"
                      fullWidth
                    />
                    <ErrorMessage
                      name="studentMobileNumber"
                      component="div"
                      className="error"
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ mt: -0.5 }}>
                  <Grid item xs={12} md={12} lg={12}>
                    <Field
                      as={TextField}
                      type="text"
                      placeholder="Parent Name"
                      name="parentName"
                      fullWidth
                    />
                    <ErrorMessage
                      name="parentName"
                      component="div"
                      className="error"
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <Field
                      as={TextField}
                      type="text"
                      placeholder="Parent Mobile Number"
                      name="parentMobileNumber"
                      fullWidth
                    />
                    <ErrorMessage
                      name="parentMobileNumber"
                      component="div"
                      className="error"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1} sx={{ mt: 1 }}>
                  <Grid item xs={12} md={12} lg={12} sx={{ mb: 1 }}>
                    <div>
                      <Button
                        variant="contained"
                        disabled={!isValid || !dirty}
                        onClick={(e) => handleSubmit()}
                        sx={{
                          width: "100%",
                          mb: 4,
                          fontSize: "1.5rem",
                          letterSpacing: "3px",
                        }}
                      >
                        Save
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
    </>
  );
};

export default AddStudent;
