import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { showToast } from "../utils/Toasts";
import { uploadPhoto } from "./uploadPhotoSlice";

export const updateUserAsync = createAsyncThunk(
  "user/updateUser",
  async (userData, { rejectWithValue, dispatch }) => {
    try {
      const { profilePic, ...userDetails } = userData;
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/register`,
        userDetails
      );

      if (response.status === 201) {
        const responseData = response.data;
        showToast("Registered Successfully!", "success");
        if (profilePic) {
          dispatch(
            uploadPhoto({ id: responseData.data.studentId, file: profilePic })
          );
        }
        return responseData;
      }
    } catch (error) {
      let errorMessage = "An error occurred. Please try again.";

      if (error.response) {
        const { status, data } = error.response;

        if (status >= 400 && status < 500 && data.error && data.error.message) {
          errorMessage = data.error.message;
        } else if (data.success === false && data.message) {
          errorMessage = data.message;
        } else {
          errorMessage = data.message || errorMessage;
        }
        showToast(errorMessage, "error");
      }

      return rejectWithValue(errorMessage);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: {
    loading: false,
    success: false,
  },
  reducers: {
    setSuccess: (state, action) => {
      state.success = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateUserAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateUserAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(updateUserAsync.rejected, (state) => {
        state.loading = false;
        state.success = false;
      });
  },
});

export const { setSuccess } = userSlice.actions;
export const selectUser = (state) => state.user;
export default userSlice.reducer;
