import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import {
  getFacultyData,
  setFacultyData,
  setFacultyEditData,
  updateFacultyData,
} from "../../../Redux/facultySlice";
import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { addFacultySchema } from "../../../common/validationSchema";
import CloseIcon from "@mui/icons-material/Close";
import { unwrapResult } from "@reduxjs/toolkit";
import { showToast } from "../../../utils/Toasts";
import { useNavigate } from "react-router-dom";

const EditFacultyData = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const editFacultyData = useSelector(
    (state) => state.facultyReducer.editFacultyData
  );
  const facultyData = useSelector((state) => state.facultyReducer.facultyData);

  const selectedFacultyRowData = useSelector(
    (state) => state.facultyReducer.selectedFacultyRowData
  );

  const [selectedSubjects, setSelectedSubjects] = useState([]);

  const handleCheckboxChange = (event, setFieldValue) => {
    const { value, checked } = event.target;
    let newSelectedSubjects = [...selectedSubjects];

    if (checked) {
      newSelectedSubjects.push(value);
    } else {
      newSelectedSubjects = newSelectedSubjects.filter(
        (subject) => subject !== value
      );
    }

    setSelectedSubjects(newSelectedSubjects);
    setFieldValue("subjects", newSelectedSubjects);
  };

  const handleClose = () => {
    dispatch(setFacultyEditData(!editFacultyData));
    navigate("/facultyDetails");
  };

  const handleFormSubmit = async (values) => {
    const updatedFaculty = {
      ...selectedFacultyRowData,
      firstName: values.firstName,
      lastName: values.lastName,
      mobileNumber: values.mobileNumber,
      emailAddress: values.emailAddress,
      age: values.age,
      gender: values.gender,
      subjects: values.subjects,
      experienceInYears: values.experienceInYears,
      qualification: values.qualification,
    };

    if (values.firstName === undefined) {
      return;
    }

    try {
      const resultAction = await dispatch(updateFacultyData(updatedFaculty));
      unwrapResult(resultAction);

      const updatedTableData = facultyData.map((item) =>
        item._id === selectedFacultyRowData._id ? updatedFaculty : item
      );

      dispatch(setFacultyData(updatedTableData));
      dispatch(getFacultyData());
      dispatch(setFacultyEditData(!editFacultyData));
    } catch (error) {
      showToast(error, "error");
    }
  };

  useEffect(() => {
    if (selectedFacultyRowData.subjects) {
      setSelectedSubjects(selectedFacultyRowData.subjects);
    }
  }, [selectedFacultyRowData]);

  return (
    <>
      <Modal
        keepMounted
        open={editFacultyData}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        BackdropProps={{
          onClick: (event) => event.stopPropagation(),
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: {
              xs: "38rem",
              sm: "38rem",
              md: "auto",
              lg: "auto",
              xl: "auto",
            },
            height: {
              //   xs: formStep === 0 ? "29rem" : formStep === 3 ? "35rem" : "45rem",
              sm: "45rem",
              md: "auto",
              lg: "auto",
              xl: "auto",
            },
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: "1rem",
            p: {
              xs: 2,
              sm: 2,
              md: 2,
              lg: 4,
              xl: 4,
            },
            overflow: "auto",
          }}
        >
          <>
            <Formik
              initialValues={{
                firstName: selectedFacultyRowData.firstName || "",
                lastName: selectedFacultyRowData.lastName || "",
                mobileNumber: selectedFacultyRowData.mobileNumber || "",
                emailAddress: selectedFacultyRowData.emailAddress || "",
                age: selectedFacultyRowData.age || "",
                gender: selectedFacultyRowData.gender || "",
                subjects: selectedFacultyRowData.subjects || [],
                experienceInYears:
                  selectedFacultyRowData.experienceInYears || "",
                qualification: selectedFacultyRowData.qualification || "",
              }}
              validationSchema={addFacultySchema}
              onSubmit={(values, { resetForm }) =>
                handleFormSubmit(values, { resetForm })
              }
            >
              {({
                handleSubmit,
                isValid,
                values,
                dirty,
                resetForm,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <div>
                    <CloseIcon
                      onClick={handleClose}
                      style={{
                        float: "right",
                        fontSize: "3rem",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={6}>
                      <h3 className="form_label">First Name</h3>
                      <Field
                        as={TextField}
                        type="text"
                        placeholder="First Name"
                        name="firstName"
                        fullWidth
                      />
                      <ErrorMessage
                        name="firstName"
                        component="div"
                        className="error"
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <h3 className="form_label">Last Name</h3>
                      <Field
                        as={TextField}
                        type="text"
                        placeholder="Last Name"
                        name="lastName"
                        fullWidth
                      />
                      <ErrorMessage
                        name="lastName"
                        component="div"
                        className="error"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} sx={{ mt: -1.5 }}>
                    <Grid item xs={12} md={6} lg={6}>
                      <h3 className="form_label">Mobile Number</h3>
                      <Field
                        as={TextField}
                        type="text"
                        placeholder="Mobile Number"
                        name="mobileNumber"
                        fullWidth
                      />
                      <ErrorMessage
                        name="mobileNumber"
                        component="div"
                        className="error"
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <h3 className="form_label">Email</h3>
                      <Field
                        as={TextField}
                        type="text"
                        placeholder="Email"
                        name="emailAddress"
                        fullWidth
                      />
                      <ErrorMessage
                        name="emailAddress"
                        component="div"
                        className="error"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} sx={{ mt: -1.5 }}>
                    <Grid item xs={12} md={4} lg={4}>
                      <h3 className="form_label">Age</h3>
                      <Field
                        as={TextField}
                        type="text"
                        placeholder="Age"
                        name="age"
                        fullWidth
                      />
                      <ErrorMessage
                        name="age"
                        component="div"
                        className="error"
                      />
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                      <h3 className="form_label">Experience</h3>
                      <Field
                        as={TextField}
                        type="text"
                        placeholder="Experience"
                        name="experienceInYears"
                        fullWidth
                      />
                      <ErrorMessage
                        name="experienceInYears"
                        component="div"
                        className="error"
                      />
                    </Grid>

                    <Grid item xs={12} md={4} lg={4}>
                      <Paper
                        sx={{
                          padding: "0.3rem 1rem",
                          marginTop: "2rem",
                          border: "1px solid #8c0005",
                          marginBottom: "1rem",
                        }}
                      >
                        <div className="subjectCheckbox">
                          <input
                            type="checkbox"
                            name="subjects"
                            value="subjects"
                            className="subjectCheckbox"
                            onChange={(e) => {
                              if (e.target.checked) {
                                setFieldValue("subjects", true);
                              } else {
                                setFieldValue("subjects", false);
                              }
                            }}
                            checked={values.subjects}
                          />
                          <h2 className="form_label">Subject</h2>
                        </div>
                        {values.subjects && (
                          <>
                            <div className="courseLevel">
                              <input
                                type="checkbox"
                                name="subjects"
                                value="Physics"
                                onChange={(event) =>
                                  handleCheckboxChange(event, setFieldValue)
                                }
                                checked={selectedSubjects.includes("Physics")}
                              />
                              Physics
                            </div>
                            <div className="courseLevel">
                              <input
                                type="checkbox"
                                name="subjects"
                                value="Chemistry"
                                onChange={(event) =>
                                  handleCheckboxChange(event, setFieldValue)
                                }
                                checked={selectedSubjects.includes("Chemistry")}
                              />
                              Chemistry
                            </div>
                            <div className="courseLevel">
                              <input
                                type="checkbox"
                                name="subjects"
                                value="Biology"
                                onChange={(event) =>
                                  handleCheckboxChange(event, setFieldValue)
                                }
                                checked={selectedSubjects.includes("Biology")}
                              />
                              Biology
                            </div>
                            <div className="courseLevel">
                              <input
                                type="checkbox"
                                name="subjects"
                                value="Mathematics"
                                onChange={(event) =>
                                  handleCheckboxChange(event, setFieldValue)
                                }
                                checked={selectedSubjects.includes(
                                  "Mathematics"
                                )}
                              />
                              Mathematics
                            </div>
                            <div className="courseLevel">
                              <input
                                type="checkbox"
                                name="subjects"
                                value="English"
                                onChange={(event) =>
                                  handleCheckboxChange(event, setFieldValue)
                                }
                                checked={selectedSubjects.includes("English")}
                              />
                              English
                            </div>
                            <div className="courseLevel">
                              <input
                                type="checkbox"
                                name="subjects"
                                value="Science"
                                onChange={(event) =>
                                  handleCheckboxChange(event, setFieldValue)
                                }
                                checked={selectedSubjects.includes("Science")}
                              />
                              Science
                            </div>
                            <ErrorMessage
                              name="subjects"
                              component="div"
                              className="error"
                            />
                          </>
                        )}
                      </Paper>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} sx={{ mt: -2 }}>
                    <Grid item xs={12} md={6} lg={6}>
                      <h3 className="form_label">Gender</h3>
                      <FormControl fullWidth>
                        <Field
                          as={Select}
                          labelId="gender-label"
                          id="gender"
                          label="gender"
                          name="gender"
                          fullWidth
                          inputProps={{ style: { fontSize: "1.5rem" } }}
                          input={<OutlinedInput />}
                          placeholder="Gender"
                          sx={{
                            minWidth: 120,
                            backgroundColor: "white",
                            borderRadius: "7px",
                            border: "1px solid #e9ecef",
                            color: "#000",
                            fontSize: "1.5rem",
                          }}
                          displayEmpty
                        >
                          <MenuItem
                            value={selectedFacultyRowData.gender}
                            disabled
                          >
                            {selectedFacultyRowData.gender || "Gender"}
                          </MenuItem>
                          <MenuItem value={"Male"}>Male</MenuItem>
                          <MenuItem value={"FeMale"}>Female</MenuItem>
                          <MenuItem value={"Other"}>Other</MenuItem>
                        </Field>
                        <ErrorMessage
                          name="gender"
                          component="div"
                          className="error"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <h3 className="form_label">Qualification</h3>
                      <FormControl fullWidth>
                        <Field
                          as={Select}
                          id="qualification"
                          name="qualification"
                          input={<OutlinedInput />}
                          displayEmpty
                          sx={{
                            minWidth: 120,
                            backgroundColor: "white",
                            fontSize: "1.5rem",
                            color: "#000",
                          }}
                        >
                          <MenuItem
                            value={selectedFacultyRowData.qualification}
                            disabled
                          >
                            {selectedFacultyRowData.qualification ||
                              "Select Qualification"}
                          </MenuItem>
                          <MenuItem value="Graduate">Graduate</MenuItem>
                          <MenuItem value="Postgraduate">Postgraduate</MenuItem>
                        </Field>
                        <ErrorMessage
                          name="qualification"
                          component="div"
                          className="error"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} sx={{ mt: 1 }}>
                    <Grid item xs={12} md={12} lg={12}>
                      <div>
                        <Button
                          variant="contained"
                          //   disabled={!isValid || !dirty}
                          onClick={(e) => handleSubmit()}
                          sx={{
                            width: "100%",
                            fontSize: "1.5rem",
                            letterSpacing: "3px",
                          }}
                        >
                          Submit
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </>
        </Box>
      </Modal>
    </>
  );
};

export default EditFacultyData;
