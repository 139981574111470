import React from "react";
import { useSelector } from "react-redux";
import AdminSidebar from "../AdminSidebar";
import AdminHeader from "../AdminHeader";

const AdminLayout = ({ children }) => {
  const toggleOpen = useSelector((state) => state.boolean.toggleOpen);

  return (
    <>
      <AdminHeader />
      <main>
        <div className={toggleOpen ? "main_contnet" : "main_contnet_full"}>
          {children}
        </div>
      </main>
      {toggleOpen && <AdminSidebar />}
    </>
  );
};

export default AdminLayout;
