import { Grid } from "@mui/material";
import React from "react";
import LoginForm from "./LoginForm";

const Login = () => {
  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12} md={8} lg={8}>
          <LoginForm />
        </Grid>
      </Grid>
    </>
  );
};

export default Login;
