import React from "react";
import { Grid } from "@mui/material";
import classes from "./Proposition.module.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Proposition = () => {
  return (
    <>
      <h1 className={classes.proposition_heading}>
        Our Unique Value Proposition
      </h1>
      <div className={classes.proposition_container}>
        <Grid container spacing={8} justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <div className={classes.proposition_image_box}>
              <img
                src="https://res.cloudinary.com/dmuecdqxy/image/upload/v1718991571/zkjuhzsz8u4qcqfpgysz.jpg"
                alt="lady"
                className={classes.proposition_image}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <div className={classes.accordions}>
              <Accordion className={classes.accordion}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ fontSize: "3rem" }} />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  className={classes.accordian_title}
                >
                  How does personalized learning work?{" "}
                </AccordionSummary>
                <AccordionDetails className={classes.accordian_description}>
                  Our AI analyzes your strengths and weaknesses to tailor a
                  unique study plan for you.
                </AccordionDetails>
              </Accordion>
              <Accordion className={classes.accordion}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ fontSize: "3rem" }} />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  className={classes.accordian_title}
                >
                  What services are 24/7?
                </AccordionSummary>
                <AccordionDetails className={classes.accordian_description}>
                  You have round-the-clock access to learning materials and
                  expert assistance, with an AI bot available to assist you at
                  any time.
                </AccordionDetails>
              </Accordion>
              <Accordion className={classes.accordion}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ fontSize: "3rem" }} />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  className={classes.accordian_title}
                >
                  How does instant feedback help?
                </AccordionSummary>
                <AccordionDetails className={classes.accordian_description}>
                  Immediate feedback lets you correct mistakes and improve
                  continuously.
                </AccordionDetails>
              </Accordion>{" "}
              <Accordion className={classes.accordion}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ fontSize: "3rem" }} />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  className={classes.accordian_title}
                >
                  What are the costs involved?
                </AccordionSummary>
                <AccordionDetails className={classes.accordian_description}>
                  We offer cost-effective pricing plan to suit every budget.
                </AccordionDetails>
              </Accordion>
              <Accordion className={classes.accordion}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ fontSize: "3rem" }} />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  className={classes.accordian_title}
                >
                  Didn’t find the answer you were looking for?
                </AccordionSummary>
                <AccordionDetails className={classes.accordian_description}>
                  Our Admission Counsellors are just a phone call away. Or
                  better yet, drop in to our Admission Counselling Centre and
                  have your queries on programs, fees, accommodation,
                  placements, etc., answered.{" "}
                </AccordionDetails>
              </Accordion>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Proposition;
