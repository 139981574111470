import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { showToast } from "../utils/Toasts";

export const resetPassword = createAsyncThunk(
  "reset/resetPassword",
  async (studentEmailAddress, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/forgot-password`,
        { studentEmailAddress }
      );
      if (response.data.success === true) {
        showToast(response.data.data);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const forgotPasswordSlice = createSlice({
  name: "reset",
  initialState: {
    loading: false,
    error: null,
    success: false,
    forgotPasswordModal: false,
  },
  reducers: {
    resetState: (state) => {
      state.loading = false;
      state.error = null;
      state.success = false;
    },
    setForgotPasswordModal: (state, action) => {
      state.forgotPasswordModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetPassword.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetState, setForgotPasswordModal } =
  forgotPasswordSlice.actions;
export default forgotPasswordSlice.reducer;
