import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { useSelector, useDispatch } from "react-redux";
import { ErrorMessage, Field, Form, Formik } from "formik";
import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  getFacultyData,
  setAddFaculty,
  setFacultyData,
} from "../../../Redux/facultySlice";
import { postFacultyData } from "../../../Redux/postFacultySlice";
import { addFacultySchema } from "../../../common/validationSchema";

const AddFaculty = () => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    maxHeight: "90vh",
    overflowY: "auto",
    bgcolor: "background.paper",
    borderRadius: "1rem",
    boxShadow: 24,
    p: 2,
  };
  const addFacultyData = useSelector(
    (state) => state.facultyReducer.addFaculty
  );
  const facultyData = useSelector((state) => state.facultyReducer.facultyData);

  const dispatch = useDispatch();

  const [selectedSubjects, setSelectedSubjects] = useState([]);

  const handleClose = () => {
    dispatch(setAddFaculty(false));
  };

  const handleCheckboxChange = (event, setFieldValue) => {
    const { value, checked } = event.target;
    let newSelectedSubjects = [...selectedSubjects];

    if (checked) {
      newSelectedSubjects.push(value);
    } else {
      newSelectedSubjects = newSelectedSubjects.filter(
        (subject) => subject !== value
      );
    }

    setSelectedSubjects(newSelectedSubjects);
    setFieldValue("subjects", newSelectedSubjects);
  };

  const generateRandomId = () => {
    const randomNum = Math.random();
    const id = randomNum.toString(36).substring(2, 10);
    return id;
  };

  const handleFormSubmit = async (values, { resetForm }) => {
    const updatedValues = {
      ...values,
      subjects: selectedSubjects,
      _id: generateRandomId(),
    };

    try {
      await dispatch(postFacultyData(updatedValues));
      dispatch(getFacultyData());
      dispatch(setFacultyData([...facultyData, updatedValues]));
      resetForm();
      setSelectedSubjects([]);
      handleClose();
    } catch (error) {
      console.error("Error posting faculty data: ", error);
    }
  };

  return (
    <>
      <Modal
        keepMounted
        open={addFacultyData}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        BackdropProps={{
          onClick: (event) => event.stopPropagation(),
        }}
      >
        <Box sx={style}>
          <>
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                mobileNumber: "",
                emailAddress: "",
                age: "",
                gender: "",
                subjects: [],
                experienceInYears: "",
                qualification: "",
              }}
              validationSchema={addFacultySchema}
              onSubmit={(values, { resetForm }) =>
                handleFormSubmit(values, { resetForm })
              }
            >
              {({
                handleSubmit,
                isValid,
                values,
                dirty,
                resetForm,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <div>
                    <CloseIcon
                      style={{
                        fontSize: "3rem",
                        float: "right",
                        cursor: "pointer",
                      }}
                      onClick={handleClose}
                    />
                  </div>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={6}>
                      <h3 className="form_label">First Name</h3>
                      <Field
                        as={TextField}
                        type="text"
                        placeholder="First Name"
                        name="firstName"
                        fullWidth
                      />
                      <ErrorMessage
                        name="firstName"
                        component="div"
                        className="error"
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <h3 className="form_label">Last Name</h3>
                      <Field
                        as={TextField}
                        type="text"
                        placeholder="Last Name"
                        name="lastName"
                        fullWidth
                      />
                      <ErrorMessage
                        name="lastName"
                        component="div"
                        className="error"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} sx={{ mt: -1.5 }}>
                    <Grid item xs={12} md={6} lg={6}>
                      <h3 className="form_label">Mobile Number</h3>
                      <Field
                        as={TextField}
                        type="text"
                        placeholder="Mobile Number"
                        name="mobileNumber"
                        fullWidth
                      />
                      <ErrorMessage
                        name="mobileNumber"
                        component="div"
                        className="error"
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <h3 className="form_label">Email</h3>
                      <Field
                        as={TextField}
                        type="text"
                        placeholder="Email"
                        name="emailAddress"
                        fullWidth
                      />
                      <ErrorMessage
                        name="emailAddress"
                        component="div"
                        className="error"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} sx={{ mt: -1.5 }}>
                    <Grid item xs={12} md={4} lg={4}>
                      <h3 className="form_label">Age</h3>
                      <Field
                        as={TextField}
                        type="text"
                        placeholder="Age"
                        name="age"
                        fullWidth
                      />
                      <ErrorMessage
                        name="age"
                        component="div"
                        className="error"
                      />
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                      <h3 className="form_label">Experience</h3>
                      <Field
                        as={TextField}
                        type="text"
                        placeholder="Experience"
                        name="experienceInYears"
                        fullWidth
                      />
                      <ErrorMessage
                        name="experienceInYears"
                        component="div"
                        className="error"
                      />
                    </Grid>

                    <Grid item xs={12} md={4} lg={4}>
                      <Paper
                        sx={{
                          padding: "0.3rem 1rem",
                          marginTop: "2rem",
                          border: "1px solid #8c0005",
                          marginBottom: "1rem",
                        }}
                      >
                        <div className="subjectCheckbox">
                          <input
                            type="checkbox"
                            name="subjects"
                            value="subjects"
                            className="subjectCheckbox"
                            onChange={(e) => {
                              if (e.target.checked) {
                                setFieldValue("subjects", true);
                              } else {
                                setFieldValue("subjects", false);
                              }
                            }}
                            checked={values.subjects}
                          />
                          <h2 className="form_label">Subject</h2>
                        </div>
                        {values.subjects && (
                          <>
                            <div className="courseLevel">
                              <input
                                type="checkbox"
                                name="subjects"
                                value="Physics"
                                onChange={(event) =>
                                  handleCheckboxChange(event, setFieldValue)
                                }
                                checked={selectedSubjects.includes("Physics")}
                              />
                              Physics
                            </div>
                            <div className="courseLevel">
                              <input
                                type="checkbox"
                                name="subjects"
                                value="Chemistry"
                                onChange={(event) =>
                                  handleCheckboxChange(event, setFieldValue)
                                }
                                checked={selectedSubjects.includes("Chemistry")}
                              />
                              Chemistry
                            </div>
                            <div className="courseLevel">
                              <input
                                type="checkbox"
                                name="subjects"
                                value="Biology"
                                onChange={(event) =>
                                  handleCheckboxChange(event, setFieldValue)
                                }
                                checked={selectedSubjects.includes("Biology")}
                              />
                              Biology
                            </div>
                            <div className="courseLevel">
                              <input
                                type="checkbox"
                                name="subjects"
                                value="Mathematics"
                                onChange={(event) =>
                                  handleCheckboxChange(event, setFieldValue)
                                }
                                checked={selectedSubjects.includes(
                                  "Mathematics"
                                )}
                              />
                              Mathematics
                            </div>
                            <div className="courseLevel">
                              <input
                                type="checkbox"
                                name="subjects"
                                value="English"
                                onChange={(event) =>
                                  handleCheckboxChange(event, setFieldValue)
                                }
                                checked={selectedSubjects.includes("English")}
                              />
                              English
                            </div>
                            <div className="courseLevel">
                              <input
                                type="checkbox"
                                name="subjects"
                                value="Science"
                                onChange={(event) =>
                                  handleCheckboxChange(event, setFieldValue)
                                }
                                checked={selectedSubjects.includes("Science")}
                              />
                              Science
                            </div>
                            <ErrorMessage
                              name="subjects"
                              component="div"
                              className="error"
                            />
                          </>
                        )}
                      </Paper>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} sx={{ mt: -3 }}>
                    <Grid item xs={12} md={6} lg={6}>
                      <h3 className="form_label">Gender</h3>
                      <FormControl fullWidth>
                        <Field
                          as={Select}
                          labelId="gender-label"
                          id="gender"
                          label="gender"
                          name="gender"
                          fullWidth
                          input={<OutlinedInput />}
                          placeholder="Gender"
                          sx={{
                            minWidth: 120,
                            backgroundColor: "white",
                            color: "#000",
                            fontSize: "1.5rem",
                          }}
                          displayEmpty
                        >
                          <MenuItem value="" disabled>
                            Gender
                          </MenuItem>
                          <MenuItem value={"Male"}>Male</MenuItem>
                          <MenuItem value={"FeMale"}>FeMale</MenuItem>
                          <MenuItem value={"Others"}>Others</MenuItem>
                        </Field>
                        <ErrorMessage
                          name="gender"
                          component="div"
                          className="error"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <h3 className="form_label">Qualification</h3>
                      <FormControl fullWidth>
                        <Field
                          as={Select}
                          labelId="qualification-label"
                          id="qualification"
                          label="qualification"
                          name="qualification"
                          fullWidth
                          input={<OutlinedInput />}
                          placeholder="Qualification"
                          sx={{
                            minWidth: 120,
                            backgroundColor: "white",
                            fontSize: "1.5rem",
                            color: "#000",
                          }}
                          displayEmpty
                        >
                          <MenuItem value="" disabled>
                            Qualification
                          </MenuItem>
                          <MenuItem value={"Graduate"}>Graduate</MenuItem>
                          <MenuItem value={"Postgraduate"}>
                            Postgraduate
                          </MenuItem>
                        </Field>
                        <ErrorMessage
                          name="qualification"
                          component="div"
                          className="error"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} sx={{ mt: 1 }}>
                    <Grid item xs={12} md={12} lg={12}>
                      <div>
                        <Button
                          variant="contained"
                          // disabled={!isValid || !dirty}
                          onClick={(e) => handleSubmit()}
                          sx={{
                            width: "100%",
                            fontSize: "1.5rem",
                            letterSpacing: "3px",
                          }}
                        >
                          Submit
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </>
        </Box>
      </Modal>
    </>
  );
};

export default AddFaculty;
