import React from "react";
import { Grid } from "@mui/material";
import classes from "./ScienceWithAI.module.css";
import Button from "../../common/Button";
import lighting from "../../../components/assets/images/ezibpgpeankgmvnxcbbb.png";
import { useDispatch } from "react-redux";
import { setModelOpen } from "../../Redux/loginSlice";
import SignupFormModel from "../../authentication/SignUp/SignupFormModel";

const ScienceWithAI = () => {
  const dispatch = useDispatch();

  const handleJoinNow = () => {
    dispatch(setModelOpen(true));
  };

  return (
    <>
      <div className={classes.container} id="science_home">
        <h1 className={classes.primary_heading}  >
          Unlock the Mysteries of Science with AI
        </h1>
        <Grid container spacing={10} sx={{ mb: 8 }}>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <p className={classes.primary_contnet}>
              Embark on an unparalleled learning journey where artificial
              intelligence meets the captivating world of science.
            </p>
            <div className={classes.joinus_container}>
              {/* <Button className={classes.explore_btn}>Explore</Button> */}
              <Button className={classes.join_now_btn} onClick={handleJoinNow}>
                Join Now
              </Button>
            </div>
            <div className={classes.rating_section}>
              <div>{/* <img src={trustPilot} alt="green star" /> */}</div>
              {/* <div className={classes.rating}>
                <div className={classes.rating_points}>
                  <p>
                    <Rating
                      name="half-rating"
                      defaultValue={4.5}
                      precision={0.5}
                    />
                  </p>
                  <h1>4.8/5 Ratings</h1>
                </div>
              </div> */}
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <img src={lighting} alt="light" className={classes.light_image} />
          </Grid>
        </Grid>
      </div>
      <SignupFormModel />
    </>
  );
};

export default ScienceWithAI;
