import React, { useEffect } from "react";
import ScienceWithAI from "./ScienceWithAI";
import VirutualLabs from "./VirutualLabs";
import ScienceProgram from "./ScienceProgram";
import ExploreInnovation from "./ExploreInnovation";
import ScienceHeader from "./ScienceHeader";
import ContactFooter from "../../common/contactFooter/ContactFooter";
import ScienceCoursePrice from "./ScienceCoursePrice";
import MiniHeader from "../HomePage/MiniHeader";

const Science = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div style={{ backgroundColor: "#fff" }}>
        <MiniHeader />
        <ScienceHeader />
        <ScienceWithAI />
        <VirutualLabs />
        <ScienceProgram />
        <ScienceCoursePrice />
        <ExploreInnovation />
        {/* <ScienceAccordian /> */}
        {/* <GetInTouchContactData /> */}
        {/* <WideRangeServices /> */}
        <ContactFooter />
        {/* <Footer /> */}
      </div>
    </>
  );
};

export default Science;
