import React, { useCallback, useMemo, useState } from "react";
import classes from "./CourseEnrollment.module.css";
import Button from "../../common/Button";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate, useParams } from "react-router-dom";

const CourseButtons = () => {
  const navigate = useNavigate();
  const { programName } = useParams();

  const [activeLink, setActiveLink] = useState("features");

  const handleActiveLink = useCallback((menuItem) => {
    setActiveLink(menuItem);
  }, []);

  const handleFeatures = useCallback(() => {
    const featuresRef = document.getElementById("feature_section");
    if (featuresRef) {
      const offset = 300;
      const scrollTop = featuresRef.offsetTop - offset;
      window.scrollTo({
        top: scrollTop,
        behavior: "smooth",
      });
    }
  }, []);

  const handleDemoVideo = useCallback(() => {
    const demoVideo = document.getElementById("demo_video");
    if (demoVideo) {
      const offset = 300;
      const scrollTop = demoVideo.offsetTop - offset;
      window.scrollTo({
        top: scrollTop,
        behavior: "smooth",
      });
    }
  }, []);

  const handlePdfDownloader = useCallback(() => {
    const pdfDownloader = document.getElementById("pdf");
    if (pdfDownloader) {
      const offset = 310;
      const scrollTop = pdfDownloader.offsetTop - offset;
      window.scrollTo({
        top: scrollTop,
        behavior: "smooth",
      });
    }
  }, []);

  const handlePrograms = useCallback(() => {
    navigate("/programs");
  }, []);

  const capitalizeAllLetters = useCallback((string) => {
    return string.toUpperCase();
  }, []);

  const capitalizedProgramName = useMemo(
    () => capitalizeAllLetters(programName),
    [programName, capitalizeAllLetters]
  );

  return (
    <>
      <div className={classes.back_to_programs}>
        <h1 onClick={handlePrograms}>
          Program
          <ArrowForwardIosIcon style={{ fontSize: "2.5rem" }} />
          {capitalizedProgramName}
        </h1>

        <div className={classes.course_btn_box}>
          <Button
            className={
              activeLink === "features"
                ? classes.active_course_btn
                : classes.course_btn
            }
            onClick={() => {
              handleFeatures();
              handleActiveLink("features");
            }}
          >
            Features
          </Button>
          <Button
            className={
              activeLink === "demo_video"
                ? classes.active_course_btn
                : classes.course_btn
            }
            onClick={() => {
              handleDemoVideo();
              handleActiveLink("demo_video");
            }}
          >
            Demo Video
          </Button>
          <Button
            className={
              activeLink === "course_pdf"
                ? classes.active_course_btn
                : classes.course_btn
            }
            onClick={() => {
              handlePdfDownloader();
              handleActiveLink("course_pdf");
            }}
          >
            Downloads
          </Button>
        </div>
      </div>
    </>
  );
};

export default CourseButtons;
