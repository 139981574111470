import React from "react";
import logo from "../../assets/images/cra_logo_V1 (2).png";

const Logo = () => {
  return (
    <>
      <div className="logo_container">
        <div className="logo_box">
          <img src={logo} alt="logo" className="logo" />
          <div>
            <h2>CERTAINTY</h2>
            <h3>RANK ACADEMY</h3>
          </div>
        </div>
        <div className="logo_title">
          <p>AI Fueled and Human Guided Education</p>
        </div>
      </div>
    </>
  );
};

export default Logo;
