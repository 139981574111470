import React, { useCallback, useEffect, useMemo, useState } from "react";
import classes from "./CourseEnrollment.module.css";
import { Grid, Paper } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import PdfDownloader from "../../common/PdfDownloader";
import DemoClassVideo from "../../common/DemoClass";
import Features from "./Features";
import CourseButtons from "./CourseButtons";
import { useDispatch } from "react-redux";
import { fetchPdf } from "../../Redux/pdfSlice";
import { showToast } from "../../utils/Toasts";

const CourseEnrollment = () => {
  const { programName } = useParams();
  const dispatch = useDispatch();

  const [selectedSubjects, setSelectedSubjects] = useState({
    Physics: { checked: false, selectedOption: null },
    Chemistry: { checked: false, selectedOption: null },
    Maths: { checked: false, selectedOption: null },
    Biology: { checked: false, selectedOption: null },
  });

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setSelectedSubjects((prev) => ({
      ...prev,
      [name]: {
        ...prev[name],
        checked,
        selectedOption: checked ? prev[name].selectedOption : null,
      },
    }));
  };

  const handleRadioChange = (event) => {
    const { name, value } = event.target;
    setSelectedSubjects((prev) => ({
      ...prev,
      [name]: {
        ...prev[name],
        selectedOption: value,
      },
    }));
  };

  const capitalizeAllLetters = useCallback((string) => {
    return string.toUpperCase();
  }, []);

  const capitalizedProgramName = useMemo(
    () => capitalizeAllLetters(programName),
    [programName, capitalizeAllLetters]
  );

  const handleFrequntlyAskedQuestions = (fileName) => {
    dispatch(fetchPdf(fileName));
  };

  const handleRefundPolicy = (fileName) => {
    dispatch(fetchPdf(fileName));
  };

  const isAnySubjectSelected = useMemo(() => {
    return Object.values(selectedSubjects).some(
      (subject) => subject.checked && subject.selectedOption
    );
  }, [selectedSubjects]);

  const isEnrollButtonDisabled = useMemo(() => {
    return Object.entries(selectedSubjects).some(
      ([_, { checked, selectedOption }]) => checked && !selectedOption
    );
  }, [selectedSubjects]);

  const handleEnroll = () => {
    // const selectedCheckboxWithoutRadio = Object.entries(
    //   selectedSubjects
    // ).filter(([_, { checked, selectedOption }]) => checked && !selectedOption);

    // if (selectedCheckboxWithoutRadio.length > 0) {
    //   const subjectNames = selectedCheckboxWithoutRadio
    //     .map(([subjectName]) => subjectName)
    //     .join(", ");
    //   showToast(
    //     `Please select a level for each checked subject: ${subjectNames}.`,
    //     "error"
    //   );
    //   return;
    // }

    if (!isAnySubjectSelected) {
      showToast("Please select at least one subject to enroll.", "error");
      return;
    }

    const selectedSubjectsForPayment = Object.entries(selectedSubjects)
      .filter(([_, { checked, selectedOption }]) => checked && selectedOption)
      .map(([subjectName, { selectedOption }]) => ({
        subject: subjectName,
        level: selectedOption,
      }));

    console.log("Selected Subjects for Payment:", selectedSubjectsForPayment);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Paper
        className={classes.mainContainer}
        sx={{
          boxShadow: "0 0 2rem rgba(0, 0, 0, 0.7)",
          borderRadius: "1.5rem",
        }}
      >
        <CourseButtons />
        <Features />

        <div className={classes.subjectContainer}>
          <h1>Subject Selection</h1>
          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <Paper
                sx={{
                  boxShadow: "0 0 2rem rgba(0, 0, 0, 0.1)",
                  borderRadius: "1rem",
                  padding: "1rem 2rem",
                }}
              >
                <div className={classes.subjectHeader}>
                  <input
                    type="checkbox"
                    name="Physics"
                    value="Physics"
                    className={classes.subjectCheckbox}
                    onChange={handleCheckboxChange}
                    checked={selectedSubjects.Physics.checked}
                  />
                  <h2>
                    {capitalizedProgramName === "5TH - 10TH STANDARD"
                      ? "English"
                      : "Physics"}
                  </h2>
                </div>
                {selectedSubjects.Physics.checked && (
                  <>
                    <div className={classes.courseLevel}>
                      <input
                        type="radio"
                        name="Physics"
                        value="Basic"
                        checked={
                          selectedSubjects.Physics.selectedOption === "Basic"
                        }
                        onChange={handleRadioChange}
                      />
                      Basic
                    </div>
                    <div className={classes.courseLevel}>
                      <input
                        type="radio"
                        name="Physics"
                        value="Standard"
                        checked={
                          selectedSubjects.Physics.selectedOption === "Standard"
                        }
                        onChange={handleRadioChange}
                      />
                      Standard
                    </div>
                    <div className={classes.courseLevel}>
                      <input
                        type="radio"
                        name="Physics"
                        value="Premium"
                        checked={
                          selectedSubjects.Physics.selectedOption === "Premium"
                        }
                        onChange={handleRadioChange}
                      />
                      Premium
                    </div>
                  </>
                )}
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <Paper
                sx={{
                  boxShadow: "0 0 2rem rgba(0, 0, 0, 0.1)",
                  borderRadius: "1rem",
                  padding: "1rem 2rem",
                }}
              >
                <div className={classes.subjectHeader}>
                  <input
                    type="checkbox"
                    name="Chemistry"
                    value="Chemistry"
                    className={classes.subjectCheckbox}
                    onChange={handleCheckboxChange}
                    checked={selectedSubjects.Chemistry.checked}
                  />
                  <h2>
                    {capitalizedProgramName === "5TH - 10TH STANDARD"
                      ? "Science"
                      : "Chemistry"}
                  </h2>
                </div>
                {selectedSubjects.Chemistry.checked && (
                  <>
                    <div className={classes.courseLevel}>
                      <input
                        type="radio"
                        name="Chemistry"
                        value="Basic"
                        checked={
                          selectedSubjects.Chemistry.selectedOption === "Basic"
                        }
                        onChange={handleRadioChange}
                      />
                      Basic
                    </div>
                    <div className={classes.courseLevel}>
                      <input
                        type="radio"
                        name="Chemistry"
                        value="Standard"
                        checked={
                          selectedSubjects.Chemistry.selectedOption ===
                          "Standard"
                        }
                        onChange={handleRadioChange}
                      />
                      Standard
                    </div>
                    <div className={classes.courseLevel}>
                      <input
                        type="radio"
                        name="Chemistry"
                        value="Premium"
                        checked={
                          selectedSubjects.Chemistry.selectedOption ===
                          "Premium"
                        }
                        onChange={handleRadioChange}
                      />
                      Premium
                    </div>
                  </>
                )}
              </Paper>
            </Grid>
            {capitalizedProgramName !== "NEET" && (
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <Paper
                  sx={{
                    boxShadow: "0 0 2rem rgba(0, 0, 0, 0.1)",
                    borderRadius: "1rem",
                    padding: "1rem 2rem",
                  }}
                >
                  <div className={classes.subjectHeader}>
                    <input
                      type="checkbox"
                      name="Maths"
                      value="Maths"
                      className={classes.subjectCheckbox}
                      onChange={handleCheckboxChange}
                      checked={selectedSubjects.Maths.checked}
                    />
                    <h2>Mathematics</h2>
                  </div>
                  {selectedSubjects.Maths.checked && (
                    <>
                      <div className={classes.courseLevel}>
                        <input
                          type="radio"
                          name="Maths"
                          value="Basic"
                          checked={
                            selectedSubjects.Maths.selectedOption === "Basic"
                          }
                          onChange={handleRadioChange}
                        />
                        Basic
                      </div>
                      <div className={classes.courseLevel}>
                        <input
                          type="radio"
                          name="Maths"
                          value="Advanced"
                          checked={
                            selectedSubjects.Maths.selectedOption === "Advanced"
                          }
                          onChange={handleRadioChange}
                        />
                        Standard
                      </div>
                      <div className={classes.courseLevel}>
                        <input
                          type="radio"
                          name="Maths"
                          value="Premium"
                          checked={
                            selectedSubjects.Maths.selectedOption === "Premium"
                          }
                          onChange={handleRadioChange}
                        />
                        Premium
                      </div>
                    </>
                  )}
                </Paper>
              </Grid>
            )}
            {capitalizedProgramName !== "JEE" &&
              capitalizedProgramName !== "5TH - 10TH STANDARD" && (
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                  <Paper
                    sx={{
                      boxShadow: "0 0 2rem rgba(0, 0, 0, 0.1)",
                      borderRadius: "1rem",
                      padding: "1rem 2rem",
                    }}
                  >
                    <div className={classes.subjectHeader}>
                      <input
                        type="checkbox"
                        name="Biology"
                        value="Biology"
                        className={classes.subjectCheckbox}
                        onChange={handleCheckboxChange}
                        checked={selectedSubjects.Biology.checked}
                      />
                      <h2>Biology</h2>
                    </div>
                    {selectedSubjects.Biology.checked && (
                      <>
                        <div className={classes.courseLevel}>
                          <input
                            type="radio"
                            name="Biology"
                            value="Basic"
                            checked={
                              selectedSubjects.Biology.selectedOption ===
                              "Basic"
                            }
                            onChange={handleRadioChange}
                          />
                          Basic
                        </div>
                        <div className={classes.courseLevel}>
                          <input
                            type="radio"
                            name="Biology"
                            value="Advanced"
                            checked={
                              selectedSubjects.Biology.selectedOption ===
                              "Advanced"
                            }
                            onChange={handleRadioChange}
                          />
                          Standard
                        </div>
                        <div className={classes.courseLevel}>
                          <input
                            type="radio"
                            name="Biology"
                            value="Premium"
                            checked={
                              selectedSubjects.Biology.selectedOption ===
                              "Premium"
                            }
                            onChange={handleRadioChange}
                          />
                          Premium
                        </div>
                      </>
                    )}
                  </Paper>
                </Grid>
              )}
          </Grid>
          <div className={classes.enrollButtonContainer}>
            <div
              className={`${classes.enrollButton} ${
                isEnrollButtonDisabled ? classes.disabledButton : ""
              }`}
              onClick={isEnrollButtonDisabled ? null : handleEnroll}
            >
              <Link
                // to={isAnySubjectSelected ? "/course-enrollment/checkout" : "#"}
                className={classes.enrollButton}
                onClick={(event) => {
                  if (!isAnySubjectSelected) {
                    event.preventDefault();
                  } else {
                    handleEnroll();
                  }
                }}
              >
                Enroll Now
              </Link>
            </div>
          </div>
        </div>
      </Paper>
      <Grid container spacing={2} sx={{ mb: 2 }} id="demo_video">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Link style={{ textDecoration: "none" }}>
            <DemoClassVideo />
          </Link>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mb: 2 }} id="pdf">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <PdfDownloader />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{
          mb: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <div
            className={classes.faqButton}
            onClick={() =>
              handleFrequntlyAskedQuestions(
                capitalizedProgramName === "JEE"
                  ? "JEE-FAQ.pdf"
                  : capitalizedProgramName === "NEET"
                  ? "NEET-FAQ.pdf"
                  : capitalizedProgramName === "KCET"
                  ? "KCET-FAQ.pdf"
                  : "5-10-FAQ.pdf"
              )
            }
          >
            <h1>Frequently asked questions</h1>
            <svg
              className={classes.svgIcon}
              focusable="false"
              aria-hidden="true"
              viewBox="0 0 24 24"
              data-testid="ArrowForwardOutlinedIcon"
              height={36}
              width={36}
              fill="#fff"
            >
              <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"></path>
            </svg>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <div
            className={classes.faqButton}
            onClick={() => handleRefundPolicy("Refund_Policy_CRA.pdf")}
          >
            <h1>Refund Policy</h1>
            <svg
              className={classes.svgIcon}
              focusable="false"
              aria-hidden="true"
              viewBox="0 0 24 24"
              data-testid="ArrowForwardOutlinedIcon"
              height={36}
              width={36}
              fill="#fff"
            >
              <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"></path>
            </svg>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default CourseEnrollment;
