import React, { useState } from "react";
import { Grid } from "@mui/material";
import classes from "./ContactFooter.module.css";
import FacebookIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import logo from "../../assets/images/cra_logo_V1 (2).png";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import { Link, useNavigate } from "react-router-dom";

const ContactFooterCont = () => {
  const navigate = useNavigate();

  const [activeMenuItem, setActiveMenuItem] = useState("home");

  const handleMenuItemClick = (menuItem) => {
    setActiveMenuItem(menuItem);
  };

  const hendleAboutus = () => {
    const aboutUsSection = document.getElementById("aboutUsSection");
    if (aboutUsSection) {
      const offset = 140;
      const scrollTop = aboutUsSection.offsetTop - offset;
      window.scrollTo({
        top: scrollTop,
        behavior: "smooth",
      });
    }
  };

  const handleOurPrograms = () => {
    navigate("/programs");
  };

  return (
    <>
      <Grid
        container
        spacing={1}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          padding: "0rem 2rem",
        }}
      >
        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
          <div className={classes.links}>
            <p></p>
            <h3>LINKS</h3>
            <ul>
              <li>
                <Link
                  to="/"
                  className={`${classes.menu_list} ${
                    activeMenuItem === "home" ? classes.activeMenuItem : ""
                  }`}
                  onClick={() => handleMenuItemClick("home")}
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/about"
                  className={`${classes.menu_list} ${
                    activeMenuItem === "about" ? classes.activeMenuItem : ""
                  }`}
                  onClick={() => {
                    hendleAboutus();
                    handleMenuItemClick("about");
                  }}
                >
                  About Us
                </Link>
              </li>
              <li
                onClick={() => {
                  handleOurPrograms();
                  handleMenuItemClick("programs");
                }}
              >
                <Link
                  to="programs"
                  className={`${classes.menu_list} ${
                    activeMenuItem === "programs" ? classes.activeMenuItem : ""
                  }`}
                >
                  Programs
                </Link>
              </li>
              <li
                onClick={() => {
                  handleMenuItemClick("Team");
                }}
              >
                <Link
                  to="/leadership"
                  className={`${classes.menu_list} ${
                    activeMenuItem === "Team" ? classes.activeMenuItem : ""
                  }`}
                >
                  LeaderShip
                </Link>
              </li>
            </ul>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <div className={classes.links}>
            <p></p>
            <h3>CONTACT US</h3>
            <h2 className={classes.address}>
              <span style={{ color: "#007ea7" }}>Corporate Office:</span>
              <br />
              <span>
                Akshara The School, Chikkalanerpu, Chintamani Taluk,
                Chikkaballapur Dist. 563125
                <br /> Karnataka
              </span>
            </h2>
            <h2 className={classes.address}>
              <span style={{ color: "#007ea7" }}>
                Marketing and Sales Office:
              </span>
              <br />
              <span>
                MDxBlocks
                <br /> Certainty Rank Academy Trust
                <br /> 251 B-Wing,First Floor,
                <br /> Orchard Corporate Park, Royal Palms,
                <br /> Goreagon East,
                <br /> Mumbai – 400065.
              </span>
            </h2>
            <div className={classes.footer_cont}>
              <div>
                <h2 className={classes.krishna_email}>
                  <PhoneIcon style={{ color: "#0f4d6b", fontSize: "2rem" }} />
                  +91 9347183823
                </h2>
                <h2 className={classes.krishna_email}>
                  <EmailIcon style={{ color: "#0f4d6b", fontSize: "2rem" }} />
                  info@certaintyrankacademy.com
                </h2>
              </div>
              <div className={classes.logo_box}>
                <img src={logo} alt="logo" />
                <div>
                  <h2>Certainty</h2> <span>Rank Academy</span>
                </div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          lg={3}
          xl={3}
          sx={{ padding: "0rem 2rem" }}
        >
          <div className={classes.links}>
            <p></p>
            <h3>SOCIAL</h3>
            <div className={classes.socials}>
              <Link
                to="https://www.instagram.com/certainty__rank__academy/?hl=en"
                target="_blank"
              >
                <InstagramIcon
                  style={{
                    border: "1px solid red",
                    backgroundColor: "red",
                    padding: "5px 5px",
                    borderRadius: "5px",
                    fontSize: "3.5rem",
                    color: "#fff",
                  }}
                />
              </Link>
              {/* <Link
                to="https://www.instagram.com/certainty__rank__academy/?hl=en"
                target="_blank"
              >
                <FacebookIcon
                  style={{
                    border: "1px solid blue",
                    backgroundColor: "blue",
                    padding: "5px 5px",
                    borderRadius: "5px",
                    fontSize: "3.5rem",
                    color: "#fff",
                  }}
                />
              </Link> */}
              <Link
                to="https://www.youtube.com/@CertaintyRankAcademy"
                target="_blank"
              >
                <YouTubeIcon
                  style={{
                    border: "1px solid #cc0000",
                    backgroundColor: "#cc0000",
                    padding: "5px 5px",
                    borderRadius: "5px",
                    fontSize: "3.5rem",
                    color: "#fff",
                  }}
                />
              </Link>
              <Link to="https://x.com/CertaintyA76743" target="_blank">
                <TwitterIcon
                  style={{
                    border: "1px solid  #330033",
                    backgroundColor: " #330033",
                    padding: "5px 5px",
                    borderRadius: "5px",
                    fontSize: "3.5rem",
                    color: "#fff",
                  }}
                />
              </Link>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default ContactFooterCont;
