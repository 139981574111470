import React, { useEffect, useState, useRef } from "react";
import { Grid } from "@mui/material";
import classes from "../LeaderShip/Directors.module.css";
import JeeMains from "../../assets/images/Computer Processor.png";
import Neet from "../../assets/images/Image by Hush Naidoo Jade Photography.png";
import Kcet from "../../assets/images/Image by Nguyen Dang Hoang Nhu.png";
import EigthStandard from "../../assets/images/Book on Table.png";
import Button from "../../common/Button";
import styles from "./ProgramDetails.module.css";
import { Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { LazyImage } from "../../utils/LazyImage";

const ProgramDetails = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Grid
          container
          spacing={1}
          sx={{ mt: 0.2 }}
          className={classes.directors_container}
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className={classes.directors_image_box}>
              <div className={classes.directors_image}>
                <LazyImage src={JeeMains} alt="krishna Bayanna" />
                <div className={styles.program_details}>
                  <Link
                    to="jee"
                    className={
                      loading
                        ? styles.program_name_loading
                        : styles.program_name
                    }
                  >
                    <h3>JEE Mains</h3>
                    <p>
                      In-depth courses focused on mastering concepts for
                      engineering entrance success.
                    </p>
                    <div className={classes.viewmore_btn_box}>
                      <Button className={styles.viewmore_btn}>View More</Button>
                    </div>
                  </Link>
                </div>
              </div>
              <div className={classes.directors_image}>
                <LazyImage src={Neet} alt="Bharathi Reddy" />
                <div className={styles.program_details}>
                  <Link to="neet" className={styles.program_name}>
                    <h3>NEET</h3>
                    <p>
                      Comprehensive preparation with guidance for excelling in
                      medical entrance exams.
                    </p>
                    <div className={classes.viewmore_btn_box}>
                      <Button className={styles.viewmore_btn}>View More</Button>
                    </div>
                  </Link>
                </div>
              </div>
              <div className={classes.directors_image}>
                <LazyImage src={Kcet} alt="P.S Narayanan" />
                <div className={styles.program_details}>
                  <Link to="kcet" className={styles.program_name}>
                    <h3>Karnataka CET</h3>
                    <p>
                      Karnataka CET Targeted instruction to help you achieve top
                      scores in Karnataka's CET.
                    </p>
                    <div className={classes.viewmore_btn_box}>
                      <Button className={styles.viewmore_btn}>View More</Button>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              mt: { xs: 1, sm: 1, lg: 10, xl: 10 },
              mb: { lg: 3, xl: 3, xs: 0, sm: 0 },
            }}
          >
            <div className={classes.directors_image_box}>
              <div className={classes.directors_image}>
                <LazyImage src={EigthStandard} alt="Eigth Standard" />
                <div className={styles.program_details}>
                  <Link
                    to="4th - 10th standard"
                    className={styles.program_name}
                  >
                    <h3>4th to 10th Standard</h3>
                    <p>
                      Foundation-building to excel in core subjects and prepare
                      for future exams.
                    </p>
                    <div className={classes.viewmore_btn_box}>
                      <Button className={styles.viewmore_btn}>View More</Button>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ProgramDetails;
