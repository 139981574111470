import { useState, useRef, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// material-ui
import { useTheme } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import LockResetIcon from "@mui/icons-material/LockReset";

// third-party

// project imports
import MainCard from "./ui-component/cards/MainCard";
import Transitions from "./ui-component/extended/Transitions";
import User1 from "../../assets/images/user-round.svg";

// assets
import { Settings, Logout } from "@mui/icons-material";
import { setPopupOpen } from "../../Redux/loginSlice";
import { setResetPasswordModal } from "../../Redux/resetPasswordSlice";
import ResetPassword from "../../authentication/Login/ResetPassword";

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
  const theme = useTheme();
  //   const customization = useSelector((state) => state.customization);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const studentData = useSelector((state) => state.loginReducer.studentData);
  const fullName = `${studentData?.studentFirstName} ${studentData?.studentLastName}`;
  const passwordResetModal = useSelector(
    (state) => state.resetPasswordReducer.resetPasswordModal
  );
  const [open, setOpen] = useState(false);
  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef(null);
  const handleLogout = async () => {
    navigate("/");
    window.location.reload();
    dispatch(setPopupOpen(true));
    localStorage.removeItem("token");
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleResetPassword = () => {
    dispatch(setResetPasswordModal(true));
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Chip
        sx={{
          height: "48px",
          alignItems: "center",
          borderRadius: "27px",
          transition: "all .2s ease-in-out",
          marginRight: "7rem",
          borderColor: theme.palette.primary.light,
          backgroundColor: theme.palette.primary.light,
          '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: theme.palette.primary.main,
            background: `${theme.palette.primary.main}!important`,
            color: theme.palette.primary.light,
            "& svg": {
              stroke: theme.palette.primary.light,
            },
          },
          "& .MuiChip-label": {
            lineHeight: 0,
          },
        }}
        icon={
          <Avatar
            src={User1}
            sx={{
              ...theme.typography.mediumAvatar,
              margin: "8px 0 8px 8px !important",
              cursor: "pointer",
            }}
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            color="inherit"
          />
        }
        label={
          <Settings
            stroke={1.5}
            color={theme.palette.primary.main}
            style={{ fontSize: "3rem" }}
          />
        }
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      />
      <Popper
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 14],
              },
            },
          ],
        }}
        style={{ maxWidth: "35rem" }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  border={false}
                  elevation={16}
                  content={false}
                  boxShadow
                  shadow={theme.shadows[16]}
                >
                  <Box sx={{ p: 2, pb: 0 }}>
                    <Stack>
                      <Stack direction="row" spacing={0.5} alignItems="center">
                        <Typography
                          component="span"
                          variant="h4"
                          sx={{ fontWeight: 400, fontFamily: "Roboto" }}
                        >
                          {fullName}
                        </Typography>
                      </Stack>
                      <Typography variant="h5" style={{ fontFamily: "Roboto" }}>
                        User - Admin
                      </Typography>
                    </Stack>
                    <Divider />
                  </Box>

                  <Box sx={{ p: 2, pt: 0 }}>
                    <List
                      component="nav"
                      sx={{
                        width: "100%",
                        maxWidth: 250,
                        minWidth: 200,
                        backgroundColor: theme.palette.background.paper,
                        borderRadius: "10px",
                        [theme.breakpoints.down("md")]: {
                          minWidth: "100%",
                        },
                        "& .MuiListItemButton-root": {
                          mt: 0.5,
                        },
                      }}
                    >
                      <ListItemButton onClick={handleResetPassword}>
                        <ListItemIcon>
                          <LockResetIcon
                            stroke={1.5}
                            size="1.3rem"
                            style={{ fontSize: "2rem" }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography
                              variant="h5"
                              style={{ fontFamily: "Roboto" }}
                            >
                              Reset Password
                            </Typography>
                          }
                        />
                      </ListItemButton>
                      <ListItemButton onClick={handleLogout}>
                        <ListItemIcon>
                          <Logout
                            stroke={1.5}
                            size="1.3rem"
                            style={{ fontSize: "2rem" }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography
                              variant="h5"
                              style={{ fontFamily: "Roboto" }}
                            >
                              Logout
                            </Typography>
                          }
                        />
                      </ListItemButton>
                    </List>
                  </Box>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
      {passwordResetModal && <ResetPassword />}
    </>
  );
};

export default ProfileSection;
