import React from "react";
import { Grid } from "@mui/material";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import HowToRegOutlinedIcon from "@mui/icons-material/HowToRegOutlined";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";
import FacebookIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser, setModelOpen, setPopupOpen } from "../../Redux/loginSlice";

import { Link, useNavigate } from "react-router-dom";
import LoginForm from "../../authentication/Login/LoginForm";
import SignupFormModel from "../../authentication/SignUp/SignupFormModel";

const MiniHeader = () => {
  const popupOpen = useSelector((state) => state.loginReducer.modelOpen);

  const token = useSelector((state) => state.loginReducer.token);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const signupHandler = () => {
    // setModelOpen(!modelOpen);
    // dispatch(setModelOpen(true));
    navigate("/register");
  };

  const loginHandler = () => {
    if (token) {
      // If the user is logged in, dispatch the logoutUser action
      dispatch(logoutUser());
      // localStorage.clear()
    } else {
      // If the user is not logged in, dispatch the setPopupOpen action to open the login form
      dispatch(setPopupOpen(true));
    }
  };

  return (
    <>
      <div className="mini_header_section">
        <Grid container spacing={1}>
          <Grid item xs={12} md={6} lg={6}>
            <div className="mini_header">
              <div className="mini_header_cont">
                <LocalPhoneIcon style={{ color: "#111", fontSize: "2rem" }} />
                <h3>+91 9347183823</h3>
              </div>
              <div className="mini_header_cont">
                <EmailIcon style={{ color: "#111", fontSize: "2rem" }} />
                <h3>info@certaintyrankacademy.com</h3>
              </div>
              <div className="mini_header_cont" onClick={loginHandler}>
                {/* {token ? ( */}
                {/* <LoginOutlinedIcon
                  style={{ color: "#111", fontSize: "2rem" }}
                /> */}
                {/* ) : ( */}
                {/* <HowToRegOutlinedIcon
                    style={{ color: "#111", fontSize: "2rem" }}
                  />
                )} */}
                <h3 className="mini_header_cont">
                  {/* {token ? "Logout" : "Login"} */}
                  <LoginOutlinedIcon
                    style={{ color: "#111", fontSize: "2rem" }}
                  />
                  Login
                </h3>
              </div>
              <div className="mini_header_cont" onClick={signupHandler}>
                {/* {token ? undefined : ( */}

                {/* )} */}
                <h3 className={`mini_header_cont${token ? "" : ""}`}>
                  <HowToRegOutlinedIcon
                    style={{ color: "#111", fontSize: "2rem" }}
                  />
                  {/* {token ? "" : "Register"} */}
                  Register
                </h3>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <div className="mini_header_socials">
              <Link
                to="https://www.instagram.com/certainty__rank__academy/?hl=en"
                target="_blank"
              >
                <InstagramIcon style={{ color: "#111", fontSize: "2rem" }} />
              </Link>
              {/* <FacebookIcon style={{ color: "#111", fontSize: "2rem" }} /> */}
              <Link
                to="https://www.youtube.com/@CertaintyRankAcademy"
                target="_blank"
              >
                <YouTubeIcon style={{ color: "#111", fontSize: "2rem" }} />{" "}
              </Link>

              <Link to="https://x.com/CertaintyA76743" target="_blank">
                <TwitterIcon style={{ color: "#111", fontSize: "2rem" }} />{" "}
              </Link>
            </div>
          </Grid>
        </Grid>
      </div>
      <SignupFormModel />
      {popupOpen && <LoginForm />}
    </>
  );
};

export default MiniHeader;
