import { configureStore } from "@reduxjs/toolkit";
import { thunk } from "redux-thunk";
import userSlice from "../userSlice";
import chatbotReducer from "../chatbotSlice";
import loginSlice from "..//loginSlice";

import excutiveDirectorsSlice from "../excutiveDirectorsSlice";
import paymentModelSlice from "../paymenyModelSlice";
import pdfSlice from "../pdfSlice";
import videoSlice from "../videoSlice";
import facultySlice from "../facultySlice";
import booleanSlice from "../booleanSlice";
import studentsSlice from "../studentsSlice";
import forgotPasswordSlice from "../forgotPasswordSlice";
import resetPasswordSlice from "../resetPasswordSlice";
import downloadPhotoSlice from "../downloadPhotoSlice";

const store = configureStore({
  reducer: {
    users: userSlice,
    chatbot: chatbotReducer,
    loginReducer: loginSlice,
    excutiveDirectorsSlice: excutiveDirectorsSlice,
    paymentModelSlice: paymentModelSlice,
    pdfReducer: pdfSlice,
    videoReducer: videoSlice,
    facultyReducer: facultySlice,
    boolean: booleanSlice,
    studentReducer: studentsSlice,
    forgotPasswordReducer: forgotPasswordSlice,
    resetPasswordReducer: resetPasswordSlice,
    downloadPhoto: downloadPhotoSlice,
    middleware: [thunk],
  },
});

export default store;
