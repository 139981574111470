import { createSlice } from "@reduxjs/toolkit";

export const chatbotSlice = createSlice({
  name: "chatbot",
  initialState: {
    open: false,
  },
  reducers: {
    openChatbot: (state) => {
      state.open = true;
    },
    closeChatbot: (state) => {
      state.open = false;
    },
  },
});

export const { openChatbot, closeChatbot } = chatbotSlice.actions;

export const selectChatbotOpen = (state) => state.chatbot.open;

export default chatbotSlice.reducer;
