import React, { useEffect } from "react";
import Headerv3 from "./Headerv3";
import UnlockAI from "./UnlockAI";
import TransfornSkills from "./TransfornSkills";
import LearningPaths from "./LearningPaths";
import CoursePrice from "./CoursePrice";
import ContactFooter from "../../common/contactFooter/ContactFooter";
import MiniHeader from "../HomePage/MiniHeader";

const NewScreen = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div style={{backgroundColor:"#f8f9fa"}}>
        <MiniHeader />
        <Headerv3 />
        <div style={{ padding: "2.5rem" }}>
          <UnlockAI />
          <TransfornSkills />
          <CoursePrice />
          <LearningPaths />
        </div>
        <ContactFooter />
      </div>
    </>
  );
};

export default NewScreen;
