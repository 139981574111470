import { Grid, Paper, TextField } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { Field, Formik } from "formik";
import Button from "../../common/Button";

const PaymentForm = () => {
  const registerData = useSelector(
    (state) => state.paymentModelSlice.registerData
  );

  const handleFormSubmit = () => {};

  return (
    <>
      <Formik
        initialValues={{
          amount: registerData.registrationfee || "",
          email: registerData.emailAddress || "",
          phone: registerData.studentMobileNumber || "",
        }}
        onSubmit={(values, { resetForm }) =>
          handleFormSubmit(values, { resetForm })
        }
      >
        {({ handleSubmit, isValid, values, dirty }) => (
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Paper className="paymnet_paper">
                <h3>Payment Details</h3>
                <Grid container spacing={1} sx={{ mt: 2 }}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <h2 className="form_label">Amount</h2>
                    <Field
                      as={TextField}
                      id="outlined-basic"
                      name="amount"
                      variant="filled"
                      type="number"
                      value={values.amount}
                      fullWidth
                      InputProps={{ style: { fontSize: "1.5rem" } }}
                      disabled
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1} sx={{ mt: 2 }}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <h2 className="form_label">Email</h2>
                    <Field
                      as={TextField}
                      id="outlined-basic"
                      name="email"
                      variant="filled"
                      type="email"
                      value={values.email}
                      fullWidth
                      InputProps={{ style: { fontSize: "1.5rem" } }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1} sx={{ mt: 2 }}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <h2 className="form_label">Phone</h2>
                    <Field
                      as={TextField}
                      id="outlined-basic"
                      variant="filled"
                      name="phone"
                      type="number"
                      value={values.phone}
                      fullWidth
                      InputProps={{ style: { fontSize: "1.5rem" } }}
                    />
                  </Grid>
                </Grid>
                <div className="payment_btn_box">
                  <Button className="payment_btn">Payment</Button>
                </div>
              </Paper>
            </Grid>
          </Grid>
        )}
      </Formik>
    </>
  );
};

export default PaymentForm;
