import React from "react";
import { Grid } from "@mui/material";
import classes from "./FutureOfAI.module.css";
import Button from "../../common/Button";
import ai from "../../../components/assets/images/ai.png";

const FutureOfAI = () => {
  return (
    <>
      <Grid container spacing={0} id="home">
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <div className={classes.main_contnet}>
            <h1 className={classes.primary_heading}>
              Discover the Future of Learning with AI
            </h1>
            <p className={classes.primary_contnet}>
              Empower your NEET , JEE & CET prep with our AI-powered,
              personalized online courses designed to enhance learning
              efficiency
            </p>
            <div className={classes.future_btns}>
              <Button className={classes.enrollnow_btn}>Register Now</Button>
              <Button className={classes.learnmore_btn}>Learn More</Button>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          {/* <div className={classes.image_container}> */}
            <img src={ai} alt="ai" className={classes.ai_image} />
          {/* </div> */}
        </Grid>
      </Grid>
    </>
  );
};

export default FutureOfAI;
