import React from "react";
import { Grid } from "@mui/material";
import classes from "./OurLeaderShip.module.css"

const OurLeaderShip = () => {
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className={classes.leadership_section}>
            <h1>Our Leadership Team</h1>
            <p>
              Our Leadership team is dedicated to revolutionizing online classes
              for competitive exams. Our seasoned educators are not only experts
              in their fields but also pioneers in integrating AI to enhance
              student performance. By leveraging cutting-edge technology, we
              ensure personalized learning experiences that help students
              achieve their highest potential. We believe the future of
              education lies in AI, empowering students to excel in a
              competitive world. Join us in preparing your child for success
              with the education of tomorrow, today.
            </p>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default OurLeaderShip;
