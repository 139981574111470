import React from "react";
import { Grid } from "@mui/material";
import classes from "./ExploreInnovation.module.css";
import teamProfile from "../../../components/assets/images/lj7a7mb5l5q4d1erbbkf.png";
import teamProfile1 from "../../../components/assets/images/vjdxzclwiaq5iy8b8que.png";
import teamProfile2 from "../../../components/assets/images/yhurbkn5i3rwyd0ttjzv_101_11zon.png";
import teamProfile3 from "../../../components/assets/images/laptoptype.png";

const ExploreInnovation = () => {
  const innovationData = [
    {
      image: teamProfile,
      title: "Virtual Experiments",
      details:
        "Delve into interactive simulations that make learning immersive and engaging.",
      date: "Jan 12-2024",
    },
    {
      image: teamProfile1,
      title: "Learning Journeys",
      details:
        "Each journey is crafted by AI to uniquely fit your pace and interests.",
      date: "Jan 02-2024",
    },
    {
      image: teamProfile2,
      title: "Interactive Lessons",
      details: "Learn complex theories through engaging, interactive lessons.",
      date: "Jan 11-2024",
    },
    {
      image: teamProfile3,
      title: "AI Learning",
      details:
        "Discover how AI transforms traditional learning into a dynamic experience.",
      date: "Jan 09-2024",
    },
  ];
  return (
    <>
      {/* <div className={classes.support_box}>
        <div className={classes.support}>
          <h1>We Support You</h1>
          <img src={vector} alt="vector" />
          <h1>Always Here to Help</h1>
        </div>
      </div> */}
      <div className={classes.innovations} id="science_features">
        <h1>Explore Insights and Innovations</h1>
        <Grid container spacing={3}>
          {innovationData.map((innovate) => (
            <Grid item xs={12} sm={12} md={6} lg={3}>
              <div className={classes.innovation_box}>
                <img src={innovate.image} alt={innovate.title} />
                <h1>{innovate.title}</h1>
                <p>{innovate.details}</p>
                {/* <h3>{innovate.date}</h3> */}
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
    </>
  );
};

export default ExploreInnovation;
