import * as Yup from "yup";

export const validationSchemaStep0 = Yup.object().shape({
  program: Yup.string().required("Program is required"),
});
export const validationSchemaStepForProgram = Yup.object().shape({
  program: Yup.mixed().test(
    "program",
    "At least one program or subject must be selected",
    function (value) {
      return (
        typeof value === "string" || (Array.isArray(value) && value.length > 0)
      );
    }
  ),
});

export const validationSchemaStep1 = Yup.object().shape({
  studentFirstName: Yup.string().required("First Name is required"),
  studentLastName: Yup.string().required("Last Name is required"),
  studentEmailAddress: Yup.string()
    .email("Invalid email")
    .required("Email is required"),
  gender: Yup.string().required("Gender is required"),
  dob: Yup.date()
    .required("Date of Birth is required")
    .max(
      new Date("2014-12-31"),
      "Date of Birth cannot be later than 2006-12-31"
    ),

  studentMobileNumber: Yup.string()
    .matches(/^[0-9]+$/, "Mobile number should contain only numbers")
    .length(10, "Mobile number should be exactly 10 digits")
    .required("Student Mobile Number is required"),
  country: Yup.string().required("Country is required"),
  state: Yup.string().required("State is required"),
  city: Yup.string().required("City is required"),
});

export const validationSchemaStep2 = Yup.object().shape({
  parentName: Yup.string().required("Parent Name is required"),
  parentEmailAddress: Yup.string()
    .email("Invalid email")
    .required("Email is required")
    .test(
      "email-not-same",
      "Parent Email Address should not be the same as student",
      function (value) {
        const studentEmail = this.parent.studentEmailAddress || "";
        return value !== studentEmail;
      }
    ),
  parentMobileNumber: Yup.string()
    .matches(/^[0-9]+$/, "Mobile number should contain only numbers")
    .length(10, "Mobile number should be exactly 10 digits")
    .required("Parent Mobile Number is required")
    .test(
      "mobile-not-same",
      "Parent mobile number should not be the same as student mobile number",
      function (value) {
        const studentMobileNumber = this.parent.studentMobileNumber || "";
        return value !== studentMobileNumber;
      }
    ),
  relationship: Yup.string().required("Relationship is required"),
  schoolName: Yup.string().required("school Name is required"),
  currentClass: Yup.string().required("Current Class is required"),
  Syllabus: Yup.string().required("Syllabus is required"),
});

export const validationSchemaStep3 = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .matches(
      /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{6,}$/,
      "Password must be 6 characters or more and include at least one digit, one uppercase letter, and one lowercase letter."
    ),

  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});
