import { toast } from "react-toastify";

export const showToast = (message, type = "success", options = {}) => {
  const defaultOptions = {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    style: {
      fontSize: "1.5rem",
    },
  };

  let customStyle = {};

  switch (type) {
    case "success":
      customStyle = {
        backgroundColor: "#4caf50",
        color: "#fff",
      };
      break;
    case "error":
      customStyle = {
        backgroundColor: "#f44336",
        color: "#fff",
      };
      break;
    case "info":
      customStyle = {
        backgroundColor: "#2196f3",
        color: "#fff",
      };
      break;
    case "warning":
      customStyle = {
        backgroundColor: "#ff9800",
        color: "#fff",
      };
      break;
    default:
      customStyle = {
        backgroundColor: "#333",
        color: "#fff",
      };
      break;
  }

  const finalOptions = {
    ...defaultOptions,
    ...options,
    style: { ...defaultOptions.style, ...customStyle },
  };

  switch (type) {
    case "success":
      toast.success(message, finalOptions);
      break;
    case "error":
      toast.error(message, finalOptions);
      break;
    case "info":
      toast.info(message, finalOptions);
      break;
    case "warning":
      toast.warning(message, finalOptions);
      break;
    default:
      toast(message, finalOptions);
      break;
  }
};
