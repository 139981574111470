import React from "react";

const ComingSoon = () => {
  return (
    <div
      style={{
        textAlign: "center",
        fontSize: "1.5rem",
      }}
    >
      <h1>ComingSoon.....</h1>
    </div>
  );
};

export default ComingSoon;
