import React, { useEffect, useState } from "react";
import classes from "../pages/Programs/CourseEnrollment.module.css";

const DemoClassVideo = ({ onClose }) => {
  const [videoUrl, setVideoUrl] = useState("");

  useEffect(() => {
    const sasTokenUrl =
      "https://cravideos.blob.core.windows.net/demofiles/Physics.mp4?sp=r&st=2024-06-04T09:28:58Z&se=2024-06-04T17:28:58Z&sv=2022-11-02&sr=c&sig=LJ3GJWxWi0v2yG%2FkJrgfwx2A92KsKTL9vUlth51xkzU%3D";
    console.log("SAS Token URL:", sasTokenUrl);
    setVideoUrl(sasTokenUrl);
  }, []);

  return (
    <div className={classes.demo_video_box}>
      <h1 style={{ fontSize: "2.5rem", color: "#0f4d6b" }}>
        Watch our Demo Class
      </h1>
      <video
        id="videoPlayer"
        src={videoUrl}
        controls
        autoPlay
        className={classes.demo_video}
      />
    </div>
  );
};

export default DemoClassVideo;
