import { Dialog, Grid, styled } from "@mui/material";
import React, { useEffect } from "react";
import gino from "../../assets/images/gino4.png";
import { useDispatch, useSelector } from "react-redux";
import {
  setGinoModalOpen,
  setTushaarModalOpen,
} from "../../Redux/excutiveDirectorsSlice";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";

const GinoPage = () => {
  const dispatch = useDispatch();

  const ginoModal = useSelector(
    (state) => state.excutiveDirectorsSlice.ginoModalOpen
  );

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
    "& .MuiPaper-root": {
      width: "80%",
      maxWidth: "1200px",
    },
  }));

  const handleClose = () => {
    dispatch(setGinoModalOpen(!ginoModal));
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* <Header /> */}
      {/* <Back /> */}
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={ginoModal}
      >
        <div className="directors_page">
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div>
                <CloseSharpIcon
                  style={{
                    float: "right",
                    fontSize: "4rem",
                    cursor: "pointer",
                  }}
                  onClick={handleClose}
                />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ padding: "0rem 4rem" }}>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <p></p>
              <h1>Gino Valverde</h1>
              <h2>Director - Consulting</h2>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <div className="krishna_img">
                <img src={gino} alt="krishna" className="directors_img" />
              </div>
            </Grid>
          </Grid>
        </div>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div className="directors_profile_cont">
              <p>
                Gino Valverde is the founder of Ganas Consulting, an advisory
                practice specializing in business transformation services. He
                brings over twenty years of experience in the assessment,
                design, transition, and management of corporate functions,
                including 14-years in the outsourcing industry where he built
                and led a variety of teams across the U.S, U.K., India, Japan,
                Hong Kong, and the Philippines. Gino currently resides in
                Houston, Texas, where, since 2012, he has managed numerous
                transformation initiatives and corporate transactions. Most
                recently, he served as COO and Head of Product Development for
                an industry consortium of oil & gas companies developing and
                deploying blockchain technologies.
              </p>
            </div>
          </Grid>
        </Grid>
      </BootstrapDialog>
      {/* <Footer /> */}
    </>
  );
};

export default GinoPage;
