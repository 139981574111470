import { Grid } from "@mui/material";
import React from "react";
import classes from "./MathMastery.module.css";
import nikeShoes from "../../../components/assets/images/dan-cristian-padure-xJLN32FO7AY-unsplash.png";

const MathMastery = () => {
  return (
    <>
      <Grid container spacing={1} sx={{ mt: 5 }} id="math_about">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <h1 className={classes.primary_heading}>
            Improving Your Math Mastery
          </h1>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        sx={{
          mt: { xs: -9, sm: -9, md: -9, lg: 5 },
        }}
      >
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <h1 className={classes.secondary_heading}>
            Personalized Learning Paths
          </h1>
          <p className={classes.secondary_contnet}>
            Our fun and interactive lessons make math easy to understand and
            enjoyable. Using smart AI technology, we tailor practice to your
            needs, helping you get better at your own speed. With videos,
            quizzes, and games, you'll quickly improve your math skills and gain
            confidence. Track your progress with detailed reports and celebrate
            your achievements as you reach new milestones. Our supportive
            community and expert tutors are always here to help you with any
            questions or challenges. Explore math in a way that suits you best,
            and watch your skills grow stronger every day. Join us to make
            learning math exciting and rewarding!
          </p>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <img
            src={nikeShoes}
            alt="nike shoes"
            className={classes.pencil_image}
          />
        </Grid>
      </Grid>
      <Grid container spacing={0} id="math_features">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <h1 className={classes.primary_heading}>Features & Benefits</h1>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <div className={classes.third}>
          <Grid item xs={12} sm={12} md={12} lg={3}>
            <h1 className={classes.third_heading}>AI Assessment</h1>
            <p className={classes.third_contnet}>
              Initial AI assessments to tailor your perfect learning path.
            </p>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={3}>
            <h1 className={classes.third_heading}>Interactive Quizzes</h1>
            <p className={classes.third_contnet}>
              Engaging quizzes that reinforce learning and understanding.
            </p>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={3}>
            <h1 className={classes.third_heading}>Progress Tracking</h1>
            <p className={classes.third_contnet}>
              Monitor your achievements and spot areas that need improvement.
            </p>
          </Grid>
        </div>
      </Grid>
      <Grid container spacing={0}>
        <div className={classes.third}>
          <Grid item xs={12} sm={12} md={12} lg={3}>
            <h1 className={classes.third_heading}>Algorithmic Practice</h1>
            <p className={classes.third_contnet}>
              Hone problem-solving skills with algorithm-based exercises.
            </p>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={3}>
            <h1 className={classes.third_heading}>Graphical Aids</h1>
            <p className={classes.third_contnet}>
              Utilize graphical aids to visualize and understand complex
              concepts.
            </p>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={3}>
            <h1 className={classes.third_heading}>Live Tutor Support</h1>
            <p className={classes.third_contnet}>
              Access live tutor support for personalized guidance and
              clarifications.
            </p>
          </Grid>
        </div>
      </Grid>
      <Grid container spacing={0} sx={{ mt: -1 }}>
        <div className={classes.third}>
          <Grid item xs={12} sm={12} md={12} lg={3}>
            <h1 className={classes.third_heading}>Syllabus Coverage</h1>
            <p className={classes.third_contnet}>
              Comprehensive syllabus coverage from CBSE/ICSE/State boards for
              grades 5 and above.
            </p>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={3}>
            <h1 className={classes.third_heading}>Puzzle Challenges </h1>
            <p className={classes.third_contnet}>
              Tackle interactive puzzles to apply and test your math knowledge.
            </p>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={3}>
            <h1 className={classes.third_heading}>Custom Reports</h1>
            <p className={classes.third_contnet}>
              Receive detailed reports on your progress and areas for
              improvement.
            </p>
          </Grid>
        </div>
      </Grid>
      {/* <Grid
        container
        spacing={1}
        sx={{ mt: { xs: -5, sm: -5, md: -5, lg: 8 } }}
      >
        <Grid item xs={12} sm={12} md={12} lg={4.2}>
          <h1 className={classes.secondary_heading}>Elevate Your Math Game</h1>
          <p className={classes.secondary_contnet}>
            With features like AI assessments and progress tracking, Math Matrix
            ensures a comprehensive learning journey.
          </p>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={7.8}>
          <img src={eleveate} alt="pencils" className={classes.pencil_image} />
        </Grid>
      </Grid> */}
    </>
  );
};

export default MathMastery;
