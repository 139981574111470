import React, { useEffect } from "react";
import HeaderMath from "./HeaderMath";
import MathWithAI from "./MathWithAI";
import MathCuriosity from "./MathCuriosity";
import MathMastery from "./MathMastery";
import ContactFooter from "../../common/contactFooter/ContactFooter";
import MathCoursePrice from "./MathCoursePrice";
import MiniHeader from "../HomePage/MiniHeader";

const Math = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div style={{ backgroundColor: "#f8f9fa" }}>
        <MiniHeader />
        <HeaderMath />
        <div style={{ padding: "2.5rem" }}>
          <MathWithAI />
          <MathCuriosity />
          <MathCoursePrice />
          <MathMastery />
          {/* <ContactUs /> */}
          {/* <GetInTouchContactData /> */}
          {/* <JoinusToday /> */}
        </div>
        <ContactFooter />
        {/* <Footer /> */}
      </div>
    </>
  );
};

export default Math;
