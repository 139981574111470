import React, { useEffect } from "react";
import About from "./About";
import Header from "../HomePage/Header";
import ContactFooter from "../../common/contactFooter/ContactFooter";
import SecondaryEducation from "./SecondaryEducation";
import JeeMainExam from "./JeeMainExam";
import CoursesUnderNeet from "./CoursesUnderNeet";
import WelcomePage from "./WelcomePage";
import KcetExam from "./KcetExam";
import TNEA from "./TNEA";

const AboutPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <WelcomePage />
      <About />
      {/* <SecondaryEducation /> */}
      {/* <JeeMainExam />
      <CoursesUnderNeet />
      <TNEA />
      <KcetExam /> */}
    </>
  );
};

export default AboutPage;
