import React, { useCallback, useMemo } from "react";
import classes from "./CourseEnrollment.module.css";
import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";

const Features = () => {
  const { programName } = useParams();

  const capitalizeAllLetters = useCallback((string) => {
    return string.toUpperCase();
  }, []);

  const capitalizedProgramName = useMemo(
    () => capitalizeAllLetters(programName),
    [programName, capitalizeAllLetters]
  );

  const isStandardProgram = useMemo(
    () => ["5TH - 10TH STANDARD"].includes(capitalizedProgramName),
    [capitalizedProgramName]
  );
  return (
    <>
      <h1 id="feature_section">Features</h1>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={isStandardProgram ? 12 : 4}
          lg={isStandardProgram ? 12 : 4}
          xl={isStandardProgram ? 12 : 4}
        >
          <div
            className={
              isStandardProgram
                ? classes.basic_feature_item
                : classes.feature_item
            }
          >
            <h2>Basic</h2>
            <div className={classes.price}>
              <h2>
                {isStandardProgram
                  ? "₹700 per Subject"
                  : capitalizedProgramName === "KCET"
                  ? "₹3,000 per Subject"
                  : "₹5000 per Subject"}
              </h2>
            </div>
            <ul className={classes.featureList}>
              <li>
                <span className={classes.tickIcon}>✔</span> Recorded Classes
              </li>
              <li>
                <span className={classes.tickIcon}>✔</span> 24/7 Chat Bot for
                Queries
              </li>
              <li>
                <span className={classes.tickIcon}>✔</span> Total Duration:
                {isStandardProgram
                  ? "100 hours"
                  : capitalizedProgramName === "KCET"
                  ? "100 hours"
                  : "250 hours"}
              </li>
              <li>
                <span className={classes.tickIcon}>✔</span> Time frame: 10
                months
              </li>
              {isStandardProgram ? (
                ""
              ) : (
                <li>
                  <span className={classes.tickIcon}>✔</span>Registration Fee:
                  {capitalizedProgramName === "KCET" ? "Rs 100₹" : "Rs 250₹"}
                </li>
              )}
            </ul>
          </div>
        </Grid>
        {!isStandardProgram && (
          <>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <div className={classes.feature_item}>
                <h2>Standard</h2>
                <div className={classes.price}>
                  <h2>
                    {capitalizedProgramName === "KCET"
                      ? "₹5,000 per Subject"
                      : "₹10,000 per Subject"}
                  </h2>
                </div>
                <ul className={classes.featureList}>
                  <li>
                    <span className={classes.tickIcon}>✔</span> Everything in
                    Basic
                  </li>
                  <li>
                    <span className={classes.tickIcon}>✔</span> AI Enhanced
                    Education
                  </li>
                  <li>
                    <span className={classes.tickIcon}>✔</span> Showcase
                    Testimonials and Case Studies
                  </li>
                  <li>
                    <span className={classes.tickIcon}>✔</span> Group Tutoring
                  </li>
                </ul>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <div className={classes.feature_item}>
                <h2>Premium</h2>
                <div className={classes.price}>
                  <h2>
                    {capitalizedProgramName === "KCET"
                      ? "₹10,000 per Subject"
                      : "₹25,000 per Subject"}
                  </h2>
                </div>
                <ul className={classes.featureList}>
                  <li>
                    <span className={classes.tickIcon}>✔</span> Everything in
                    Standard
                  </li>
                  <li>
                    <span className={classes.tickIcon}>✔</span> Personalized
                    Tutoring with AI Insights
                  </li>
                  <li>
                    <span className={classes.tickIcon}>✔</span> Sessions from
                    Top-Tier Educators
                  </li>
                  <li>
                    <span className={classes.tickIcon}>✔</span> AI for
                    Personalized Learning Analytics
                  </li>
                  <li>
                    <span className={classes.tickIcon}>✔</span> Human Tutors for
                    Nuanced Guidance
                  </li>
                  <li>
                    <span className={classes.tickIcon}>✔</span> Weekly 1-on-1
                    Tutoring Sessions
                  </li>
                  <li>
                    <span className={classes.tickIcon}>✔</span> Continuous
                    Progress Assessments by Expert Educators
                  </li>
                  <li>
                    <span className={classes.tickIcon}>✔</span> AI-Driven Course
                    Materials and Automated Feedback
                  </li>
                  <li>
                    <span className={classes.tickIcon}>✔</span> Initial Discount
                    or Value-Add for Early Subscribers
                  </li>
                  <li>
                    <span className={classes.tickIcon}>✔</span> Awards and
                    Scholarships
                  </li>
                </ul>
              </div>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default Features;
