import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  paymentModelOpen: false,
  registerData: [],
};

const paymentModelSlice = createSlice({
  name: "paymentModel",
  initialState,
  reducers: {
    setPaymentModelOpen: (state, action) => {
      state.paymentModelOpen = action.payload;
    },
    setRegisterData: (state, action) => {
      state.registerData = action.payload;
    },
  },
});

export const { setPaymentModelOpen, setRegisterData } =
  paymentModelSlice.actions;

export default paymentModelSlice.reducer;
