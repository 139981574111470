import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { IconButton, InputAdornment, Typography } from "@mui/material";
import {
  passwordReset,
  setResetPasswordModal,
} from "../../Redux/resetPasswordSlice";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

const validationSchema = Yup.object().shape({
  currentPassword: Yup.string().required("Current Password is Required"),
  newPassword: Yup.string()
    .required("Password is required")
    .matches(
      /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{6,}$/,
      "Password must be 6 characters or more and include at least one digit, one uppercase letter, and one lowercase letter."
    ),

  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

const ResetPassword = () => {
  const dispatch = useDispatch();
  const passwordResetModal = useSelector(
    (state) => state.resetPasswordReducer.resetPasswordModal
  );
  const studentData = useSelector((state) => state.loginReducer.studentData);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const toggleCurrentPasswordVisibility = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  const handleClose = () => {
    dispatch(setResetPasswordModal(false));
  };

  const handleSubmit = (values) => {
    const { confirmPassword, ...postData } = values;
    dispatch(passwordReset(postData));
    handleClose();
  };

  return (
    <Dialog
      open={passwordResetModal}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": {
          width: "100%",
          maxWidth: "600px",
          minHeight: "200px",
          maxHeight: "70vh",
        },
      }}
    >
      <DialogTitle variant="h4">Reset Password</DialogTitle>
      <Formik
        initialValues={{
          studentId: studentData && studentData._id,
          currentPassword: "",
          newPassword: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValid,
          isDirty,
        }) => (
          <Form onSubmit={handleSubmit}>
            <DialogContent>
              {/* <Typography variant="h5">
                Please enter your email address, which you have registred with
              </Typography> */}

              <Field
                id="outlined-basic"
                fullWidth
                variant="outlined"
                name="currentPassword"
                as={TextField}
                type={showPassword ? "text" : "password"}
                placeholder="Current Password*"
                InputProps={{
                  style: {
                    fontSize: "1.5rem",
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={togglePasswordVisibility}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOutlinedIcon
                            style={{
                              fontSize: "2rem",
                              fontWeight: "700",
                            }}
                          />
                        ) : (
                          <VisibilityOffOutlinedIcon
                            style={{
                              fontSize: "2rem",
                              fontWeight: "700",
                            }}
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{ mb: 1 }}
              />
              <ErrorMessage
                name="currentPassword"
                component="div"
                className="error"
              />
              <Field
                id="outlined-basic"
                fullWidth
                variant="outlined"
                name="newPassword"
                as={TextField}
                type={showConfirmPassword ? "text" : "password"}
                placeholder="New Password*"
                InputProps={{
                  style: {
                    fontSize: "1.5rem",
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={toggleConfirmPasswordVisibility}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOutlinedIcon
                            style={{
                              fontSize: "2rem",
                              fontWeight: "700",
                            }}
                          />
                        ) : (
                          <VisibilityOffOutlinedIcon
                            style={{
                              fontSize: "2rem",
                              fontWeight: "700",
                            }}
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{ mb: 1 }}
              />
              <ErrorMessage
                name="newPassword"
                component="div"
                className="error"
              />
              <Field
                id="outlined-basic"
                fullWidth
                variant="outlined"
                name="confirmPassword"
                as={TextField}
                type={showCurrentPassword ? "text" : "password"}
                placeholder="Confirm Password*"
                InputProps={{
                  style: {
                    fontSize: "1.5rem",
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={toggleCurrentPasswordVisibility}
                        edge="end"
                      >
                        {showCurrentPassword ? (
                          <VisibilityOutlinedIcon
                            style={{
                              fontSize: "2rem",
                              fontWeight: "700",
                            }}
                          />
                        ) : (
                          <VisibilityOffOutlinedIcon
                            style={{
                              fontSize: "2rem",
                              fontWeight: "700",
                            }}
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{ mb: 1 }}
              />
              <ErrorMessage
                name="confirmPassword"
                component="div"
                className="error"
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                variant="outlined"
                size="large"
                style={{
                  background: "#e9ecef",
                  border: "none",
                  fontSize: "1.5rem",
                  color: "#495057",
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="outlined"
                size="large"
                // disabled={!isValid || !isDirty}
                onClick={() => handleSubmit()}
                style={{
                  background: "#1976D2",
                  color: "#fff",
                  fontSize: "1.5rem",
                }}
              >
                Submit
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default ResetPassword;
