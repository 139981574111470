import { Dialog, Grid, styled } from "@mui/material";
import React, { useEffect } from "react";
import tushar from "../../assets/images/tushar.png";
import { useDispatch, useSelector } from "react-redux";
import { setTushaarModalOpen } from "../../Redux/excutiveDirectorsSlice";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";

const TusharPage = () => {
  const dispatch = useDispatch();

  const tushaarModal = useSelector(
    (state) => state.excutiveDirectorsSlice.tushaarModalOpen
  );

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
    "& .MuiPaper-root": {
      width: "80%",
      maxWidth: "1200px",
    },
  }));

  const handleClose = () => {
    dispatch(setTushaarModalOpen(!tushaarModal));
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* <Header /> */}
      {/* <Back /> */}
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={tushaarModal}
      >
        <div className="directors_page">
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div>
                <CloseSharpIcon
                  style={{
                    float: "right",
                    fontSize: "4rem",
                    cursor: "pointer",
                  }}
                  onClick={handleClose}
                />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ padding: "0rem 4rem" }}>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <p></p>
              <h1>Dr. Tushaar Jagadish</h1>
              <h2>Teaching, Admission and Management</h2>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <div className="krishna_img">
                <img src={tushar} alt="krishna" className="directors_img" />
              </div>
            </Grid>
          </Grid>
        </div>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div className="directors_profile_cont">
              <p>
                Dr. Tushaar Jagadish holds qualifications of M.Com, MBA, PGDBA,
                PGDPM. RBR. and Phd. He has 37 years of experience in teaching,
                administration, and management. He has written books in Commerce
                discipline for PUC, B.Com, M.Com and MBA.
              </p>
              <p>
                He has worked as Public Relations Officer, Administrator,
                Manager, Principal and Lecturer in Commerce for various
                Institutions which are PU and Degree Colleges, Manipal group of
                Companies at Bengaluru and Manipal.
              </p>
              <p>
                He has expertise in operations, correspondence, liaison,
                administration and management of educational institutions and
                offices including liaison work of Colleges and Universities.
              </p>
              <p>
                He has received Awards as "Certified Master Trainer" awarded
                from Electronics Sector skill Council, National Development
                Skill Council under Ministry of Skill Development and
                Entrepreneurship; "Clap Award" from Navriti Technologies; "Jagad
                Guru Shankaracharya National Award 2006 awarded by National
                Centre for R&D in Education, Science and Technology, Mumbai;
                "Man of the Year 2006" awarded by Bengaluru Mahanagara
                Palike, Bengaluru.
              </p>
            </div>
          </Grid>
        </Grid>
      </BootstrapDialog>
      {/* <Footer /> */}
    </>
  );
};

export default TusharPage;
