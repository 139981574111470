import React from "react";
import EarningCard from "./EarningCard";
import TotalOrderLineCard from "./OrderCard";
import { Grid } from "@mui/material";

const Dashboard = () => {
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <EarningCard />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <TotalOrderLineCard />
        </Grid>
      </Grid>
    </>
  );
};

export default Dashboard;
