import React from "react";
import { Grid } from "@mui/material";
import classes from "./ScienceProgram.module.css";
import Button from "../../common/Button";
import write from "../../../components/assets/images/mxkd4mltgd3pmvm7lvdf.png";
import ar from "../../../components/assets/images/zjswaqrhelflibonc7hv.png";
import arrowUp from "../../../components/assets/images/iconsvg_1699528107_25365.png";

const ScienceProgram = () => {
  return (
    <>
      <div className={classes.container} id="science_about">
        <Grid container spacing={10}>
          <Grid item xs={12} sm={12} md={12} lg={5}>
            <img src={write} alt="light" className={classes.light_image} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={7}>
            <div className={classes.science_program}>
              <h1 className={classes.primary_heading}>
                Discover New Dimensions of Learning with Our Advanced Science
                Programs
              </h1>
              <p className={classes.primary_contnet}>
                Our programs are designed for the curious minds of 5th Standard
                students onwards, aligned with CBSE/ICSE/State syllabi, offering
                hands-on virtual labs, customized learning experiences, and
                interactive feedback mechanisms to enrich your learning.
              </p>
              <div className={classes.joinus_container}>
                <Button className={classes.learn_more_btn}>Learn More</Button>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={10} sx={{ mt: 5 }}>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <h1 className={classes.secondary_heading}>
              Transform Your Understanding of Science Today
            </h1>
            <p className={classes.secondary_contnet}>
              Embrace a fully immersive learning environment where science comes
              alive through virtual reality, interactive simulations, and
              AI-driven customization to suit your pace and preferences.
            </p>
            <div className={classes.ai_way}>
              <h1>AI-Customized</h1>
              <img src={arrowUp} alt="arrowUp" />
            </div>
            <div className={classes.ai_way}>
              <h1 className={classes.learning_pace}>Learning at Pace</h1>
              <img src={arrowUp} alt="arrowUp" />
            </div>
            <div className={classes.ai_way}>
              <h1 className={classes.learning_pace}>Instant Feedback</h1>
              <img src={arrowUp} alt="arrowUp" />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={5}>
            <img src={ar} alt="light" className={classes.light_image} />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default ScienceProgram;
