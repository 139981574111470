import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { authHeader } from "../common/authHeader";
import { showToast } from "../utils/Toasts";
// import { authHeader } from "../../helpers/auth-header";

const initialState = {
  loading: false,
  facultyData: [],
  selectedFacultyRowData: [],
  error: null,
  editFacultyData: false,
  addFaculty: false,
};

export const getFacultyData = createAsyncThunk(
  "faculty/getFacultyData",
  async () => {
    const requestOptions = {
      headers: authHeader(),
    };
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/teachers`,
      requestOptions
    );
    return response.data.data;
  }
);

export const updateFacultyData = createAsyncThunk(
  "faculty/updateFacultyData",
  async (updatedFaculty) => {
    const requestOptions = {
      headers: authHeader(),
    };
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/teacher/${updatedFaculty._id}`,
      updatedFaculty,
      requestOptions
    );
    if (response.status === 200 && response.data.success === true) {
      showToast(response.data.data, "success");
    }
    return response.data.data;
  }
);

const facultySlice = createSlice({
  name: "faculty",
  initialState,
  reducers: {
    setFacultyData: (state, action) => {
      state.facultyData = action.payload;
    },
    setSelectedFacultyRowData: (state, action) => {
      state.selectedFacultyRowData = action.payload;
    },
    setFacultyEditData: (state, action) => {
      state.editFacultyData = action.payload;
    },
    setAddFaculty: (state, action) => {
      state.addFaculty = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFacultyData.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getFacultyData.fulfilled, (state, action) => {
      state.loading = false;
      state.facultyData = action.payload;
      state.error = null;
    });
    builder.addCase(getFacultyData.rejected, (state, action) => {
      state.facultyData = [];
      state.error = action.payload;
    });
  },
});

export const {
  setFacultyData,
  setSelectedFacultyRowData,
  setFacultyEditData,
  setAddFaculty,
} = facultySlice.actions;
export default facultySlice.reducer;
