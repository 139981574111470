import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const fetchVideoUrl = createAsyncThunk(
  "video/fetchVideoUrl",
  async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/video/Physics.mp4`
    );
    const data = await response.json();
    return data.videoUrl;
  }
);

const videoSlice = createSlice({
  name: "video",
  initialState: {
    videoUrl: "",
    status: "idle",
    error: null,
  },
  reducers: {
    clearVideoUrl: (state) => {
      state.videoUrl = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchVideoUrl.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchVideoUrl.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.videoUrl = action.payload;
      })
      .addCase(fetchVideoUrl.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { clearVideoUrl } = videoSlice.actions;

export default videoSlice.reducer;
