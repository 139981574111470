import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { showToast } from "../utils/Toasts";
import { authHeader } from "../common/authHeader";

export const passwordReset = createAsyncThunk(
  "passwordChange/passwordReset",
  async (values, { rejectWithValue }) => {
    const requestOptions = {
      headers: authHeader(),
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/reset-password`,
        values, // Pass the values as the second parameter
        requestOptions // Pass the requestOptions as the third parameter
      );
      if (response.data.success === true) {
        showToast(response.data.data);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const forgotPasswordSlice = createSlice({
  name: "passwordChange",
  initialState: {
    loading: false,
    error: null,
    success: false,
    resetPasswordModal: false,
  },
  reducers: {
    resetState: (state) => {
      state.loading = false;
      state.error = null;
      state.success = false;
    },
    setResetPasswordModal: (state, action) => {
      state.resetPasswordModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(passwordReset.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(passwordReset.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(passwordReset.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetState, setResetPasswordModal } =
  forgotPasswordSlice.actions;
export default forgotPasswordSlice.reducer;
