import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GridActionsCellItem } from "@mui/x-data-grid";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CircularProgress from "@mui/material/CircularProgress";

import Table from "../../../common/Table";
import {
  getFacultyData,
  setAddFaculty,
  setFacultyData,
  setFacultyEditData,
  setSelectedFacultyRowData,
} from "../../../Redux/facultySlice";
import AddFaculty from "./AddFaculty";
import { useNavigate } from "react-router-dom";
import EditFacultyData from "./EditFacultyData";

const FacultyDetails = () => {
  const facultyData = useSelector((state) => state.facultyReducer.facultyData);
  const navigate = useNavigate();
  const editFacultyData = useSelector(
    (state) => state.facultyReducer.editFacultyData
  );
  const addFacultyData = useSelector(
    (state) => state.facultyReducer.addFaculty
  );
  const loading = useSelector((state) => state.facultyReducer.loading);
  const toggleOpen = useSelector((state) => state.boolean.toggleOpen);

  const dispatch = useDispatch();
  const columns = [
    {
      field: "fullName",
      headerName: "Full Name",
      flex: 1,
      align: "left",
      headerAlign: "left",
    },

    {
      field: "age",
      headerName: "Age",
      flex: 1,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "gender",
      headerName: "Gender",
      flex: 1,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "emailAddress",
      headerName: "Email Address",
      flex: 1,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "mobileNumber",
      headerName: "Mobile Number",
      flex: 1,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "qualification",
      headerName: "Qualification",
      flex: 1,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "subjects",
      headerName: "Subjects",
      flex: 1,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "experienceInYears",
      headerName: "Experience In Years",
      flex: 1,
      align: "left",
      headerAlign: "left",
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: (rowData) => [
        <GridActionsCellItem
          icon={
            <EditOutlinedIcon
              style={{ fontSize: "2rem", color: "#1a2e44", cursor: "pointer" }}
              onClick={() => handleEdit(rowData.row)}
            />
          }
        />,
        // <GridActionsCellItem
        //   icon={
        //     <DeleteOutlineOutlinedIcon
        //       style={{ fontSize: "2rem", color: "#1a2e44", cursor: "pointer" }}
        //       onClick={() => handleDelete(rowData.id)}
        //     />
        //   }
        // />,
      ],
    },
  ];

  const handleEdit = (rowData) => {
    dispatch(setSelectedFacultyRowData(rowData));
    dispatch(setFacultyEditData(!editFacultyData));
    navigate(`/facultyDetails/${rowData._id}`);
  };

  const handleDelete = (id) => {
    const filteredData = facultyData.filter((item) => item._id !== id);
    dispatch(setFacultyData(filteredData));
  };

  const handleAddFaculty = () => {
    dispatch(setAddFaculty(true));
  };

  const formattedStudentsData = Array.isArray(facultyData)
    ? facultyData.map((faculty) => ({
        ...faculty,
        fullName: `${faculty.firstName} ${faculty.lastName}`,
      }))
    : [];

  useEffect(() => {
    if (facultyData.length === 0) {
      dispatch(getFacultyData());
    }
  }, [dispatch, facultyData]);

  return (
    <>
      <div className={toggleOpen ? "" : "main_contnet_full"}>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "25%",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <button className="add_faculty" onClick={handleAddFaculty}>
              +Add Faculty
            </button>
            <div className="faculty_table">
              <Table columns={columns} rows={formattedStudentsData} />
            </div>
          </>
        )}
        {addFacultyData && <AddFaculty />}
        {editFacultyData && <EditFacultyData />}
      </div>
    </>
  );
};

export default FacultyDetails;
