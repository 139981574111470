import React, { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { useDispatch, useSelector } from "react-redux";
// import { menuToggle } from "../../components/Redux/booleanSlice";
// import { searchChanger } from "../../components/Redux/fieldSlice";
import { Avatar, Box, Grid, Popover } from "@mui/material";
import { CgMenuLeft } from "react-icons/cg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import NoteOutlinedIcon from "@mui/icons-material/NoteOutlined";
// import { logoutUser } from "../../components/Redux/loginSlice";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import { menuToggle } from "../../Redux/booleanSlice";
import { setPopupOpen } from "../../Redux/loginSlice";
import logo from "../../assets/images/stamop-size-logo.PNG";
import ProfileSection from "./ProfileSection";

const AdminHeader = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const toggleOpen = useSelector((state) => state.boolean.toggleOpen);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const menuHandler = () => {
    dispatch(menuToggle(!toggleOpen));
  };

  const handleAdminOpen = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const encryptedData = localStorage.getItem("studentData");
  let studentData;
  if (encryptedData) {
    const decryptedBytes = CryptoJS.AES.decrypt(
      encryptedData,
      process.env.REACT_APP_SECRET_KEY
    );
    const decryptedData = decryptedBytes.toString(CryptoJS.enc.Utf8);

    // Parse the decrypted data if it's a JSON string
    studentData = JSON.parse(decryptedData);
  }

  const handleLogout = () => {
    // dispatch(logoutUser());
    navigate("/");
    // window.location.reload();
  };
  return (
    <header className="admin_header">
      <div className="admin_menu_content">
        <div className="admin_logo_container">
          <div className="logo_box">
            <img src={logo} alt="logo" className="logo" />
            <div>
              <h2 style={{ fontFamily: "Poppins", fontSize: "2.9rem" }}>
                CERTAINTY
              </h2>
              <h3 style={{ fontFamily: "Poppins" }}>RANK ACADEMY</h3>
            </div>
          </div>
          <CgMenuLeft
            style={{
              fontSize: "4rem",
              marginLeft: "1.5rem",
              cursor: "pointer",
              background: "#ede7f6",
              borderRadius: "0.7rem",
              padding: "0.5rem 0.5rem",
              color: "rgb(94, 53, 177)",
            }}
            className="admin_menu_icon"
            onClick={menuHandler}
          />
        </div>
      </div>

      <ProfileSection />
    </header>
  );
};

export default AdminHeader;
