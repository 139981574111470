import { Dialog, Grid, styled } from "@mui/material";
import React, { useEffect } from "react";
import krishna from "../../assets/images/Krishna.png";
import { useDispatch, useSelector } from "react-redux";
import { setDirectorsModalOpen } from "../../Redux/excutiveDirectorsSlice";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { useNavigate } from "react-router-dom";

const ExcutiveDirectorsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const directorsModal = useSelector(
    (state) => state.excutiveDirectorsSlice.directorsModalOpen
  );

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
    "& .MuiPaper-root": {
      width: "80%",
      maxWidth: "1200px",
    },
  }));

  const handleClose = () => {
    dispatch(setDirectorsModalOpen(!directorsModal));
    navigate("/leadership");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* <Header /> */}
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={directorsModal}
      >
        {/* <Back /> */}
        <div className="directors_page">
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div>
                <CloseSharpIcon
                  style={{
                    float: "right",
                    fontSize: "4rem",
                    cursor: "pointer",
                  }}
                  onClick={handleClose}
                />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ padding: "0rem 4rem" }}>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <p></p>
              <h1>Krishna Reddy Bayanna</h1>
              <h2>The visionary CEO/CTO of MDxBlocks Inc.</h2>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <div className="krishna_img">
                <img src={krishna} alt="krishna" className="directors_img" />
              </div>
            </Grid>
          </Grid>
        </div>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div className="directors_profile_cont">
              <p>
                Krishna Reddy Bayanna, the visionary CEO/CTO of MDxBlocks Inc.,
                brings over 28 years of profound expertise in the IT realm, with
                a special focus on pioneering AI-enabled security and
                observability solutions.
              </p>
              <p>
                Beyond his remarkable contributions to MDxBlocks, Krishna has
                founded Akshara The School, a beacon of modern educational
                methodologies designed to equip children with the skills needed
                for a brighter future.
              </p>
              <p>
                His journey through various industries including Education,
                Retail, Energy, Healthcare, and more, has led him to the
                forefront of Web 3.0 Technologyparticularly in the field of
                decentralized applications. With a history of successful
                collaborations with leading multinational corporations and
                significant public agencies, Krishna's acumen spans a broad
                spectrum from nanotechnology and biotechnology to material
                science and environmental engineering.
              </p>
              <p>
                His expertise is not confined to a single sector but stretches
                across industries, including Education, Food, Banking, Retail,
                Energy, and Healthcare, showcasing his versatility and
                pioneering role in Web 4.0 Technology and decentralized
                applications. A fervent philanthropist at heart, Krishna
                champions the cause of making education accessible to children
                in rural and semi-urban areas, driven by the belief that
                education is a fundamental right for everyone. Through Certainty
                Rank Academy, Krishna Reddy Bayanna extends his vision of
                integrating cutting-edge AI, blockchain, and cloud technologies
                to revolutionize learning and professional development. His
                dedication to R&D and experimentation in AI not only enhances
                the educational landscape but also sets a new standard for
                leadership in the technological domain,
              </p>
            </div>
          </Grid>
        </Grid>
        {/* <Footer /> */}
      </BootstrapDialog>
    </>
  );
};

export default ExcutiveDirectorsPage;
