import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  toggleOpen: true,
};

const booleanSlice = createSlice({
  name: "boolean",
  initialState,
  reducers: {
    menuToggle: (state, action) => {
      state.toggleOpen = action.payload;
    },
  },
});

export const { menuToggle } = booleanSlice.actions;

export default booleanSlice.reducer;
