import React, { useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box } from "@mui/material";

export const Table = ({ columns, rows, className, getRowId }) => {
  const [filterModel, setFilterModel] = React.useState({
    items: [],
    quickFilterExcludeHiddenColumns: true,
    quickFilterValues: [""],
  });

  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    program: true,
    fullName: true,
    studentMobileNumber: true,
    studentEmailAddress: true,
    parentName: true,
    relationship: true,
    Syllabus: true,
    dob: false,
    schoolName: false,
    currentClass: false,
    country: false,
    state: false,
    isRegFeePaid: false,
    actions: true,
  });

  const [pageSize, setPageSize] = useState(10);

  return (
    <Box
      sx={{
        height: 500,
        width: "100%",
        maxWidth: "100%",
        overflowX: "auto",
        backdropFilter: "blur(5px)",
        boxShadow: "0px 0px 50px rgba(0, 0, 0, 0.1)",
        borderRadius: "10px",
        boxSizing: "border-box",
        backgroundColor: "#fff",
        border: "2px solid white",
        p: 2,
        "& .MuiDataGrid-columnHeaders": {
          fontSize: "1.6rem",
        },
        "& .MuiDataGrid-cell": {
          fontSize: "1.5rem",
        },
      }}
    >
      <DataGrid
        columns={columns}
        rows={rows}
        getRowId={(row) => row._id}
        disableColumnFilter
        disableDensitySelector
        slots={{ toolbar: GridToolbar }}
        filterModel={filterModel}
        onFilterModelChange={(newModel) => setFilterModel(newModel)}
        slotProps={{ toolbar: { showQuickFilter: true } }}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) =>
          setColumnVisibilityModel(newModel)
        }
        pagination
        paginationMode="client"
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20]}
      />
    </Box>
  );
};

export default Table;
