import React, { useRef } from "react";
import { Grid } from "@mui/material";
import classes from "./MathWithAI.module.css";

const MathWithAI = () => {
  const unlockAIRef = useRef(null);

  return (
    <>
      <Grid container spacing={1} ref={unlockAIRef} id="math_home">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <h1 className={classes.primary_heading}>
            Conquer Math with AI, Starting Today!
          </h1>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div className={classes.mathWithAIImage}></div>
        </Grid>
      </Grid>
    </>
  );
};

export default MathWithAI;
