import { Field } from "formik";
import React, { useState } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  Grid,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Drawer,
} from "@mui/material";

import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { GoPerson } from "react-icons/go";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import { MdClose } from "react-icons/md";
import {
  loginUserAsync,
  setModelOpen,
  setPopupOpen,
} from "../../Redux/loginSlice";
import { setForgotPasswordModal } from "../../Redux/forgotPasswordSlice";
import ForgotPassword from "./ForgotPassword";

const SignupSchema = Yup.object().shape({
  studentEmailAddress: Yup.string().required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const LoginForm = () => {
  const popupOpen = useSelector((state) => state.loginReducer.modelOpen);
  const resetModal = useSelector(
    (state) => state.forgotPasswordReducer.forgotPasswordModal
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleClose = () => {
    dispatch(setPopupOpen(false));
  };

  const handleSignupPage = (e) => {
    e.preventDefault();
    // dispatch(setModelOpen(true));
    navigate("/register");
    dispatch(setPopupOpen(false));
  };

  const handleorgotPassword = () => {
    dispatch(setForgotPasswordModal(!resetModal));
  };

  const handleLogin = async (values) => {
    try {
      await dispatch(
        loginUserAsync(values.studentEmailAddress, values.password, navigate)
      );
    } catch (error) {
      console.error("Login failed", error);
      alert("Invalid Email or Password");
    }
  };

  return (
    <>
      <Drawer
        anchor={"right"}
        open={popupOpen} // Make sure popupOpen is correctly set
        PaperProps={{
          style: {
            width: "100%",
            maxWidth: "500px",
            height: "100vh",
            transition: "width 2s",
          },
        }}
      >
        <Grid
          container
          spacing={0}
          sx={{
            backgroundColor: "#4a5e75",
          }}
        >
          <Grid item xs={12} md={3} lg={3}>
            <Button onClick={handleClose}>
              <MdClose
                style={{
                  color: "#fff",
                  fontSize: "2rem",
                  border: "2px solid #fff",
                  borderRadius: "50%",
                  marginTop: "1.5rem",
                }}
              />
            </Button>
          </Grid>
        </Grid>
        <div className="login_form">
          <Formik
            initialValues={{
              studentEmailAddress: "",
              password: "",
            }}
            onSubmit={handleLogin}
            validationSchema={SignupSchema}
          >
            {({ handleSubmit, isValid, values, dirty }) => (
              <Form onSubmit={handleSubmit}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6} lg={3}>
                    <div className="welcomelog">
                      <h1>Login</h1>
                    </div>
                  </Grid>
                </Grid>
                /*{" "}
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6} lg={12} sx={{ mb: 1 }}>
                    {/* <div className="welcomelog">
                      <h2>Hello! Let's get started</h2>
                    </div> */}
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6} lg={12} sx={{ mb: 5, mt: -3 }}>
                    <Field
                      id="outlined-basic"
                      fullWidth
                      as={TextField}
                      variant="outlined"
                      name="studentEmailAddress"
                      placeholder="Email Address*"
                      InputProps={{
                        style: {
                          color: "white",
                          // borderBottom: "1px solid #3e98f0",
                          border: "2px solid #ffffff66",
                          backgroundColor: "#0f1825",
                          borderRadius: "10px",
                          fontSize: "1.5rem",
                          fontWeight: "700",
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={togglePasswordVisibility}
                              edge="end"
                            >
                              <GoPerson
                                style={{
                                  color: "#94d9ff",
                                  fontSize: "2rem",
                                  fontWeight: "700",
                                }}
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <ErrorMessage
                      name="studentEmailAddress"
                      component="div"
                      className="error"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6} lg={12} sx={{ mb: 1 }}>
                    <Field
                      id="outlined-basic"
                      fullWidth
                      variant="outlined"
                      name="password"
                      as={TextField}
                      type={showPassword ? "text" : "password"}
                      placeholder="Password*"
                      InputProps={{
                        style: {
                          color: "white",
                          // borderBottom: "1px solid #3e98f0",
                          border: "2px solid #ffffff66",
                          backgroundColor: "#0f1825",
                          borderRadius: "10px",
                          fontSize: "1.5rem",
                          fontWeight: "700",
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={togglePasswordVisibility}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOutlinedIcon
                                  style={{
                                    color: "#94d9ff",
                                    fontSize: "2rem",
                                    fontWeight: "700",
                                  }}
                                />
                              ) : (
                                <VisibilityOffOutlinedIcon
                                  style={{
                                    color: "#94d9ff",
                                    fontSize: "2rem",
                                    fontWeight: "700",
                                  }}
                                />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="error"
                    />
                  </Grid>
                </Grid>
                <div className="forgotPassword" onClick={handleorgotPassword}>
                  <p>
                    <span
                      style={{
                        color: "#6ea3db",
                        fontWeight: "700",
                        letterSpacing: "1px",
                      }}
                    >
                      Forget Password?
                    </span>
                    <span style={{ color: "#f8f9fa", fontWeight: "700" }}>
                      Reset
                    </span>
                  </p>
                </div>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6} lg={12} sx={{ mb: 3 }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "2rem",
                      }}
                    >
                      <Button
                        variant="contained"
                        disabled={
                          !isValid
                          // || !dirty
                        }
                        onClick={(e) => handleSubmit()}
                        style={{
                          padding: ".7rem 5rem",
                          borderRadius: "2rem",
                          backgroundColor: "#fff",
                          boxShadow: "0 2.4rem 4rem black",
                        }}
                      >
                        <EastOutlinedIcon
                          style={{
                            color: "#0081d5",
                            fontSize: "3rem",
                            fontWeight: "700",
                          }}
                        />
                      </Button>
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12} lg={12} sx={{ mb: 3 }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "1.3rem",
                        color: "#fff",
                        fontWeight: "bold",
                      }}
                    >
                      Don't have an account? Create one! &nbsp;
                      <Link
                        style={{
                          // textDecoration: "none",
                          color: "blue",
                          fontSize: "1.5rem",
                          // textShadow: "0 2.4rem 4rem #fff",
                        }}
                        onClick={handleSignupPage}
                      >
                        Sign Up
                      </Link>
                    </div>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </div>
      </Drawer>
      {resetModal && <ForgotPassword />}
    </>
  );
};

export default LoginForm;
