import React from "react";
import classes from "./AllAtItaBest.module.css";
import DiamondIcon from "@mui/icons-material/Diamond";
import Button from "../../common/Button";

const AllAtItsBest = () => {
  return (
    <>
      <div className={classes.section_revolution}>
        <h1 className={classes.primary_heading}>AI at Its Best</h1>
        <p className={classes.primary_contnet}>
          Our AI platform provides an unmatched personalized learning experience
          that boosts NEET, JEE & CET scores.
        </p>
      </div>
      <div className={classes.dianmond_btn_container}>
        <Button className={classes.dianmond_btn}>
          <DiamondIcon style={{ fontSize: "4rem", color: "#3498db" }} />
          Personalized
        </Button>
        <Button className={classes.dianmond_btn}>
          <DiamondIcon style={{ fontSize: "4rem", color: "#3498db" }} />
          Periodic Evaluation
        </Button>
        <Button className={classes.dianmond_btn}>
          <DiamondIcon style={{ fontSize: "4rem", color: "#3498db" }} />
          Cost-Effective
        </Button>
        <Button className={classes.dianmond_btn}>
          <DiamondIcon style={{ fontSize: "4rem", color: "#3498db" }} />
          Flexibility
        </Button>
      </div>
      <div className={classes.dianmond_btn_container2}>
        <Button className={classes.dianmond_btn}>
          <DiamondIcon style={{ fontSize: "4rem", color: "#3498db" }} />
          Feedback
        </Button>
        <Button className={classes.dianmond_btn}>
          <DiamondIcon style={{ fontSize: "4rem", color: "#3498db" }} />
          Support
        </Button>

        <Button className={classes.dianmond_btn}>
          <DiamondIcon style={{ fontSize: "4rem", color: "#3498db" }} />
          Interactivity
        </Button>

        <Button className={classes.dianmond_btn}>
          <DiamondIcon style={{ fontSize: "4rem", color: "#3498db" }} />
          Guidance
        </Button>
      </div>
    </>
  );
};

export default AllAtItsBest;
