import React from "react";
import Header from "../HomePage/Header";
import ContactFooter from "../../common/contactFooter/ContactFooter";
import ChatBot from "../HomePage/ChatBot";

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      {children}
      <ChatBot />
      <ContactFooter />
    </>
  );
};

export default Layout;
