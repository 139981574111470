import React, { useEffect } from "react";
import OurLeaderShip from "./OurLeaderShip";
import Directors from "./Directors";

const LeaderShip = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <OurLeaderShip />
      <Directors />
    </>
  );
};

export default LeaderShip;
