import React from "react";
import classes from "./ServicesOffered.module.css";
import { Grid } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SettingsIcon from "@mui/icons-material/Settings";
import EmailSharpIcon from "@mui/icons-material/EmailSharp";
import TipsAndUpdatesSharpIcon from "@mui/icons-material/TipsAndUpdatesSharp";
import NearMeSharpIcon from "@mui/icons-material/NearMeSharp";
import RocketLaunchSharpIcon from "@mui/icons-material/RocketLaunchSharp";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Link } from "react-router-dom";

const ServicesOffered = () => {
  return (
    <>
      <Grid container spacing={8}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className={classes.services_offered_container}>
            <div className={classes.services_offered}>
              <h1>Services Offered</h1>
              <ul>
                <li>
                  <ArrowForwardIcon
                    style={{ color: "#0e58ae", fontSize: "2rem" }}
                  />
                  Learn At Your Pace
                </li>
                <li>
                  <ArrowForwardIcon
                    style={{ color: "#0e58ae", fontSize: "2rem" }}
                  />
                  Instant Feedback And Support
                </li>
                <li>
                  <ArrowForwardIcon
                    style={{ color: "#0e58ae", fontSize: "2rem" }}
                  />
                  Cost-Effective Pricing
                </li>
              </ul>
              <Link to="programs" className={classes.explore_btn}>
                Explore Services
              </Link>
            </div>

            <div className={classes.service_options}>
              <div className={classes.options}>
                <div className={classes.first_option} id={classes.option}>
                  <SettingsIcon
                    style={{ color: "#448C74", fontSize: "5rem" }}
                  />
                  <h1>
                    Extensive <br /> Practice Materials
                  </h1>
                  <p>
                    Access a vast array of practice materials to hone your
                    skills.
                  </p>
                </div>
                <div className={classes.second_option} id={classes.option}>
                  <EmailSharpIcon
                    style={{ color: "#EDCB50", fontSize: "5rem" }}
                  />
                  <h1>
                    Economic <br />
                    Accessibility
                  </h1>
                  <p>Affordable education for all.</p>
                </div>
              </div>
              <div className={classes.options} id={classes.option}>
                <div className={classes.third_option}>
                  <TipsAndUpdatesSharpIcon
                    style={{ color: "#3F7FCA", fontSize: "5rem" }}
                  />
                  <h1>24/7 Accessibility</h1>
                  <p>
                    Round-the-clock help boosts your learning anytime, anywhere.
                  </p>
                </div>
                <div className={classes.fourth_option} id={classes.option}>
                  <NearMeSharpIcon
                    style={{ color: "#ED5050", fontSize: "5rem" }}
                  />
                  <h1>
                    FLEXIBILITY
                    <br /> & CONVENIENCE
                  </h1>
                  <p>Learn anytime, anywhere, any device</p>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      <h1 className={classes.benfits_heding}>Key Benefits</h1>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
          <div className={classes.benfits}>
            <SettingsIcon style={{ color: "#0e58aeff", fontSize: "5rem" }} />
            <h1>Flexible Learning</h1>
            <p>Highly personalized for every learner.</p>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
          <div className={classes.benfits}>
            <TipsAndUpdatesSharpIcon
              style={{ color: "#0e58aeff", fontSize: "5rem" }}
            />
            <h1>Progress Updates</h1>
            <p>Stay informed with regular updates.</p>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
          <div className={classes.benfits}>
            <CheckCircleIcon style={{ color: "#0e58aeff", fontSize: "5rem" }} />
            <h1>Instant Support</h1>
            <p>Get feedback in real-time.</p>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
          <div className={classes.benfits}>
            <RocketLaunchSharpIcon
              style={{ color: "#0e58aeff", fontSize: "5rem" }}
            />
            <h1>Scholarships Available</h1>
            <p>Access education irrespective of background.</p>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default ServicesOffered;
