import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import {
  resetPassword,
  setForgotPasswordModal,
} from "../../Redux/forgotPasswordSlice";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { Typography } from "@mui/material";

const validationSchema = Yup.object().shape({
  studentEmailAddress: Yup.string()
    .email("Invalid email address")
    .required("Required"),
});

const ForgotPassword = () => {
  const resetModal = useSelector(
    (state) => state.forgotPasswordReducer.forgotPasswordModal
  );
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setForgotPasswordModal(false));
  };

  const handleSubmit = (values, { setSubmitting }) => {
    dispatch(resetPassword(values.studentEmailAddress));
    handleClose();
    setSubmitting(false);
  };

  return (
    <Dialog
      open={resetModal}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": {
          width: "100%",
          maxWidth: "600px",
          minHeight: "200px",
          maxHeight: "70vh",
        },
      }}
    >
      <DialogTitle variant="h4">Forgot Password</DialogTitle>
      <Formik
        initialValues={{ studentEmailAddress: "" }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <DialogContent>
              <Typography variant="h5">
                Please enter your email address, which you have registred with
              </Typography>
              <Field
                as={TextField}
                required
                fullWidth
                variant="filled"
                id="studentEmailAddress"
                name="studentEmailAddress"
                // label="Email Address"
                type="email"
                placeholder="Email Address*"
                inputProps={{ style: { fontSize: "1.5rem" } }}
              />
              <ErrorMessage
                name="studentEmailAddress"
                component="div"
                className="error"
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                variant="outlined"
                size="large"
                style={{
                  background: "#e9ecef",
                  border: "none",
                  fontSize: "1.5rem",
                  color: "#495057",
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="outlined"
                size="large"
                disabled={isSubmitting}
                style={{
                  background: "#1976D2",
                  color: "#fff",
                  fontSize: "1.5rem",
                }}
              >
                Submit
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default ForgotPassword;
