import React from "react";
import { Grid } from "@mui/material";
import classes from "./virutualLabs.module.css";
import iconImages from "../../../components/assets/images/icon-26.png";
import iconImages1 from "../../../components/assets/images/icon-27.png";
import iconImages2 from "../../../components/assets/images/icon-28.png";

const VirutualLabs = () => {
  return (
    <>
      <div className={classes.virutual_labs}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <div className={classes.virutal_lab_contnet}>
              <img src={iconImages} alt="icons" />
              <h2>Virtual Labs</h2>
              <p>
                Dive into hands-on experiments that bring science theories to
                life.
              </p>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <div className={classes.virutal_lab_contnet}>
              <img src={iconImages1} alt="icons" />
              <h2>Customized Learning</h2>
              <p>
                Experience learning tailored by AI to match your learning style
                and pace.
              </p>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <div className={classes.virutal_lab_contnet}>
              <img src={iconImages2} alt="icons" />
              <h2>Smart Feedback</h2>
              <p>
                Receive instant feedback and guidance to enhance your scientific
                knowledge.
              </p>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default VirutualLabs;
