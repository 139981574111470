import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  Grid,
  Radio,
  Typography,
} from "@mui/material";
import Button from "../../../common/Button";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ErrorMessage, useFormikContext } from "formik";

const WelcomePageEdit = ({
  formStep,
  validateForm,
  handleNext,
  setFormStep,
  setFieldValue,
  values,
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleExamChange = (event) => {
    setFieldValue("program", event.target.value);
  };

  const handleSubjectChange = (event) => {
    const { value } = event.target;
    const currentSubjects = values.program;

    if (Array.isArray(currentSubjects)) {
      if (currentSubjects.includes(value)) {
        setFieldValue(
          "program",
          currentSubjects.filter((subject) => subject !== value)
        );
      } else {
        setFieldValue("program", [...currentSubjects, value]);
      }
    } else {
      setFieldValue("program", [value]);
    }
  };

  const handleSubmit = async () => {
    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      handleNext();
    } else {
      alert("At least one program or subject must be selected");
    }
  };

  return (
    <>
      {formStep === 0 && (
        <>
          <div className="welcome-page-container">
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12} xl={12}>
                {/* <div className="content-box">
                  <h1 className="title">
                    Welcome to Certainty Rank Academy Registration
                  </h1>
                  <p className="subtitle">
                    We're delighted to welcome you to Our Online Recorded
                    Classes. The journey toward your higher education begins
                    here, and we're excited to be a part of this important
                    chapter in your life
                  </p>
                  <p className="info">
                    Please complete the registration form below to ensure a
                    seamless enrollment process.
                  </p>
                </div> */}
                <Accordion
                  expanded={expanded === "panel1"}
                  onChange={handleAccordionChange("panel1")}
                  sx={{ mt: 2, width: "100%" }}
                >
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon style={{ fontSize: "3.5rem" }} />
                    }
                  >
                    <Typography variant="h4" sx={{ fontSize: "1.8rem" }}>
                      Competitive Exams - JEE | NEET | KCET
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div style={{ width: "100%" }}>
                      <FormControlLabel
                        control={
                          <Radio
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: 18,
                              },
                            }}
                          />
                        }
                        label="JEE"
                        value="JEE"
                        checked={values.program === "JEE"}
                        onChange={handleExamChange}
                      />
                      <FormControlLabel
                        control={
                          <Radio
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: 18,
                              },
                            }}
                          />
                        }
                        label="NEET"
                        value="NEET"
                        checked={values.program === "NEET"}
                        onChange={handleExamChange}
                      />
                      <FormControlLabel
                        control={
                          <Radio
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: 18,
                              },
                            }}
                          />
                        }
                        label="KCET"
                        value="KCET"
                        checked={values.program === "KCET"}
                        onChange={handleExamChange}
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  expanded={expanded === "panel2"}
                  onChange={handleAccordionChange("panel2")}
                  sx={{ mt: 2, width: "100%" }}
                >
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon style={{ fontSize: "3.5rem" }} />
                    }
                  >
                    <Typography variant="h4" sx={{ fontSize: "1.8rem" }}>
                      Subjects - English | Science | Mathematics
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div style={{ width: "100%" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }}
                          />
                        }
                        label="English"
                        value="English"
                        checked={
                          Array.isArray(values.program) &&
                          values.program.includes("English")
                        }
                        onChange={handleSubjectChange}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }}
                          />
                        }
                        label="Science"
                        value="Science"
                        checked={
                          Array.isArray(values.program) &&
                          values.program.includes("Science")
                        }
                        onChange={handleSubjectChange}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }}
                          />
                        }
                        label="Mathematics"
                        value="Mathematics"
                        checked={
                          Array.isArray(values.program) &&
                          values.program.includes("Mathematics")
                        }
                        onChange={handleSubjectChange}
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>
                {/* <ErrorMessage
                  name="program"
                  component="div"
                  className="error"
                /> */}
              </Grid>
              <Grid item xs={12} md={12} lg={12} xl={12}>
                <div className="pre_nxt_btn_box">
                  <Button
                    type="button"
                    className="next_btn"
                    onClick={handleSubmit}
                  >
                    Next
                  </Button>
                </div>
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </>
  );
};

export default WelcomePageEdit;
