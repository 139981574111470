import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  directorsModalOpen: false,
  tushaarModalOpen: false,
  narayananModalOpen: false,
  ginoModalOpen: false,
  laksmiModal: false,
  kcetToggle: false,
};

export const excutiveDirectorsSlice = createSlice({
  name: "directors",
  initialState,
  reducers: {
    setDirectorsModalOpen: (state, action) => {
      state.directorsModalOpen = action.payload;
    },
    setTushaarModalOpen: (state, action) => {
      state.tushaarModalOpen = action.payload;
    },

    setNarayananModalOpen: (state, action) => {
      state.narayananModalOpen = action.payload;
    },
    setGinoModalOpen: (state, action) => {
      state.ginoModalOpen = action.payload;
    },
    setLaksmiModalOpen: (state, action) => {
      state.laksmiModal = action.payload;
    },

    setKcetToggle: (state, action) => {
      state.kcetToggle = action.payload;
    },
  },
});

export const {
  setDirectorsModalOpen,
  setTushaarModalOpen,
  setNarayananModalOpen,
  setGinoModalOpen,
  setLaksmiModalOpen,
} = excutiveDirectorsSlice.actions;

export default excutiveDirectorsSlice.reducer;
