import React, { useEffect, useState } from "react";
import user from "../../../assets/images/user.68cb0bff29e4bb329a2d (1).png";
import { Grid } from "@mui/material";
import Button from "../../../common/Button";
import { useDispatch, useSelector } from "react-redux";
import { uploadPhoto } from "../../../Redux/uploadPhotoSlice";
import { downloadPhoto } from "../../../Redux/downloadPhotoSlice";

const UploadPictureEdit = ({
  formStep,
  handlePrevious,
  handleFormSubmit,
  setFieldValue,
}) => {
  const dispatch = useDispatch();
  const photo = useSelector((state) => state.downloadPhoto.photo);
  const status = useSelector((state) => state.downloadPhoto.status);
  const error = useSelector((state) => state.downloadPhoto.error);

  const selectedRow = useSelector(
    (state) => state.studentReducer.selectedStudentsRowData
  );
  const photoId = selectedRow._id;

  const [image, setImage] = useState(null);

  const handleChange = (e) => {
    const selectedFile = e.target.files[0];
    setFieldValue("profilePic", selectedFile);
    setImage(URL.createObjectURL(selectedFile));
    dispatch(uploadPhoto({ id: photoId, file: selectedFile }));
  };

  useEffect(() => {
    if (photoId) {
      dispatch(downloadPhoto(photoId));
    }
  }, [dispatch, photoId]);

  useEffect(() => {
    if (photo) {
      const imageUrl = URL.createObjectURL(photo);
      setImage(imageUrl);

      // Revoke the object URL after it has been set to the state
      return () => URL.revokeObjectURL(imageUrl);
    }
  }, [dispatch, photo]);

  return (
    <>
      {formStep === 3 && (
        <>
          <Grid
            container
            spacing={2}
            // sx={{ marginLeft: { xs: "2rem", sm: "2rem" } }}
          >
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <div className="user_image_box">
                <img src={image || user} alt="Profile" className="user_image" />
                <h1>Select a file</h1>
                <input
                  type="file"
                  name="profilePic"
                  id="profilePic"
                  accept="image/png, image/gif, image/jpeg"
                  placeholder="choose"
                  className="hidden"
                  onChange={handleChange}
                />
                <label htmlFor="profilePic" className="upload_label">
                  Click to upload picture
                </label>
                {status === "loading" && <p>Loading...</p>}
                {/* {error && <p style={{ color: "red" }}>{error}</p>} */}
              </div>
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <div className="pre_nxt_btn_box">
                <Button className="previous_btn" onClick={handlePrevious}>
                  Previous
                </Button>
                <Button className="next_btn" onClick={handleFormSubmit}>
                  Submit
                </Button>
              </div>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default UploadPictureEdit;
