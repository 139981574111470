import React, { useRef } from "react";
import { Grid } from "@mui/material";
import classes from "./UnlockAI.module.css";

const UnlockAI = () => {
  const unlockAIRef = useRef(null);

  return (
    <>
      <Grid container spacing={1} ref={unlockAIRef}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <h1 className={classes.primary_heading}>
            Unlock Your Potential with Engaging AI English Learning
          </h1>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {/* <img
            src="https://res.cloudinary.com/dbyioi2qq/image/upload/v1713888584/njyhc08kmrwxwxbhdc4f.jpg"
            alt="cloudinary"
            className={classes.cloudinary}
          /> */}
          <div className={classes.cloudinary}></div>
        </Grid>
      </Grid>
    </>
  );
};

export default UnlockAI;
