import React, { useState } from "react";
import { Dialog, Grid } from "@mui/material";
import chatbot from "../../assets/images/pulsera_76_11zon.png";
import { Link } from "react-router-dom";

const ChatBot = () => {
  const [chatbotOpen, setChatbotOpen] = useState(false);

  const chatbotHandler = () => {
    setChatbotOpen(!chatbotOpen);
  };

  const handleClose = () => {
    setChatbotOpen(!chatbotOpen);
  };

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12} md={12} lg={12}>
          <Link onClick={chatbotHandler}>
            <img src={chatbot} alt="chatbot" className="chatbot" />
          </Link>
          <span className="chatbot-text">CHAT NOW</span>
        </Grid>
      </Grid>
      <Dialog
        open={chatbotOpen}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "75vw",
            height: "84.3vh",
            maxWidth: "none",
            maxHeight: "none",
          },
        }}
      >
        <iframe
          src="https://dev.aksharatheschool.com/cra"
          frameborder={0}
          style={{ height: "100%", width: "100%" }}
        ></iframe>

        <button onClick={handleClose} className="close_btn">
          Close
        </button>
      </Dialog>
    </>
  );
};

export default ChatBot;
