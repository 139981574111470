import React  from "react";
import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  Modal,
  OutlinedInput,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import CloseIcon from "@mui/icons-material/Close";
import PaymentModel from "./PaymenyModal";

import { Link } from "react-router-dom";
import { updateUserAsync } from "../../Redux/userSlice";
import { setSuccess } from "../../Redux/ContactFormSlice";
import { setPaymentModelOpen, setRegisterData } from "../../Redux/paymenyModelSlice";
import { setModelOpen, setPopupOpen } from "../../Redux/loginSlice";

const SignupSchema = Yup.object().shape({
  studentName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Student Name is required"),
  // parentName: Yup.string()
  //   .min(2, "Too Short!")
  //   .max(50, "Too Long!")
  //   .required("Parent Name is required"),
  // sponserName: Yup.string()
  //   .min(2, "Too Short!")
  //   .max(50, "Too Long!")
  //   .required("Name is required"),
  studentEmailAddress: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  // parentEmailId: Yup.string()
  //   .email("Invalid email address")
  //   .required(" Parent Email is required"),
  studentMobileNumber: Yup.string()
    .matches(/^[0-9]+$/, "Mobile number should contain only numbers")
    .length(10, "Mobile number should be exactly 10 digits")
    .required("Student Mobile Number is required"),
  parentMobileNumber: Yup.string()
    .matches(/^[0-9]+$/, "Mobile number should contain only numbers")
    .length(10, "Mobile number should be exactly 10 digits")
    .required("Parent Mobile Number is required")
    .test(
      "mobile-not-same",
      "Parent mobile number should not be the same as student mobile number",
      function (value) {
        return value !== this.parent.studentMobileNumber;
      }
    ),
  currentClass: Yup.string().required("Current Class is required"),
  schoolName: Yup.string().required("School Name is required"),
  Syllabus: Yup.string().required("Syllabus is required"),
  // country: Yup.string().required("Country is required"),
  state: Yup.string().required("State is required"),
  program: Yup.string().required("Program is required"),
  gender: Yup.string().required("Gender is required"),
  dateOfBirth: Yup.string().required("Date Of Birth is required"),
  // relationShip: Yup.string().required("RelationShip is required"),

  // sponserMobileNumber: Yup.string()
  //   .matches(/^[0-9]+$/, "Mobile number should contain only numbers")
  //   .length(10, "Mobile number should be exactly 10 digits")
  //   .required("Mobile Number is required"),
  password: Yup.string()
    .required("Password is required")
    .matches(
      /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{6,}$/,
      "Password must be 6 characters or more and include at least one digit, one uppercase letter, and one lowercase letter."
    ),

  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),

  // city: Yup.string().required("City is required"),

  // currentClass: Yup.string().required("Current Class is required"),
  // program: Yup.string().required("program is required"),
});

const statesInIndia = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli",
  "Daman and Diu",
  "Lakshadweep",
  "Delhi",
  "Puducherry",
];

const SignupFormModel = () => {
  const user = useSelector((state) => state.users.loading);
  const modelOpen = useSelector(
    (state) => state.loginReducer.modelRegisterOpen
  );

  const paymentModal = useSelector(
    (state) => state.paymentModelSlice.paymentModelOpen
  );

  const dispatch = useDispatch();

  const handleFormSubmit = async (values, { resetForm }) => {
    console.log("Register Data", values);
    dispatch(setRegisterData(values));
    try {
      const { confirmPassword, ...postData } = values;
      const response = await dispatch(updateUserAsync(postData));
      dispatch(setPaymentModelOpen(false));

      if (response.payload && response.payload.payload === true) {
        dispatch(setSuccess(true));
      } else {
        dispatch(setSuccess(false));
      }
    } catch (error) {
      dispatch(setSuccess(false));
    }
    // dispatch(setPaymentModelOpen(!paymentModal));
    // dispatch(setModelOpen(!modelOpen));
  };

  const closeHandler = () => {
    dispatch(setModelOpen(!modelOpen));
  };

  const handleLoginPage = (e) => {
    e.preventDefault();
    dispatch(setModelOpen(!modelOpen));
    dispatch(setPopupOpen(true));
  };
  return (
    <>
      <Modal open={modelOpen} onClose={null} disableBackdropClick={true}>
        <div className="model">
          <Formik
            initialValues={{
              studentName: "",
              studentMobileNumber: "",
              parentName: "",
              parentMobileNumber: "",
              studentEmailAddress: "",
              currentClass: "",
              schoolName: "",
              dateOfBirth: "",
              gender: "",
              Syllabus: "",
              country: "INDIA",
              state: "",
              parentEmailAddress: "",
              relationship: "",
              program: "",
              registrationfee: 200,
              password: "",
              confirmPassword: "",
            }}
            validationSchema={SignupSchema}
            onSubmit={(values, { resetForm }) =>
              handleFormSubmit(values, { resetForm })
            }
          >
            {({ handleSubmit, isValid, values, dirty, resetForm }) => (
              <Form onSubmit={handleSubmit} className="signUpForm_test">
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} lg={12}>
                    <CloseIcon
                      style={{
                        color: "#000",
                        fontSize: "4rem",
                        cursor: "pointer",
                        //   position: "absolute",
                        //   top: "5px",
                        //   right: "calc(120% + 10px)",
                        //   zIndex: 1,
                        float: "right",
                        marginTop: "-2rem",
                      }}
                      onClick={closeHandler}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  // sx={{ mt: -6.5 }}
                  sx={{ mb: 1 }}
                >
                  <Grid item xs={12} md={12} lg={12}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "15px",
                        overflowY: "auto",
                        maxHeight: "90vh",
                      }}
                    >
                      <div className="register">
                        <h2>Student Registration</h2>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                {/* Student Details */}
                <Paper
                  style={{
                    boxShadow: "0 0rem 1rem rgba(0,0,0,0.1)",
                    padding: "1rem 2rem",
                    marginBottom: "1rem",
                  }}
                >
                  <Grid container spacing={2} sx={{ mb: 1 }}>
                    <Grid item xs={12} md={12} lg={12}>
                      <div>
                        <div>
                          <h2 style={{ fontSize: "2rem" }}>
                            Student Information
                          </h2>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={6}>
                      <h3 className="form_label">Student Name</h3>
                      <Field
                        as={TextField}
                        type="text"
                        placeholder="Student Name"
                        name="studentName"
                        fullWidth
                        inputProps={{ style: { fontSize: "1.5rem" } }}
                      />
                      <ErrorMessage
                        name="studentName"
                        component="div"
                        className="error"
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <h3 className="form_label">Email Address</h3>
                      <Field
                        as={TextField}
                        type="text"
                        placeholder="Email Address"
                        name="studentEmailAddress"
                        fullWidth
                        inputProps={{ style: { fontSize: "1.5rem" } }}
                      />
                      <ErrorMessage
                        name="studentEmailAddress"
                        component="div"
                        className="error"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ mt: -0.5 }}>
                    <Grid item xs={12} md={6} lg={6}>
                      <h3 className="form_label">Current Class</h3>
                      <FormControl fullWidth>
                        <Field
                          as={Select}
                          labelId="current-class-label"
                          id="current-class"
                          label="Current Class"
                          name="currentClass"
                          fullWidth
                          inputProps={{ style: { fontSize: "1.5rem" } }}
                          input={<OutlinedInput />}
                          placeholder="Current Class"
                          sx={{
                            minWidth: 120,
                            backgroundColor: "white",
                            borderRadius: "7px",
                            // border: "1px solid #8c0005",
                            // boxShadow: "rgba(0, 0, 0, 0.15) 0 0 5px",
                            color: "#b4b4b4",
                            fontSize: "1.5rem",
                          }}
                          displayEmpty
                        >
                          <MenuItem
                            value=""
                            disabled
                            style={{ fontSize: "1.5rem" }}
                          >
                            Current Class
                          </MenuItem>
                          <MenuItem value={"12"}>PUC 2 (12)</MenuItem>
                          <MenuItem value={"11"}>PUC 1 (11) </MenuItem>
                          <MenuItem value={"10"}>10th</MenuItem>
                          <MenuItem value={"9"}>9th</MenuItem>
                          <MenuItem value={"8"}>8th</MenuItem>
                          <MenuItem value={"7"}>7th</MenuItem>
                          {/* <MenuItem value={"6th"}>6th</MenuItem>
                          <MenuItem value={"5th"}>5th</MenuItem> */}
                        </Field>
                        <ErrorMessage
                          name="currentClass"
                          component="div"
                          className="error"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <h3 className="form_label">School Name</h3>
                      <Field
                        as={TextField}
                        type="text"
                        placeholder="School Name"
                        name="schoolName"
                        fullWidth
                        inputProps={{ style: { fontSize: "1.5rem" } }}
                      />
                      <ErrorMessage
                        name="schoolName"
                        component="div"
                        className="error"
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={6}>
                      <h3 className="form_label">Date Of Birth</h3>
                      <Field
                        as={TextField}
                        type="date"
                        placeholder="Date Of Birth"
                        name="dateOfBirth"
                        fullWidth
                        inputProps={{ style: { fontSize: "1.5rem" } }}
                      />
                      <ErrorMessage
                        name="dateOfBirth"
                        component="div"
                        className="error"
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <h3 className="form_label">Gender</h3>
                      <FormControl fullWidth>
                        <Field
                          as={Select}
                          labelId="gender-label"
                          id="gender"
                          label="gender"
                          name="gender"
                          fullWidth
                          inputProps={{ style: { fontSize: "1.5rem" } }}
                          input={<OutlinedInput />}
                          placeholder="Gender"
                          sx={{
                            minWidth: 120,
                            backgroundColor: "white",
                            borderRadius: "7px",
                            // border: "1px solid #8c0005",
                            // boxShadow: "rgba(0, 0, 0, 0.15) 0 0 5px",
                            color: "#b4b4b4",
                            fontSize: "1.5rem",
                          }}
                          displayEmpty
                        >
                          <MenuItem value="" disabled>
                            Gender
                          </MenuItem>
                          <MenuItem value={"Male"}>Male</MenuItem>
                          <MenuItem value={"FeMale"}>FeMale</MenuItem>
                          <MenuItem value={"Other"}>Other</MenuItem>
                        </Field>
                        <ErrorMessage
                          name="gender"
                          component="div"
                          className="error"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mt: -0.5 }}>
                    <Grid item xs={12} md={6} lg={6}>
                      <h3 className="form_label">Student Mobile Number</h3>
                      <Field
                        as={TextField}
                        type="text"
                        placeholder="Mobile Number"
                        name="studentMobileNumber"
                        fullWidth
                        inputProps={{ style: { fontSize: "1.5rem" } }}
                      />
                      <ErrorMessage
                        name="studentMobileNumber"
                        component="div"
                        className="error"
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <h3 className="form_label">Syllabus</h3>
                      <FormControl fullWidth>
                        <Field
                          as={Select}
                          labelId="syllabus-label"
                          id="syllabus"
                          label="Syllabus"
                          name="Syllabus"
                          fullWidth
                          inputProps={{ style: { fontSize: "1.5rem" } }}
                          input={<OutlinedInput />}
                          placeholder="Syllabus"
                          sx={{
                            minWidth: 120,
                            backgroundColor: "white",
                            borderRadius: "7px",
                            // border: "1px solid #8c0005",
                            // boxShadow: "rgba(0, 0, 0, 0.15) 0 0 5px",
                            color: "#b4b4b4",
                            fontSize: "1.5rem",
                          }}
                          displayEmpty
                        >
                          <MenuItem value="" disabled>
                            Syllabus
                          </MenuItem>
                          <MenuItem value={"CBSE"}>CBSE</MenuItem>
                          <MenuItem value={"ICSE"}>ICSE</MenuItem>
                          <MenuItem value={"State"}>State</MenuItem>
                          <MenuItem value={"Other"}>Other</MenuItem>
                        </Field>
                        <ErrorMessage
                          name="Syllabus"
                          component="div"
                          className="error"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ mt: -0.5 }}>
                    <Grid item xs={12} md={6} lg={6}>
                      <h3 className="form_label">Password</h3>
                      <Field
                        as={TextField}
                        type="password" // Use type="password" to obscure characters
                        placeholder="Password"
                        name="password"
                        fullWidth
                        inputProps={{ style: { fontSize: "1.5rem" } }}
                      />
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="error"
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <h3 className="form_label">Confirm Password</h3>
                      <Field
                        as={TextField}
                        type="password" // Use type="password" to obscure characters
                        placeholder="Confirm Password"
                        name="confirmPassword"
                        fullWidth
                        inputProps={{ style: { fontSize: "1.5rem" } }}
                      />
                      <ErrorMessage
                        name="confirmPassword"
                        component="div"
                        className="error"
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mt: -0.5, mb: 2 }}>
                    <Grid item xs={12} md={6} lg={6}>
                      <h3 className="form_label">Country</h3>
                      <Field
                        as={TextField}
                        type="text"
                        placeholder="Country"
                        name="country"
                        value={"INDIA"}
                        fullWidth
                        inputProps={{ style: { fontSize: "1.5rem" } }}
                        disabled
                      />
                      {/* <ErrorMessage
                      name="country"
                      component="div"
                      className="error"
                    /> */}
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <h3 className="form_label">State</h3>
                      <FormControl fullWidth>
                        <Field
                          as={Select}
                          labelId="state-label"
                          id="state"
                          label="state"
                          name="state"
                          fullWidth
                          inputProps={{ style: { fontSize: "1.5rem" } }}
                          input={<OutlinedInput />}
                          placeholder="State"
                          sx={{
                            minWidth: 120,
                            backgroundColor: "white",
                            borderRadius: "7px",
                            // border: "1px solid #8c0005",
                            // boxShadow: "rgba(0, 0, 0, 0.15) 0 0 5px",
                            color: "#b4b4b4",
                            fontSize: "1.5rem",
                          }}
                          displayEmpty
                        >
                          <MenuItem value="" disabled>
                            State
                          </MenuItem>
                          {statesInIndia.map((state, index) => (
                            <MenuItem key={index} value={state}>
                              {state}
                            </MenuItem>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="state"
                          component="div"
                          className="error"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Paper>
                <Paper
                  style={{
                    boxShadow: "0 0rem 1rem rgba(0,0,0,0.1)",
                    padding: "1rem 2rem",
                  }}
                >
                  <Grid container spacing={2} sx={{ mt: -1, mb: 2 }}>
                    <Grid item xs={12} md={12} lg={12}>
                      <div>
                        <div>
                          <h2 style={{ fontSize: "2rem" }}>
                            Parent Information
                          </h2>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={6}>
                      <h3 className="form_label">Parent Name</h3>
                      <Field
                        as={TextField}
                        type="text"
                        placeholder="Parent Name"
                        name="parentName"
                        fullWidth
                        inputProps={{ style: { fontSize: "1.5rem" } }}
                      />
                      <ErrorMessage
                        name="parentName"
                        component="div"
                        className="error"
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <h3 className="form_label">Parent Email ID</h3>
                      <Field
                        as={TextField}
                        type="text"
                        placeholder="Parent Email ID"
                        name="parentEmailAddress"
                        fullWidth
                        inputProps={{ style: { fontSize: "1.5rem" } }}
                      />
                      <ErrorMessage
                        name="parentEmailAddress"
                        component="div"
                        className="error"
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mt: -0.5, mb: 2 }}>
                    <Grid item xs={12} md={6} lg={6}>
                      <h3 className="form_label">Parent Mobile Number</h3>
                      <Field
                        as={TextField}
                        type="text"
                        placeholder="Parent Mobile Number"
                        name="parentMobileNumber"
                        fullWidth
                        inputProps={{ style: { fontSize: "1.5rem" } }}
                      />
                      <ErrorMessage
                        name="parentMobileNumber"
                        component="div"
                        className="error"
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <h3 className="form_label">Relationship</h3>
                      <FormControl fullWidth>
                        <Field
                          as={Select}
                          labelId="relationship-label"
                          id="relationship"
                          label="Relationship"
                          name="relationship"
                          fullWidth
                          inputProps={{ style: { fontSize: "1.5rem" } }}
                          input={<OutlinedInput />}
                          placeholder="Relationship"
                          sx={{
                            minWidth: 120,
                            backgroundColor: "white",
                            borderRadius: "7px",
                            color: "#b4b4b4",
                            fontSize: "1.5rem",
                          }}
                          displayEmpty
                        >
                          <MenuItem value="" disabled>
                            Relationship
                          </MenuItem>
                          <MenuItem value={"Father"}>Father</MenuItem>
                          <MenuItem value={"Mother"}>Mother</MenuItem>
                          <MenuItem value={"Guardian"}>Guardian</MenuItem>
                        </Field>
                        <ErrorMessage
                          name="relationship"
                          component="div"
                          className="error"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Paper>
                <Paper
                  style={{
                    boxShadow: "0 0rem 1rem rgba(0,0,0,0.1)",
                    padding: "1rem 2rem",
                    marginBottom: "1rem",
                    marginTop: "1rem",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={6}>
                      <h3 className="form_label">Program opted</h3>
                      <FormControl fullWidth>
                        <Field
                          as={Select}
                          labelId="program opted-label"
                          id="program-opted"
                          label="Program Opted"
                          name="program"
                          fullWidth
                          inputProps={{ style: { fontSize: "1.5rem" } }}
                          input={<OutlinedInput />}
                          placeholder="Program opted"
                          sx={{
                            minWidth: "100%",
                            backgroundColor: "white",
                            borderRadius: "7px",
                            // border: "1px solid #8c0005",
                            // boxShadow: "rgba(0, 0, 0, 0.15) 0 0 5px",
                            color: "#b4b4b4",
                            fontSize: "1.5rem",
                          }}
                          displayEmpty
                        >
                          <MenuItem value="" disabled>
                            Program opted
                          </MenuItem>
                          <MenuItem value={"NEET"}>NEET</MenuItem>
                          <MenuItem value={"JEE"}>JEE</MenuItem>
                          <MenuItem value={"KCET"}>KCET</MenuItem>
                          {/* <MenuItem value={"10"}>10th</MenuItem>
                          <MenuItem value={"9"}>9th</MenuItem>
                          <MenuItem value={"8"}>8th</MenuItem> */}
                          {/* <MenuItem value={"7th"}>7th</MenuItem>
                          <MenuItem value={"6th"}>6th</MenuItem>
                          <MenuItem value={"5th"}>5th</MenuItem> */}
                        </Field>
                        <ErrorMessage
                          name="program"
                          component="div"
                          className="error"
                        />
                      </FormControl>
                    </Grid>
                    {/* <Grid item xs={12} md={6} lg={6}>
                      <h3 className="form_label">Registration Fee</h3>
                      <Field
                        as={TextField}
                        type="text"
                        placeholder="Registration Fee"
                        name="registrationfee"
                        disabled
                        value={200}
                        fullWidth
                        inputProps={{ style: { fontSize: "1.5rem" } }}
                      />
                      <ErrorMessage
                        name="registrationfee"
                        component="div"
                        className="error"
                      />
                    </Grid> */}
                  </Grid>
                </Paper>
                <Grid container spacing={1} sx={{ mt: 1 }}>
                  <Grid item xs={12} md={12} lg={12} sx={{ mb: 1 }}>
                    <div>
                      <Button
                        variant="contained"
                        disabled={!isValid || !dirty}
                        onClick={(e) => handleSubmit()}
                        sx={{
                          width: "100%",
                          mb: 4,
                          fontSize: "1.5rem",
                          letterSpacing: "3px",
                        }}
                        // style={{ color: "#fecc00" }}
                      >
                        {user ? "Registering..." : "Register"}
                      </Button>
                    </div>
                    <div className="accountLogin">
                      <h4>
                        Already have an account?{" "}
                        <Link
                          href=""
                          style={{ color: "#00b0f5" }}
                          onClick={handleLoginPage}
                        >
                          Login
                        </Link>
                      </h4>
                    </div>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
      {paymentModal && <PaymentModel />}
    </>
  );
};

export default SignupFormModel;
