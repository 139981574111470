import React, { useEffect, useState } from "react";
import logo from "../../../components/assets/images/cra_logo_V1 (2).png";
import { FiMenu } from "react-icons/fi";
import { MdOutlineClose } from "react-icons/md";
import { Link } from "react-router-dom";

const Headerv4 = () => {

  const [toggleOpen, setToggleOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    setIsSticky(scrollPosition > 0);
  };

  const menuHandler = () => {
    setToggleOpen(!toggleOpen);
  };

  const handleContactus = () => {
    const aboutUsSection = document.getElementById("footer_content");
    if (aboutUsSection) {
      const offset = 0;
      const scrollTop = aboutUsSection.offsetTop - offset;
      window.scrollTo({
        top: scrollTop,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <header className={`header ${isSticky ? "sticky4" : ""}`}>
        <div>
          <img src={logo} alt="logo" className="logo" />
        </div>
        <nav>
          <ul className={`menu_lists ${toggleOpen ? "show" : ""}`}>
            <li>
              <Link className="menu_list">Home</Link>
            </li>
            <li>
              <Link className="menu_list">About</Link>
            </li>
            <li>
              <Link className="menu_list">Service</Link>
            </li>
            <li>
              <Link className="menu_list">Projects</Link>
            </li>
            <li>
              <Link className="menu_list" onClick={handleContactus}>
                Contact
              </Link>
            </li>
            <li>
              <Link className="menu_list" onClick={handleContactus}>
                Register
              </Link>
            </li>
            <li>
              <Link
                className="menu_list join_now_btn"
                onClick={handleContactus}
              >
                Join Now
              </Link>
            </li>
          </ul>
        </nav>
        <div className="menu" onClick={menuHandler}>
          <FiMenu
            name="menu-outline"
            className={`menu_icon ${toggleOpen ? "hide" : ""}`}
          />
          <MdOutlineClose
            name="menu-outline"
            className={`menu_icon ${toggleOpen ? "" : "hide"}`}
          />
        </div>
      </header>
    </>
  );
};

export default Headerv4;
