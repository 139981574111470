import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchPdf = createAsyncThunk(
  "pdf/fetchPdf",
  async (filename, { rejectWithValue }) => {
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}/download/${filename}`,
        method: "GET",
        responseType: "blob",
      });

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const downloadUrl = window.URL.createObjectURL(blob);

      return { downloadUrl, filename };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const pdfSlice = createSlice({
  name: "pdf",
  initialState: {
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPdf.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPdf.fulfilled, (state, action) => {
        state.loading = false;

        const { downloadUrl, filename } = action.payload;
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .addCase(fetchPdf.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to download file";
      });
  },
});

export default pdfSlice.reducer;
