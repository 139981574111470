import { Dialog, Grid, styled } from "@mui/material";
import React, { useEffect } from "react";
import narayanan from "../../assets/images/Capture-removebg-preview (2) (1).png";
import { useDispatch, useSelector } from "react-redux";
import {
  setNarayananModalOpen,
} from "../../Redux/excutiveDirectorsSlice";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { useNavigate } from "react-router-dom";

const NarayananPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const narayananModal = useSelector(
    (state) => state.excutiveDirectorsSlice.narayananModalOpen
  );

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
    "& .MuiPaper-root": {
      width: "80%",
      maxWidth: "1200px",
    },
  }));

  const handleClose = () => {
    dispatch(setNarayananModalOpen(!narayananModal));
    navigate("/leadership");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* <Header /> */}
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={narayananModal}
      >
        {/* <Back /> */}
        <div className="directors_page">
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div>
                <CloseSharpIcon
                  style={{
                    float: "right",
                    fontSize: "4rem",
                    cursor: "pointer",
                  }}
                  onClick={handleClose}
                />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ padding: "0rem 4rem" }}>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <p></p>
              <h1>Suresh Narayanan</h1>
              <h2>Chief Operating Officer</h2>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <div className="krishna_img">
                <img
                  src={narayanan}
                  alt="krishna"
                  className="directors_img"
                  style={{ height: "28rem", width: "28rem" }}
                />
              </div>
            </Grid>
          </Grid>
        </div>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div className="directors_profile_cont">
              <p>
                Suresh Narayanan is an accomplished business leader and serial
                entrepreneur renowned for his success across diverse sectors. As
                the Founder and CEO of PVN Global LLC, a prominent entity
                headquartered in Boston, USA, he exemplifies strategic vision
                and leadership.
              </p>
              <p>
                Additionally, his entrepreneurial spirit extends to founding
                USAIND News, Indsamachar News (India) a digital news
                organization in the United States. With a proven track record,
                Suresh Narayanan brings a wealth of experience and expertise to
                the management team, steering companies to new heights of
                success.
              </p>
            </div>
          </Grid>
        </Grid>
        {/* <Footer /> */}
      </BootstrapDialog>
    </>
  );
};

export default NarayananPage;
