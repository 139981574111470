import React from "react";
import { Grid, Rating } from "@mui/material";
import classes from "./NavigatingFuture.module.css";
import Button from "../../common/Button";

const NavigatingFuture = () => {
  return (
    <>
      <div className={classes.container}>
        <Grid container spacing={1} sx={{ mb: 8 }}>
          <Grid item xs={12} sm={12} md={12} lg={8}>
            <h1 className={classes.primary_heading}>
              Navigating the Future of IT
            </h1>
            <p className={classes.primary_contnet}>
              At InnovateTech Hub, we foster a culture of innovation by
              facilitating connections across diverse domains.
            </p>
            <p className={classes.primary_contnet}>
              InnovateTech Hub is not just a community; it's where the future of
              IT is being reshaped. Join us and lead the wave.
            </p>
            <div className={classes.joinus_container}>
              <Button className={classes.join_now_btn}>Join Now</Button>
              <p className={classes.mail}>contact@innovatetechhub.com</p>
            </div>
            <div className={classes.rating_section}>
              <div className={classes.imactful_persons}>
                <img
                  src="https://res.cloudinary.com/dbyioi2qq/q_auto/v1699939678/static/images-3png_1699939452_46177.png"
                  alt="images"
                />
                <div className={classes.connections}>
                  <h2>110</h2>
                  <p>Impactful Connections</p>
                </div>
              </div>
              <div className={classes.rating}>
                <h1>4.8/5</h1>
                <div className={classes.rating_points}>
                  <p>
                    <Rating
                      name="half-rating"
                      defaultValue={4.5}
                      precision={0.5}
                    />
                  </p>
                  <p>Ratings</p>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <img
              src="https://res.cloudinary.com/dbyioi2qq/image/upload/v1714071121/vayrgpxqia4k8uzoqxww.jpg"
              alt="light"
              className={classes.light_image}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default NavigatingFuture;
