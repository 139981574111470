import * as Yup from "yup";

export const addFacultySchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  mobileNumber: Yup.string()
    .matches(/^[0-9]+$/, "Mobile number should contain only numbers")
    .length(10, "Mobile number should be exactly 10 digits")
    .required("Parent Mobile Number is required"),
  emailAddress: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  age: Yup.string().required("Age is required"),
  gender: Yup.string().required("Gender is required"),
  subjects: Yup.array()
    .min(1, "At least one subject must be selected")
    .required("Subjects is required"),
  qualification: Yup.string().required("Qualification is required"),
  experienceInYears: Yup.string().required("Experience is required"),
});
