import React from "react";

const CopyRights = () => {
  return (
    <>
      <div className="copyrights">
        <p>
          Copyright &copy; 2024 Certainty Rank Academy&reg; | All rights
          reserved
        </p>
      </div>
    </>
  );
};

export default CopyRights;
