import React, { useEffect, useState } from "react";
import {
  FormControl,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { ErrorMessage, Field, useFormikContext } from "formik";
import Button from "../../common/Button";
import { useNavigate } from "react-router-dom";

const BasicInformation = ({
  formStep,
  handleNext,
  handlePrevious,
  validateForm,
}) => {
  const maxDate = "2014-12-31";
  const navigate = useNavigate();
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const { setFieldValue, values } = useFormikContext();

  const fetchCountry = async () => {
    return await fetch("https://api.countrystatecity.in/v1/countries", {
      method: "GET",
      headers: {
        "X-CSCAPI-KEY":
          "QzNVT0FaeUlPU0FlSlNEWHl6ZjR1V1BEYlFuSmF5eWhybk4xMFN0NA==",
      },
      redirect: "follow",
    })
      .then((res) => res.json())
      .then((data) => data)
      .catch(() => {
        navigate("/register");
      });
  };

  const handleState = async (e) => {
    const country = e.target.value;
    setSelectedCountry(country);
    const selectedCountryObject = countries.find((c) => c.iso2 === country);
    setFieldValue("country", selectedCountryObject.name);
    try {
      const data = await fetch(
        `https://api.countrystatecity.in/v1/countries/${country}/states`,
        {
          method: "GET",
          headers: {
            "X-CSCAPI-KEY":
              "QzNVT0FaeUlPU0FlSlNEWHl6ZjR1V1BEYlFuSmF5eWhybk4xMFN0NA==",
          },
          redirect: "follow",
        }
      ).then((res) => res.json());
      const sortedStates = data.sort((a, b) => a.name.localeCompare(b.name));
      setStates(sortedStates);
      setCities([]);
      setSelectedState("");
      setFieldValue("state", "");
      setFieldValue("city", "");
    } catch (error) {
      navigate("/register");
    }
  };

  const handleCity = async (e) => {
    const state = e.target.value;
    setSelectedState(state);
    const selectedStateObject = states.find((s) => s.iso2 === state);
    setFieldValue("state", selectedStateObject.name);
    try {
      const data = await fetch(
        `https://api.countrystatecity.in/v1/countries/${selectedCountry}/states/${state}/cities`,
        {
          method: "GET",
          headers: {
            "X-CSCAPI-KEY":
              "QzNVT0FaeUlPU0FlSlNEWHl6ZjR1V1BEYlFuSmF5eWhybk4xMFN0NA==",
          },
          redirect: "follow",
        }
      ).then((res) => res.json());

      setCities(data);
      setFieldValue("city", "");
    } catch (error) {
      navigate("/register");
    }
  };

  const handleCityChange = (e) => {
    const city = e.target.value;
    const selectedCityObject = cities.find((c) => c.name === city);
    setFieldValue("city", selectedCityObject.name);
  };

  const handleSubmit = async () => {
    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      handleNext();
    }
  };
  useEffect(() => {
    fetchCountry().then((data) => {
      if (data) {
        setCountries(data);
      }
    });
  }, []);

  return (
    <>
      {formStep === 1 && (
        <>
          <fieldset className="fieldset">
            <legend className="legend">Personal Details</legend>
            <Grid
              container
              spacing={2}
              sx={{
                marginLeft: {
                  xs: "1rem",
                  sm: "2rem",
                  md: "-1.5rem",
                  lg: "-1.5rem",
                  xl: "-1.5rem",
                },
              }}
            >
              <Grid item xs={12} md={6} lg={6}>
                <h3 className="form_label">First Name</h3>
                <Field
                  as={TextField}
                  type="text"
                  placeholder="First Name"
                  name="studentFirstName"
                  fullWidth
                  inputProps={{ style: { fontSize: "1.5rem" } }}
                />
                <ErrorMessage
                  name="studentFirstName"
                  component="div"
                  className="error"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <h3 className="form_label">Last Name</h3>
                <Field
                  as={TextField}
                  type="text"
                  placeholder="Last Name"
                  name="studentLastName"
                  fullWidth
                  inputProps={{ style: { fontSize: "1.5rem" } }}
                />
                <ErrorMessage
                  name="studentLastName"
                  component="div"
                  className="error"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <h3 className="form_label">Email Address</h3>
                <Field
                  as={TextField}
                  type="email"
                  placeholder="Email Address"
                  name="studentEmailAddress"
                  fullWidth
                  inputProps={{ style: { fontSize: "1.5rem" } }}
                />
                <ErrorMessage
                  name="studentEmailAddress"
                  component="div"
                  className="error"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <h3 className="form_label">Gender</h3>
                <FormControl fullWidth>
                  <Field
                    as={Select}
                    labelId="gender-label"
                    id="gender"
                    label="gender"
                    name="gender"
                    fullWidth
                    inputProps={{ style: { fontSize: "1.5rem" } }}
                    input={<OutlinedInput />}
                    placeholder="Gender"
                    sx={{
                      minWidth: 120,
                      backgroundColor: "white",
                      borderRadius: "7px",
                      border: "1px solid #e9ecef",
                      color: "#000",
                      fontSize: "1.5rem",
                    }}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Gender
                    </MenuItem>
                    <MenuItem value={"Male"}>Male</MenuItem>
                    <MenuItem value={"FeMale"}>Female</MenuItem>
                    <MenuItem value={"Other"}>Other</MenuItem>
                  </Field>
                  <ErrorMessage
                    name="gender"
                    component="div"
                    className="error"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <h3 className="form_label">Date Of Birth</h3>
                <Field
                  as={TextField}
                  type="date"
                  placeholder="Date Of Birth"
                  name="dob"
                  fullWidth
                  inputProps={{ style: { fontSize: "1.5rem" }, max: maxDate }}
                />
                <ErrorMessage name="dob" component="div" className="error" />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <h3 className="form_label">Student Mobile Number</h3>
                <Field
                  as={TextField}
                  type="number"
                  placeholder="Mobile Number"
                  name="studentMobileNumber"
                  fullWidth
                  inputProps={{
                    style: { fontSize: "1.5rem" },
                    onWheel: (event) => event.currentTarget.blur(),
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                  }}
                  sx={{
                    "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                      {
                        WebkitAppearance: "none",
                        margin: 0,
                      },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                  }}
                />
                <ErrorMessage
                  name="studentMobileNumber"
                  component="div"
                  className="error"
                />
              </Grid>
              <Grid item xs={12} md={4} lg={4} xl={4}>
                <h3 className="form_label">Country</h3>
                <FormControl fullWidth>
                  <Field
                    as={Select}
                    name="country"
                    value={selectedCountry}
                    onChange={handleState}
                    displayEmpty
                    input={<OutlinedInput />}
                    inputProps={{ style: { fontSize: "1.5rem" } }}
                    sx={{
                      minWidth: 120,
                      backgroundColor: "white",
                      borderRadius: "7px",
                      fontSize: "1.5rem",
                    }}
                  >
                    <MenuItem value="" disabled>
                      Select Country
                    </MenuItem>
                    {countries.map((country) => (
                      <MenuItem key={country.iso2} value={country.iso2}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="country"
                    component="div"
                    className="error"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} lg={4} xl={4}>
                <h3 className="form_label">State</h3>
                <FormControl fullWidth>
                  <Field
                    as={Select}
                    name="state"
                    value={selectedState}
                    onChange={handleCity}
                    displayEmpty
                    input={<OutlinedInput />}
                    sx={{
                      minWidth: 120,
                      backgroundColor: "white",
                      borderRadius: "7px",
                      fontSize: "1.5rem",
                    }}
                    inputProps={{ style: { fontSize: "1.5rem" } }}
                  >
                    <MenuItem value="" disabled>
                      Select State
                    </MenuItem>
                    {states.map((state) => (
                      <MenuItem key={state.iso2} value={state.iso2}>
                        {state.name}
                      </MenuItem>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="state"
                    component="div"
                    className="error"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} lg={4} xl={4}>
                <h3 className="form_label">City</h3>
                <FormControl fullWidth>
                  <Field
                    as={Select}
                    name="city"
                    value={values.city}
                    onChange={handleCityChange}
                    displayEmpty
                    input={<OutlinedInput />}
                    sx={{
                      minWidth: 120,
                      backgroundColor: "white",
                      borderRadius: "7px",
                      fontSize: "1.5rem",
                    }}
                    inputProps={{ style: { fontSize: "1.5rem" } }}
                  >
                    <MenuItem value="" disabled>
                      Select City
                    </MenuItem>
                    {cities.map((city) => (
                      <MenuItem key={city.id} value={city.name}>
                        {city.name}
                      </MenuItem>
                    ))}
                  </Field>
                  <ErrorMessage name="city" component="div" className="error" />
                </FormControl>
              </Grid>
            </Grid>
          </fieldset>
          <Grid item xs={12} md={12} lg={12} xl={12}>
            <div className="pre_nxt_btn_box">
              <Button className="previous_btn" onClick={handlePrevious}>
                Previous
              </Button>
              <Button className="next_btn" onClick={handleSubmit}>
                Next
              </Button>
            </div>
          </Grid>
        </>
      )}
    </>
  );
};

export default BasicInformation;
