import React, { useState } from "react";
import {
  FormControl,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { ErrorMessage, Field, useFormikContext } from "formik";
import Button from "../../common/Button";
const ParentInformation = ({
  formStep,
  validateForm,
  handleNext,
  handlePrevious,
}) => {

  const handleSubmit = async () => {
    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      handleNext();
    }
  };

  return (
    <>
      {formStep === 2 && (
        <>
          <fieldset className="fieldset">
            <legend className="legend">Parent Inforamtion</legend>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12} xl={12}>
                <h3 className="form_label">Parent Name</h3>
                <Field
                  as={TextField}
                  type="text"
                  placeholder="Parent Name"
                  name="parentName"
                  fullWidth
                  inputProps={{
                    style: { fontSize: "1.5rem" },
                  }}
                />
                <ErrorMessage
                  name="parentName"
                  component="div"
                  className="error"
                />
              </Grid>
              <Grid item xs={12} md={12} lg={4} xl={4} sx={{ mt: -2 }}>
                <h3 className="form_label">Parent Email</h3>
                <Field
                  as={TextField}
                  type="email"
                  placeholder="Parent Email Id"
                  name="parentEmailAddress"
                  fullWidth
                  inputProps={{
                    style: { fontSize: "1.5rem" },
                  }}
                />

                <ErrorMessage
                  name="parentEmailAddress"
                  component="div"
                  className="error"
                />
              </Grid>
              <Grid item xs={12} md={12} lg={4} xl={4} sx={{ mt: -2 }}>
                <h3 className="form_label">Mobile Number</h3>
                <Field
                  as={TextField}
                  type="number"
                  placeholder="Mobile Number"
                  name="parentMobileNumber"
                  fullWidth
                  inputProps={{
                    style: { fontSize: "1.5rem" },
                    onWheel: (event) => event.currentTarget.blur(),
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                  }}
                  sx={{
                    "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                      {
                        WebkitAppearance: "none",
                        margin: 0,
                      },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                  }}
                />
                <ErrorMessage
                  name="parentMobileNumber"
                  component="div"
                  className="error"
                />
              </Grid>
              <Grid item xs={12} md={12} lg={4} xl={4} sx={{ mt: -2 }}>
                <h3 className="form_label">Relationship</h3>
                <FormControl fullWidth>
                  <Field
                    as={Select}
                    labelId="relationship-label"
                    id="relationship"
                    label="Relationship"
                    name="relationship"
                    fullWidth
                    inputProps={{ style: { fontSize: "1.5rem" } }}
                    input={<OutlinedInput />}
                    placeholder="Relationship"
                    sx={{
                      minWidth: 120,
                      backgroundColor: "white",
                      borderRadius: "7px",
                      color: "#b4b4b4",
                      fontSize: "1.5rem",
                    }}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Relationship
                    </MenuItem>
                    <MenuItem value={"Father"}>Father</MenuItem>
                    <MenuItem value={"Mother"}>Mother</MenuItem>
                    <MenuItem value={"Guardian"}>Guardian</MenuItem>
                  </Field>
                  <ErrorMessage
                    name="relationship"
                    component="div"
                    className="error"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </fieldset>
          <fieldset className="fieldset">
            <legend className="legend">Current Class Information</legend>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12} xl={12}>
                <h3 className="form_label">School Name</h3>
                <Field
                  as={TextField}
                  type="text"
                  placeholder="School Name"
                  name="schoolName"
                  fullWidth
                  inputProps={{
                    style: { fontSize: "1.5rem" },
                  }}
                />
                <ErrorMessage
                  name="schoolName"
                  component="div"
                  className="error"
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6} xl={6} sx={{ mt: -2 }}>
                <h3 className="form_label">Current Class</h3>
                <FormControl fullWidth>
                  <Field
                    as={Select}
                    labelId="current-class-label"
                    id="current-class"
                    label="Current Class"
                    name="currentClass"
                    fullWidth
                    inputProps={{ style: { fontSize: "1.5rem" } }}
                    input={<OutlinedInput />}
                    placeholder="Current Class"
                    sx={{
                      minWidth: 120,
                      backgroundColor: "white",
                      borderRadius: "7px",
                      // border: "1px solid #8c0005",
                      // boxShadow: "rgba(0, 0, 0, 0.15) 0 0 5px",
                      color: "#b4b4b4",
                      fontSize: "1.5rem",
                    }}
                    displayEmpty
                  >
                    <MenuItem value="" disabled style={{ fontSize: "1.5rem" }}>
                      Current Class
                    </MenuItem>
                    <MenuItem value={"12"}>PUC 2 (12)</MenuItem>
                    <MenuItem value={"11"}>PUC 1 (11) </MenuItem>
                    <MenuItem value={"10"}>10th</MenuItem>
                    <MenuItem value={"9"}>9th</MenuItem>
                    <MenuItem value={"8"}>8th</MenuItem>
                    <MenuItem value={"7"}>7th</MenuItem>
                    <MenuItem value={"6"}>6th</MenuItem>
                    <MenuItem value={"5"}>5th</MenuItem>
                    {/* <MenuItem value={"6th"}>6th</MenuItem>
                          <MenuItem value={"5th"}>5th</MenuItem> */}
                  </Field>
                  <ErrorMessage
                    name="currentClass"
                    component="div"
                    className="error"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12} lg={6} xl={6} sx={{ mt: -2 }}>
                <h3 className="form_label">Syllabus</h3>
                <FormControl fullWidth>
                  <Field
                    as={Select}
                    labelId="syllabus-label"
                    id="syllabus"
                    label="Syllabus"
                    name="Syllabus"
                    fullWidth
                    inputProps={{ style: { fontSize: "1.5rem" } }}
                    input={<OutlinedInput />}
                    placeholder="Syllabus"
                    sx={{
                      minWidth: 120,
                      backgroundColor: "white",
                      borderRadius: "7px",
                      // border: "1px solid #8c0005",
                      // boxShadow: "rgba(0, 0, 0, 0.15) 0 0 5px",
                      color: "#b4b4b4",
                      fontSize: "1.5rem",
                    }}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Syllabus
                    </MenuItem>
                    <MenuItem value={"CBSE"}>CBSE</MenuItem>
                    <MenuItem value={"ICSE"}>ICSE</MenuItem>
                    <MenuItem value={"State"}>State</MenuItem>
                    <MenuItem value={"Other"}>Other</MenuItem>
                  </Field>
                  <ErrorMessage
                    name="Syllabus"
                    component="div"
                    className="error"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </fieldset>
          <Grid item xs={12} md={12} lg={12} xl={12}>
            <div className="pre_nxt_btn_box">
              <Button className="previous_btn" onClick={handlePrevious}>
                Previous
              </Button>

              <Button className="next_btn" onClick={handleSubmit}>
                Next
              </Button>
            </div>
          </Grid>
        </>
      )}
    </>
  );
};

export default ParentInformation;
