import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { authHeader } from "../common/authHeader";
import { showToast } from "../utils/Toasts";

const initialState = {
  loading: false,
  studentsData: [],
  selectedStudentsRowData: [],
  error: null,
  editStudentData: false,
  addStudents: false,
};

export const getStudentsData = createAsyncThunk(
  "students/getStudentsData",
  async () => {
    const requestOptions = {
      headers: authHeader(),
    };
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/student`,
      requestOptions
    );
    return response.data.data;
  }
);

export const updateStudentData = createAsyncThunk(
  "students/updateStudentData",
  async (updatedStudent) => {
    const requestOptions = {
      headers: authHeader(),
    };
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/update/${updatedStudent._id}`,
      updatedStudent,
      requestOptions
    );
    if (response.status === 200 && response.data.success === true) {
      showToast(response.data.data, "success");
    }
    return response.data.data;
  }
);

export const deleteStudentData = createAsyncThunk(
  "students/deleteStudentData",
  async (id) => {
    await axios.delete(`/api/students/${id}`);
    return id;
  }
);

const studentsSlice = createSlice({
  name: "students",
  initialState,
  reducers: {
    setStudentsData: (state, action) => {
      state.studentsData = action.payload;
    },
    setAddStudents: (state, action) => {
      state.addStudents = action.payload;
    },
    setSelectedStudentsRowData: (state, action) => {
      state.selectedStudentsRowData = action.payload;
    },
    setStudentEditData: (state, action) => {
      state.editStudentData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStudentsData.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getStudentsData.fulfilled, (state, action) => {
      state.loading = false;
      state.studentsData = action.payload;
      state.error = null;
    });
    builder.addCase(updateStudentData.fulfilled, (state, action) => {
      const index = state.studentsData.findIndex(
        (student) => student._id === action.payload._id
      );
      if (index !== -1) {
        state.studentsData[index] = action.payload;
      }
    });
    builder.addCase(getStudentsData.rejected, (state, action) => {
      state.studentsData = [];
      state.error = action.payload;
    });
  },
});

export const {
  setStudentsData,
  setSelectedStudentsRowData,
  setStudentEditData,
  setAddStudents,
} = studentsSlice.actions;
export default studentsSlice.reducer;
