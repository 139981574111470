import React, { useEffect } from "react";
import Headerv4 from "./Headerv4";
import NavigatingFuture from "./NavigatingFuture";
import Futures from "./Futures";
import FeatureProjects from "./FeatureProjects";
import ContactFooter from "../../common/contactFooter/ContactFooter";
import MiniHeader from "../HomePage/MiniHeader";

const FutureOfIt = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div>
        <MiniHeader />
        <Headerv4 />
        <NavigatingFuture />
        <Futures />
        <FeatureProjects />
        {/* <GetInTouch /> */}
        {/* <GetInTouchContactData /> */}
        <ContactFooter />
        {/* <Footer /> */}
      </div>
    </>
  );
};

export default FutureOfIt;
