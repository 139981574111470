import { Grid } from "@mui/material";
import React from "react";
import classes from "./Revolution.module.css";

const Revolution = () => {
  return (
    <>
      <div className={classes.section_revolution}>
        <h1 className={classes.primary_heading}>Revolutionize Your Study</h1>
        <p className={classes.primary_contnet}>
          Discover how our AI-powered online courses offer a personalized
          approach to learning that adapts to your pace and enhances exam
          scores.
        </p>
      </div>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={6} md={6} lg={6}></Grid>
      </Grid>
    </>
  );
};

export default Revolution;
