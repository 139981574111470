import React from "react";
import { Grid } from "@mui/material";
import classes from "./MathCuriosity.module.css";
import Button from "../../common/Button";
import puzzleImage from "../../../components/assets/images/eyyv2eckibfo1qnkpltg.png";
import gradinat from "../../../components/assets/images/wmwlhqa3zjzroiezayvg_100_11zon.png";

const MathCuriosity = () => {
  return (
    <>
      <Grid
        container
        spacing={1}
        sx={{ mb: 2, mt: -10 }}
        id="transferskills_content"
      >
        <Grid item xs={12} sm={12} md={12} lg={6.5}>
          <h1 className={classes.primary_heading}>
            Ignite Your Math Curiosity
          </h1>
          <p className={classes.primary_contnet}>
            Embark on a thrilling journey into the world of mathematics with
            Math Matrix. Dive deep into algorithms, engage with visual learning
            aids, and enjoy a personalized education experience.
          </p>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <img src={puzzleImage} alt="puzzle" className={classes.puzzle_img} />
          <h2 className={classes.secondary_heading}>
            Algorithmic Challenges Await{" "}
          </h2>
          <p className={classes.secondary_contnet}>
            Challenge yourself with puzzles and problems powered by advanced AI
            algorithms
          </p>
          <Button className={classes.readmore_btn}>Try Now</Button>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <img src={gradinat} alt="gradinat" className={classes.puzzle_img} />
          <h2 className={classes.secondary_heading}>
            Visual Learning Revolution
          </h2>
          <p className={classes.secondary_contnet}>
            Grasp complex mathematical concepts easier with intuitive visual
            learning aids.
          </p>
          <Button className={classes.readmore_btn}>Learn More</Button>
        </Grid>
      </Grid>
    </>
  );
};

export default MathCuriosity;
