import React, { useEffect, useState } from "react";
import { FiMenu } from "react-icons/fi";
import { MdOutlineClose } from "react-icons/md";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Logo from "./Logo";
import MiniHeader from "./MiniHeader";
import LoginForm from "../../authentication/Login/LoginForm";
import SignupFormModel from "../../authentication/SignUp/SignupFormModel";

const Header = () => {
  const popupOpen = useSelector((state) => state.loginReducer.modelOpen);
  const navigate = useNavigate();
  const location = useLocation();

  const [toggleOpen, setToggleOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState("home");

  const handleMenuItemClick = (menuItem) => {
    setToggleOpen(!toggleOpen);
    setActiveMenuItem(menuItem);
  };

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    setIsSticky(scrollPosition > 0);
  };

  const menuHandler = () => {
    setToggleOpen(!toggleOpen);
  };

  const hendleAboutus = () => {
    const aboutUsSection = document.getElementById("aboutUsSection");
    if (aboutUsSection) {
      const offset = 180;
      const scrollTop = aboutUsSection.offsetTop - offset;
      window.scrollTo({
        top: scrollTop,
        behavior: "smooth",
      });
    }
  };

  const handleContactus = () => {
    const aboutUsSection = document.getElementById("footer_content");
    if (aboutUsSection) {
      const offset = 20;
      const scrollTop = aboutUsSection.offsetTop - offset;
      window.scrollTo({
        top: scrollTop,
        behavior: "smooth",
      });
    }
  };

  const handleOurPrograms = () => {
    navigate("/programs");
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (
      location.pathname === "/programs" ||
      location.pathname.startsWith("/programs")
    ) {
      setActiveMenuItem("programs");
    } else if (location.pathname === "/leadership") {
      setActiveMenuItem("Team");
    } else if (location.pathname === "/about") {
      setActiveMenuItem("about");
    } else if (location.pathname === "/contact") {
      setActiveMenuItem("Contact Us");
    } else {
      setActiveMenuItem("home");
    }
  }, [location.pathname]);

  return (
    <>
      <section className={`stickey_header ${isSticky ? "sticky" : ""}`}>
        <MiniHeader />
        <header className="header">
          <Logo />
          <nav>
            <ul className={`menu_lists ${toggleOpen ? "show" : ""}`}>
              <li>
                <Link
                  to="/"
                  className={`menu_list ${
                    activeMenuItem === "home" ? "activeMenuItem" : ""
                  }`}
                  style={{
                    // color: "#fdc632",
                    fontWeight: 550,
                    textDecoration: "none",
                    fontSize: "14px",
                    fontFamily: "Rubik,sans-serif",
                  }}
                  onClick={() => handleMenuItemClick("home")}
                >
                  Home
                </Link>
              </li>
              {location.pathname === "/" && (
                <li>
                  <Link
                    // to="/about"
                    className={`menu_list ${
                      activeMenuItem === "about" ? "activeMenuItem" : ""
                    }`}
                    onClick={() => {
                      hendleAboutus();
                      handleMenuItemClick("about");
                    }}
                  >
                    About Us
                  </Link>
                </li>
              )}

              <li
                onClick={() => {
                  handleOurPrograms();
                  handleMenuItemClick("programs");
                }}
              >
                <Link
                  to="programs"
                  className={`menu_list ${
                    activeMenuItem === "programs" ? "activeMenuItem" : ""
                  }`}
                >
                  Programs
                </Link>
              </li>
              <li
                onClick={() => {
                  handleMenuItemClick("Team");
                }}
              >
                <Link
                  to="/leadership"
                  className={`menu_list ${
                    activeMenuItem === "Team" ? "activeMenuItem" : ""
                  }`}
                >
                  LeaderShip
                </Link>
              </li>
              <li>
                <Link
                  className={`menu_list ${
                    activeMenuItem === "Contact Us" ? "activeMenuItem" : ""
                  }`}
                  onClick={() => {
                    handleContactus();
                    handleMenuItemClick("Contact Us");
                  }}
                >
                  Contact Us
                </Link>
              </li>
            </ul>
          </nav>
          <div className="menu" onClick={menuHandler}>
            <FiMenu
              name="menu-outline"
              className={`menu_icon ${toggleOpen ? "hide" : ""}`}
            />
            <MdOutlineClose
              name="menu-outline"
              className={`menu_icon ${toggleOpen ? "" : "hide"}`}
            />
          </div>
        </header>
      </section>
      <SignupFormModel />
      {popupOpen && <LoginForm />}
    </>
  );
};

export default Header;
