import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  getStudentsData,
  setStudentEditData,
  setStudentsData,
  updateStudentData,
} from "../../../Redux/studentsSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import Modal from "@mui/material/Modal";
import BasicInformationEdit from "./BasicInformationEdit";
import ParentInformationEdit from "./ParentInformationEdit";
import UploadPictureEdit from "./UploadPictureEdit";
import WelcomePageEdit from "./WelcomePageEdit";
import { showToast } from "../../../utils/Toasts";
import CloseIcon from "@mui/icons-material/Close";
import {
  validationSchemaStep1,
  validationSchemaStep2,
  validationSchemaStepForProgram,
} from "../../../utils/signupSchemas/signupSchemas";

const StudentsRowUpdate = () => {
  const dispatch = useDispatch();

  const validationSchemaByStep = {
    0: validationSchemaStepForProgram,
    1: validationSchemaStep1,
    2: validationSchemaStep2,
  };

  const { studentsData, editStudentData, selectedStudentsRowData } =
    useSelector((state) => ({
      studentsData: state.studentReducer.studentsData,
      editStudentData: state.studentReducer.editStudentData,
      selectedStudentsRowData: state.studentReducer.selectedStudentsRowData,
    }));
  console.log("initialData", selectedStudentsRowData);

  const [formStep, setFormStep] = useState(0);
  const [isUpdating, setIsUpdating] = useState(false);

  const deepEqual = (obj1, obj2) => {
    if (obj1 === obj2) return true;

    if (
      typeof obj1 !== "object" ||
      typeof obj2 !== "object" ||
      obj1 == null ||
      obj2 == null
    ) {
      return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false;

    for (const key of keys1) {
      if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  };

  const handleNext = async (validateForm) => {
    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      setFormStep((prevStep) => prevStep + 1);
    }
  };

  const handlePrevious = () => {
    setFormStep((prevStep) => Math.max(prevStep - 1, 0));
  };
  const handleFormSubmit = async (values) => {
    console.log("Submitting form");
    const updatedStudent = {
      ...selectedStudentsRowData,
      program: values.program,
      studentFirstName: values.studentFirstName,
      studentLastName: values.studentLastName,
      studentMobileNumber: values.studentMobileNumber,
      studentEmailAddress: values.studentEmailAddress,
      parentName: values.parentName,
      parentEmailAddress: values.parentEmailAddress,
      parentMobileNumber: values.parentMobileNumber,
      relationship: values.relationship,
      Syllabus: values.Syllabus,
      dob: values.dob,
      schoolName: values.schoolName,
      currentClass: values.currentClass,
      country: values.country,
      state: values.state,
      city: values.city,
      gender: values.gender,
      isRegFeePaid: values.isRegFeePaid,
    };
    if (values.program === undefined) {
      return;
    }
    const isUnchanged = deepEqual(values, selectedStudentsRowData);

    if (isUnchanged) {
      showToast("No changes were made", "warning");
      return;
    }

    if (isUpdating) {
      return;
    }

    try {
      const resultAction = await dispatch(updateStudentData(updatedStudent));
      unwrapResult(resultAction);

      const updatedTableData = studentsData.map((item) =>
        item._id === selectedStudentsRowData._id ? updatedStudent : item
      );

      setIsUpdating(true);
      dispatch(setStudentsData(updatedTableData));
      dispatch(getStudentsData());
      dispatch(setStudentEditData(false));
    } catch (error) {
      showToast(error, "error");
    } finally {
      setIsUpdating(false);
    }
  };

  const handleClose = () => {
    dispatch(setStudentEditData(!editStudentData));
  };

  return (
    <>
      <Modal
        keepMounted
        open={editStudentData}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        BackdropProps={{
          onClick: (event) => event.stopPropagation(),
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: {
              xs: "38rem",
              sm: "38rem",
              md: "auto",
              lg: "auto",
              xl: "auto",
            },
            height: {
              xs: formStep === 0 ? "29rem" : formStep === 3 ? "35rem" : "45rem",
              sm: "45rem",
              md: "auto",
              lg: "auto",
              xl: "auto",
            },
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: "1rem",
            p: {
              xs: 2,
              sm: 2,
              md: 2,
              lg: 4,
              xl: 4,
            },
            overflow: "auto",
          }}
        >
          <Formik
            initialValues={{
              program: selectedStudentsRowData.program || "",
              studentFirstName: selectedStudentsRowData.studentFirstName || "",
              studentLastName: selectedStudentsRowData.studentLastName || "",
              studentMobileNumber:
                selectedStudentsRowData.studentMobileNumber || "",
              studentEmailAddress:
                selectedStudentsRowData.studentEmailAddress || "",
              parentName: selectedStudentsRowData.parentName || "",
              parentEmailAddress:
                selectedStudentsRowData.parentEmailAddress || "",
              parentMobileNumber:
                selectedStudentsRowData.parentMobileNumber || "",
              relationship: selectedStudentsRowData.relationship || "",
              Syllabus: selectedStudentsRowData.Syllabus || "",
              dob: selectedStudentsRowData.dob
                ? selectedStudentsRowData.dob.split("T")[0]
                : "",
              schoolName: selectedStudentsRowData.schoolName || "",
              currentClass: selectedStudentsRowData.currentClass || "",
              country: selectedStudentsRowData.country || "",
              state: selectedStudentsRowData.state || "",
              city: selectedStudentsRowData.city || "",
              gender: selectedStudentsRowData.gender || "",
              isRegFeePaid: selectedStudentsRowData.isRegFeePaid || "",
            }}
            validationSchema={validationSchemaByStep[formStep]}
            onSubmit={(values, { resetForm }) => {
              handleFormSubmit(values);
              resetForm();
            }}
          >
            {({ handleSubmit, validateForm, setFieldValue, values }) => (
              <Form
                onSubmit={handleSubmit}
                // style={{
                //   padding: "5rem 2rem",
                //   display: "flex",
                //   flexDirection: "column",
                //   justifyContent: "center",
                //   alignItems: "center",
                // }}
              >
                <div>
                  <CloseIcon
                    onClick={handleClose}
                    style={{
                      float: "right",
                      fontSize: "3rem",
                      cursor: "pointer",
                    }}
                  />
                </div>
                <Grid container spacing={2}>
                  <>
                    <WelcomePageEdit
                      formStep={formStep}
                      setFormStep={setFormStep}
                      setFieldValue={setFieldValue}
                      values={values}
                      handleNext={() => handleNext(validateForm)}
                      validateForm={validateForm}
                    />
                    <BasicInformationEdit
                      formStep={formStep}
                      handleNext={() => handleNext(validateForm)}
                      handlePrevious={handlePrevious}
                      values={values}
                      validateForm={validateForm}
                    />
                    <ParentInformationEdit
                      formStep={formStep}
                      handleNext={() => handleNext(validateForm)}
                      handlePrevious={handlePrevious}
                      validateForm={validateForm}
                    />
                    <UploadPictureEdit
                      formStep={formStep}
                      handlePrevious={handlePrevious}
                      handleFormSubmit={handleFormSubmit}
                      setFieldValue={setFieldValue}
                      validateForm={validateForm}
                    />
                  </>
                </Grid>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
    </>
  );
};

export default StudentsRowUpdate;
