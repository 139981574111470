import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import { Grid } from "@mui/material";
import logo from "../../assets/images/RANK-2_80_11zon.png";
import PaymentForm from "./PaymentForm";
import CloseIcon from "@mui/icons-material/Close";
import { setPaymentModelOpen } from "../../Redux/paymenyModelSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  height: "90vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "auto",
};

export default function PaymentModel() {
  const dispatch = useDispatch();

  const paymentModal = useSelector(
    (state) => state.paymentModelSlice.paymentModelOpen
  );

  const closeHandler = () => {
    dispatch(setPaymentModelOpen(!paymentModal));
  };

  return (
    <div className="paymnet_page_container">
      <Modal
        keepMounted
        open={paymentModal}
        onClose={closeHandler}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <CloseIcon
                style={{
                  color: "#000",
                  fontSize: "4rem",
                  cursor: "pointer",
                  //   position: "absolute",
                  //   top: "5px",
                  //   right: "calc(120% + 10px)",
                  //   zIndex: 1,
                  float: "right",
                  marginTop: "-2rem",
                }}
                onClick={closeHandler}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <div className="paymnet_detail">
                <img src={logo} alt="logo" className="logo" />
                <h3>Chilakalanerpu education trust center</h3>
              </div>
              <div className="paymnet_receipt">
                <h1>Payment Receipt for Course Fee</h1>
              </div>
              <div className="payment_query">
                <h4>Contact Us</h4>
                <div className="payment_cont">
                  <LocalPhoneIcon
                    style={{ color: "#023e8a", fontSize: "2rem" }}
                  />
                  <hp>91-994-548-0219</hp>
                </div>
                <div className="payment_cont">
                  <EmailIcon style={{ color: "#023e8a", fontSize: "2rem" }} />
                  <hp>krishna.b@mdxblocks.com</hp>
                </div>
              </div>
              <div className="paymnet_terms">
                <h2>Terms & Conditions</h2>
                <p>
                  You agree to share Information entered this page with
                  CHILAKALANERPU EDUCATION TRUST CET R (owner of this page) and
                  Razorpay,adhering to application laws
                </p>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100vw",
              }}
            >
              <PaymentForm />
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
