import { Grid } from "@mui/material";
import React from "react";
import classes from "./LearningPaths.module.css";

const LearningPaths = () => {
  return (
    <>
      {/* <Grid container spacing={1} sx={{ mt: 0 }}>
        <Grid item xs={12} sm={12} md={12} lg={8}>
          <h1 className={classes.primary_heading}>
            Our Tailored Learning Paths
          </h1>
        </Grid>
      </Grid> */}
      {/* <Grid
        container
        spacing={1}
        sx={{ mt: { xs: -9, sm: -9, md: -9, lg: 5 } }}
      >
        <Grid item xs={12} sm={12} md={12} lg={4}>
          <h1 className={classes.secondary_heading}>
            Elementary English Basics
          </h1>
          <p className={classes.secondary_contnet}>
            Our program starts with the basics: sentence structures, nouns,
            verbs, and adjectives, setting a solid foundation for early
            learners.
          </p>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={8}>
          <img
            src="https://res.cloudinary.com/dbyioi2qq/image/upload/v1713888584/tjqwutncmvdnss8slyg6.jpg"
            alt="pencils"
            className={classes.pencil_image}
          />
        </Grid>
      </Grid> */}
      <Grid container spacing={0} id="course_content">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <h1 className={classes.primary_heading} id="featuresAndBenfits">
            Features & Benefits
          </h1>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <div className={classes.third}>
          <Grid item xs={12} sm={12} md={12} lg={3}>
            <h1 className={classes.third_heading}>
              Adaptive Learning Algorithms:
            </h1>
            <p className={classes.third_contnet}>
              Personalizes the learning experience by adjusting the difficulty
              level and pacing based on individual student performance.
            </p>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={3}>
            <h1 className={classes.third_heading}>24/7 AI Tutoring</h1>
            <p className={classes.third_contnet}>
              Provides round-the-clock access to AI tutors that offer
              explanations, assist with homework, and answer questions in
              real-time.
            </p>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={3}>
            <h1 className={classes.third_heading}>Interactive Content</h1>
            <p className={classes.third_contnet}>
              Utilizes engaging multimedia resources like videos, animations,
              and gamified quizzes to enhance learning.
            </p>
          </Grid>
        </div>
      </Grid>
      <Grid container spacing={0}>
        <div className={classes.third}>
          <Grid item xs={12} sm={12} md={12} lg={3}>
            <h1 className={classes.third_heading}>
              AI-Enhanced Speech Recognition
            </h1>
            <p className={classes.third_contnet}>
              Facilitates speaking and pronunciation practice by using advanced
              speech recognition technology to give immediate feedback.
            </p>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={3}>
            <h1 className={classes.third_heading}>Data-Driven Insights</h1>
            <p className={classes.third_contnet}>
              Offers detailed tracking and analytics on student progress,
              identifying strengths and areas for improvement.
            </p>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={3}>
            <h1 className={classes.third_heading}>
              Customizable Learning Pathways
            </h1>
            <p className={classes.third_contnet}>
              Allows students and educators to tailor the curriculum to fit
              specific goals and learning styles.
            </p>
          </Grid>
        </div>
      </Grid>
      <Grid container spacing={0} sx={{ mt: -1 }}>
        <div className={classes.third}>
          <Grid item xs={12} sm={12} md={12} lg={3}>
            <h1 className={classes.third_heading}>Multilingual Support</h1>
            <p className={classes.third_contnet}>
              Accommodates non-native English speakers by providing course
              materials and support in multiple languages.
            </p>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={3}>
            <h1 className={classes.third_heading}>
              Enhanced Engagement and Retention
            </h1>
            <p className={classes.third_contnet}>
              Interactive and dynamic content keeps students engaged, leading to
              higher retention rates.
            </p>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={3}>
            <h1 className={classes.third_heading}>Efficient Learning </h1>
            <p className={classes.third_contnet}>
              AI-driven insights and feedback help students focus on their weak
              areas, making learning more efficient and effective.
            </p>
          </Grid>
        </div>
      </Grid>
      <Grid container spacing={0}>
        <div className={classes.third}>
          <Grid item xs={12} sm={12} md={12} lg={3}>
            <h1 className={classes.third_heading}>
              Personalized Learning Experience
            </h1>
            <p className={classes.third_contnet}>
              Students receive a customized learning experience that adapts to
              their unique learning styles and speeds, maximizing educational
              outcomes
            </p>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={3}>
            <h1 className={classes.third_heading}>
              Accessible Anytime, Anywhere
            </h1>
            <p className={classes.third_contnet}>
              Students can access course materials and AI tutoring from any
              device, providing flexibility to learn outside of traditional
              classroom settings.
            </p>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={3}>
            <h1 className={classes.third_heading}>
              Improved Language Proficiency
            </h1>
            <p className={classes.third_contnet}>
              Comprehensive curriculum that covers all aspects of English
              language, from basic grammar to advanced writing, ensuring
              thorough language mastery.
            </p>
          </Grid>
        </div>
      </Grid>

      {/* <Grid
        container
        spacing={1}
        sx={{ mt: { xs: -5, sm: -5, md: -5, lg: 8 } }}
      >
        <Grid item xs={12} sm={12} md={12} lg={4}>
          <h1 className={classes.secondary_heading}>Personalized Learning</h1>
          <p className={classes.secondary_contnet}>
            Each learner receives custom-tailored content, ensuring the best
            path to English proficiency.
          </p>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={8}>
          <img
            src="https://res.cloudinary.com/dbyioi2qq/image/upload/v1713888584/hhjliefhmxbfbks5beay.jpg"
            alt="pencils"
            className={classes.pencil_image}
          />
        </Grid>
      </Grid> */}
    </>
  );
};

export default LearningPaths;
