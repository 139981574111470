import React, { useState } from "react";
import { Grid } from "@mui/material";
import welcomePage from "../../assets/images/welcomePage.png";
import register from "../../assets/images/register2.png";
import education from "../../assets/images/educational.png";
import uploadPictute from "../../assets/images/uploadImage.png";
import PersonalDetailForm from "./PersonalDetailsForm";

const Register = () => {
  const [formStep, setFormStep] = useState(0);

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{ padding: { xs: "1rem 1rem", sm: "1rem 1rem", lg: "2rem 1rem" } }}
      >
        <Grid item xs={12} md={6} lg={6}>
          <div className="signup_image_container">
            {formStep === 0 ? (
              <img
                src={welcomePage}
                alt="welcomePage step 0"
                className="signup_images"
              />
            ) : formStep === 1 ? (
              <img src={register} alt="register" />
            ) : formStep === 2 ? (
              <img src={education} alt="education" />
            ) : (
              <img src={uploadPictute} alt="upload" />
            )}
          </div>
        </Grid>
        <Grid item xs={12} md={6} lg={6} sx={{ p: 2 }}>
          <PersonalDetailForm formStep={formStep} setFormStep={setFormStep} />
        </Grid>
      </Grid>
    </>
  );
};

export default Register;
