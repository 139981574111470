// src/components/PersonalDetailForm.js

import React from "react";
import { Grid } from "@mui/material";
import { Form, Formik } from "formik";
import BasicInformation from "./BasicInformation";
import ParentInformation from "./ParentInformation";
import UploadPicture from "./UploadPicture";
import WelcomePage from "./WelcomePage";
import {
  validationSchemaStep0,
  validationSchemaStep1,
  validationSchemaStep2,
  validationSchemaStep3,
} from "../../utils/signupSchemas/signupSchemas";
import { useDispatch, useSelector } from "react-redux";
import { setRegisterData } from "../../Redux/paymenyModelSlice";
import { setSuccess, updateUserAsync } from "../../Redux/userSlice";
import { uploadPhoto } from "../../Redux/uploadPhotoSlice";
import { useNavigate } from "react-router-dom";

const PersonalDetailForm = ({ formStep, setFormStep }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const studentId = useSelector((state) => state.users.studentId);

  const validationSchemaByStep = {
    0: validationSchemaStep0,
    1: validationSchemaStep1,
    2: validationSchemaStep2,
    3: validationSchemaStep3,
  };

  const handleNext = async (validateForm) => {
    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      setFormStep((prevStep) => prevStep + 1);
    }
  };

  const handlePrevious = () => {
    setFormStep((prevStep) => Math.max(prevStep - 1, 0));
  };

  const handleFormSubmit = async (values) => {
    try {
      const { confirmPassword, ...postData } = values;
      const response = await dispatch(updateUserAsync(postData));
      dispatch(setRegisterData(values));

      if (response.payload && response.payload.success === true) {
        dispatch(setSuccess(true));
        navigate("/");
      } else {
        dispatch(setSuccess(false));
      }
    } catch (error) {
      dispatch(setSuccess(false));
    }
  };

  return (
    <Formik
      initialValues={{
        program: "",
        studentFirstName: "",
        studentLastName: "",
        studentEmailAddress: "",
        gender: "",
        dob: "",
        studentMobileNumber: "",
        country: "",
        state: "",
        city: "",
        parentName: "",
        parentEmailAddress: "",
        parentMobileNumber: "",
        relationship: "",
        schoolName: "",
        currentClass: "",
        Syllabus: "",
        confirmPassword: "",
        password: "",
        profilePic: null,
      }}
      validationSchema={validationSchemaByStep[formStep]}
      onSubmit={(values, { resetForm }) =>
        handleFormSubmit(values, { resetForm })
      }
    >
      {({ handleSubmit, validateForm, setFieldValue, values }) => (
        <Form onSubmit={handleSubmit} className="signUpForm_test">
          <Grid container spacing={2}>
            <>
              <WelcomePage
                formStep={formStep}
                setFormStep={setFormStep}
                setFieldValue={setFieldValue}
                values={values}
              />
              <BasicInformation
                formStep={formStep}
                handleNext={() => handleNext(validateForm)}
                handlePrevious={handlePrevious}
                validateForm={validateForm}
              />
              <ParentInformation
                formStep={formStep}
                handleNext={() => handleNext(validateForm)}
                handlePrevious={handlePrevious}
                validateForm={validateForm}
              />
              <UploadPicture
                formStep={formStep}
                handlePrevious={handlePrevious}
                validateForm={validateForm}
                handleFormSubmit={handleFormSubmit}
                setFieldValue={setFieldValue}
              />
            </>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default PersonalDetailForm;
