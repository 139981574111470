import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { authHeader } from "../common/authHeader";
import { showToast } from "../utils/Toasts";

const initialState = {
  loading: false,
  postFacultyData: [],
  error: null,
};

export const postFacultyData = createAsyncThunk(
  "postFaculty/postFacultyData",
  async (facultyData, thunkAPI) => {
    const requestOptions = {
      headers: authHeader(),
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/teacher`,
        facultyData,
        requestOptions
      );
      if (response.data.success === true) {
        showToast(response.data.data, "success");
      }
      return response.data.data;
    } catch (error) {
      if (error.response.data.success === false) {
        showToast(error.response.data.error.message, "error");
      }
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const facultySlice = createSlice({
  name: "postFaculty",
  initialState,
  reducers: {
    setPostFacultyData: (state, action) => {
      state.postFacultyData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(postFacultyData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(postFacultyData.fulfilled, (state, action) => {
      state.loading = false;
      state.postFacultyData = action.payload;
      state.error = null;
    });
    builder.addCase(postFacultyData.rejected, (state, action) => {
      state.loading = false;
      state.postFacultyData = [];
      state.error = action.payload;
    });
  },
});

export const { setPostFacultyData } = facultySlice.actions;
export default facultySlice.reducer;
