import React from "react";
import { Grid, Paper } from "@mui/material";
import classes from "../English/CoursePrice.module.css";
import Button from "../../common/Button";
import lecturer from "../../assets/images/ousa-chea-gKUC4TMhOiY-unsplash.png";

const ScienceCoursePrice = () => {
  return (
    <>
      <Grid
        container
        spacing={1}
        sx={{
          mt: 3,
          mb: 3,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid item xs={12} sm={12} md={12} lg={5}>
          <Paper
            style={{
              borderRadius: "1rem",
              overflow: "hidden",
              boxShadow: "0 0 4rem rgba(0,0,0,0.1)",
            }}
          >
            <div className={classes.course_price_image} id="register">
              <img src={lecturer} alt="staff" />
              <div className={classes.course_cont}>
                {/* <h3>Class 11</h3> */}
                <p>Science Mastery from Basics to Brilliance with AI</p>
              </div>
            </div>
            <div className={classes.course_details}>
              <h3>Duration: 100 hours</h3>
              <h3>Fees: Rs 999 per Month</h3>
              <h3>Mode of Delivery: Online Recorded Classes</h3>
              <h3>Time Frame: 10 Months</h3>
              <h3>Best and Experienced Faculty</h3>
              <h3>Best Software Technology</h3>
              <h3>AI Powered coaching tailored for success</h3>
            </div>
            <div className={classes.checkout_btn_box}>
              <Button className={classes.checkout_btn}>
                Register for the Course
              </Button>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default ScienceCoursePrice;
