import test from "../../components/assets/images/test.png";
import qualityAsurance from "../../components/assets/images/quality-asurance_79_11zon.png";
import practice from "../../components/assets/images/renz6xfbkm8xzxai6cp2_81_11zon.png";

export const exploreServiceContnet = [
  {
    id: 1,
    title: "Real-World Test Preparation",
    description: `A course designed to adapt to your learning pace, enhancing your NEET and JEE prep`,
    image: test,
    serviceGain: "Adaptive",
    serviceGain1: "Personalized",
    service: "Adapt",
  },
  {
    id: 2,
    title: "Quality Assurance",
    description: `Enhance your study efficiency with tailored learning that focuses on your weak areas.`,
    image: qualityAsurance,
    serviceGain: "Efficient",
    serviceGain1: "Optimized",
    service: "Study Smart",
  },
  {
    id: 3,
    title: "Global Reach",
    description: `Supports multiple languages to cater to a diverse student population.`,
    image: practice,
    serviceGain: "Practice",
    serviceGain1: "Preparation",
    service: "Master",
  },
  // {
  //   id: 4,
  //   title: "Real-Life Application",
  //   description: `Uses real-life examples to make learning relevant and comprehensible.`,
  //   image:
  //     "https://res.cloudinary.com/dbyioi2qq/image/upload/v1713800049/renz6xfbkm8xzxai6cp2.jpg",
  //   serviceGain: "Practice",
  //   serviceGain1: "Preparation",
  //   service: "Master",
  // },
  // {
  //   id: 5,
  //   title: "Empowering All Students",
  //   description: `Provides affordable, scalable education solutions to empower students from all economic backgrounds`,
  //   image:
  //     "https://res.cloudinary.com/dbyioi2qq/image/upload/v1713800049/renz6xfbkm8xzxai6cp2.jpg",
  //   serviceGain: "Practice",
  //   serviceGain1: "Preparation",
  //   service: "Master",
  // },
];
