import { Dialog, Grid, styled } from "@mui/material";
import React, { useEffect } from "react";
import lakshmi from "../../assets/images/Lakshmi.png";
import { useDispatch, useSelector } from "react-redux";
import { setLaksmiModalOpen } from "../../Redux/excutiveDirectorsSlice";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { useNavigate } from "react-router-dom";

const LakshmiPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const laksmiModal = useSelector(
    (state) => state.excutiveDirectorsSlice.laksmiModal
  );

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
    "& .MuiPaper-root": {
      width: "80%",
      maxWidth: "1200px",
    },
  }));

  const handleClose = () => {
    dispatch(setLaksmiModalOpen(!laksmiModal));
    navigate("/leadership");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={laksmiModal}
      >
        <div className="directors_page">
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div>
                <CloseSharpIcon
                  style={{
                    float: "right",
                    fontSize: "4rem",
                    cursor: "pointer",
                  }}
                  onClick={handleClose}
                />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ padding: "0rem 4rem" }}>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <p></p>
              <h1>Lakshmi Marthala</h1>
              <h2>Director - HR</h2>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <div className="krishna_img">
                <img
                  src={lakshmi}
                  alt="lakshmi"
                  className="directors_img"
                  style={{ height: "28rem", width: "28rem" }}
                />
              </div>
            </Grid>
          </Grid>
        </div>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div className="directors_profile_cont">
              <p>
                Lakshmi Marthala brings over 8+ years of experience as a
                results-oriented HR professional, specializing in staffing,
                recruitment, and service delivery management across diverse
                MNCs. Her passion lies in building high-performing teams and
                optimizing service delivery processes. Lakshmi excels at
                managing the full cycle of technical recruitment, from
                identifying top talent to onboarding. She has a proven track
                record of reducing time-to-hire and improving candidate
                engagement.
              </p>
              <p>
                Lakshmi's expertise extends to managing IT resources for
                development projects. Her strong understanding of the business
                side of IT allows her to source the right talent for critical
                projects, contributing to the successful delivery of business
                applications and systems. She is proficient in both onsite and
                offshore delivery models.
              </p>
            </div>
          </Grid>
        </Grid>
      </BootstrapDialog>
    </>
  );
};

export default LakshmiPage;
