import React from "react";
import { Grid } from "@mui/material";
import classes from "../LeaderShip/OurLeaderShip.module.css";

const OurPrograms = () => {
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className={classes.leadership_section}>
            <h1>Our Programs</h1>
            <p>
              Our online recorded classes cover JEE Mains, NEET, CET
              competitive exams, as well as core subjects for grades 4 to 10
              under the CBSE & State curriculum. These classes are
              meticulously designed and conducted by our esteemed faculty, who
              have over a decade of experience in the teaching field. Our
              program ensures comprehensive coverage of all topics, providing
              students with in-depth understanding and ample practice
              opportunities. Each class is crafted to cater to the learning
              needs of students, helping them achieve their academic goals.
              Explore our programs, benefit from expert instruction, and take
              your learning to the next level.
            </p>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default OurPrograms;
