import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
// import { logoutUser } from "../../components/Redux/loginSlice";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";
import Person2Icon from "@mui/icons-material/Person2";
import { Divider } from "@mui/material";

const AdminSidebar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [activeLnk, setActiveLink] = useState("dashboard");

  const handleActiveLink = (activeItem) => {
    setActiveLink(activeItem);
  };

  const handleLogout = () => {
    // dispatch(logoutUser());
    navigate("/");
    window.location.reload();
  };

  return (
    <section className="sidebar">
      {/* <div className="logo_box"> */}
      {/* <button className="admin_logo">Logo</button> */}
      {/* <img src={logo} alt="logo" className="logo" /> */}
      {/* </div> */}
      {/* <p className="border_bottom"></p> */}
      <nav>
        <ul className="menu_items">
          <h2 className="menuitem_name">Dashboard</h2>
          <Link
            to="/dashboard"
            className={`menu_item ${
              activeLnk === "dashboard" ? "menu_item_active" : undefined
            }`}
            onClick={() => {
              handleActiveLink("dashboard");
            }}
          >
            {/* <CastForEducationIcon
              style={{ fontSize: "2.5rem", color: "#364152" }}
            /> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="2.5rem"
              height="2.5rem"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="tabler-icon tabler-icon-dashboard"
            >
              <path d="M12 13m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
              <path d="M13.45 11.55l2.05 -2.05"></path>
              <path d="M6.4 20a9 9 0 1 1 11.2 0z"></path>
            </svg>
            <h3>Dashboard</h3>
          </Link>
          <Divider />
          <h2 className="menuitem_name">Teachers</h2>
          <Link
            to="/facultyDetails"
            className={`menu_item ${
              activeLnk === "faculty" ? "menu_item_active" : undefined
            }`}
            onClick={() => {
              handleActiveLink("faculty");
            }}
          >
            <CastForEducationIcon
              style={{ fontSize: "2.5rem", color: "#364152" }}
            />
            <h3>Teachers</h3>
          </Link>
          <Divider />
          <h2 className="menuitem_name">Students</h2>
          <Link
            to="/students"
            className={`menu_item ${
              activeLnk === "students" ? "menu_item_active" : undefined
            }`}
            onClick={() => {
              handleActiveLink("students");
            }}
          >
            <Person2Icon style={{ fontSize: "2.5rem", color: "#364152" }} />
            <h3>Students</h3>
          </Link>
          <Divider />
        </ul>
      </nav>
    </section>
  );
};

export default AdminSidebar;
