import React from "react";
import classes from "./WelcomePage.module.css";
import { Grid } from "@mui/material";
import Button from "../../common/Button";
import { Link } from "react-router-dom";

const WelcomePage = () => {
  return (
    <>
      <Grid
        container
        spacing={1}
        className={classes.welcome_page_container}
        sx={{ mt: 1 }}
      >
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <div className={classes.welcome_page_content}>
            <h1>Welcome to Certainty Rank Academy</h1>
            <p>
              We are an online learning platform that provides high-quality education 
              to help students excel in their academic pursuits. Our classes are designed to
              prepare students for competitive exams, such as NEET, JEE, and
              CET. Our expert tutors provide comprehensive online coaching and
              exam preparation to help students achieve their academic goals.
              Leveraging advanced AI technology, we personalize learning
              experiences and provide targeted support to each student,
              enhancing their learning journey. In addition to our competitive
              exam coaching, we have also launched English, Maths, and Science
              Clubs for students from 4th grade to 10th grade. These clubs are
              designed to teach students in a more application-oriented way,
              fostering a deeper understanding of core subjects. Our innovative
              approach ensures that students not only learn but also apply their
              knowledge effectively. Join us to empower your child's educational
              journey and help them succeed in all areas of study with the
              support of cutting-edge AI technology.
            </p>
            <Link to="/programs" className={classes.startnow_btn}>
              Enroll Now
            </Link>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default WelcomePage;
