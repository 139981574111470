export function authHeader() {
  // return authorization header with jwt token
  let token = localStorage.getItem("token");
  console.log("toekn:   ",token);
  if (token) {
    console.log("toekn:   ",token);
    return {
      Authorization: "Bearer:" + token,
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "*/*",
      "cache-control": "no-cache",
    };
  } else {
    return {};
  }
}
// export * from "./auth-header";
