import React from "react";
import { Grid } from "@mui/material";
import classes from "./Futures.module.css";
import EastIcon from "@mui/icons-material/East";

const Futures = () => {
  return (
    <>
      <div className={classes.futures_section}>
        <Grid
          container
          spacing={1}
          sx={{ borderBottom: "1px solid #15151519" }}
        >
          <div className={classes.about_main}>
            <Grid item xs={12} sm={12} md={12} lg={7.5}>
              <p className={classes.about_contnet}>
                InnovateTech Hub is at the forefront of technological evolution,
                driving innovation through collaboration between...
              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4.5}>
              <h1 className={classes.about_heading}>About Us</h1>
            </Grid>
          </div>
        </Grid>
        <Grid container spacing={1} sx={{ padding: "100px 0px 0px 0px" }}>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <h1 className={classes.key_features}>Key Features</h1>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <div className={classes.coding}>
              <img
                src="https://res.cloudinary.com/dbyioi2qq/q_auto/v1699943165/static/icon-29png_1699942940_62214.png"
                alt="eye"
                className={classes.eye_image}
              />
              <div className={classes.python}>
                <h1>Python Coding</h1>
                <h3>Strategic Insights</h3>
                <p>Learn Python coding to navigate the future of technology.</p>
              </div>
            </div>
            <div className={classes.coding}>
              <img
                src="https://res.cloudinary.com/dbyioi2qq/q_auto/v1699943465/static/icon-30png_1699943241_64370.png"
                alt="eye"
                className={classes.strategies}
              />
              <div className={classes.python}>
                <h1>Modernization Strategies</h1>
                <h3>Modernization</h3>
                <p>Modernization at Its Finest</p>
              </div>
            </div>
            <div className={classes.coding}>
              <img
                src="https://res.cloudinary.com/dbyioi2qq/q_auto/v1699943465/static/icon-31png_1699943241_26374.png"
                alt="eye"
                className={classes.management_image}
              />
              <div className={classes.python}>
                <h1>Change Management</h1>
                <h3>Leadership Growth</h3>
                <p>
                  Embrace the transformative power of effective change
                  management with InnovateTech Hub's tailored solutions. Learn
                  more today.
                </p>
              </div>
            </div>
          </Grid>
        </Grid>
        <div className={classes.core_services}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div className={classes.coreFeatures_heading}>
                <p>Core Services</p>
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            sx={{ borderBottom: "1px solid #ffffff4c" }}
          >
            <Grid item xs={12} sm={12} md={12} lg={8}>
              <div className={classes.coreFeatures_primary_heading}>
                <h3>Collaborative Workshops</h3>
                <p>Collaborative Workshops</p>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4}>
              <EastIcon
                style={{
                  color: "gold",
                  fontSize: "4rem",
                  float: "right",
                  marginRight: "2rem",
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            sx={{ borderBottom: "1px solid #ffffff4c", mt: 6 }}
          >
            <Grid item xs={12} sm={12} md={12} lg={8}>
              <div className={classes.coreFeatures_primary_heading}>
                <h3>Collaboration Project</h3>
                <p>
                  Discover the transformative power of collaboration and change
                  management to drive your business forward.
                </p>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4}>
              <EastIcon
                style={{
                  color: "gold",
                  fontSize: "4rem",
                  float: "right",
                  marginRight: "2rem",
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            sx={{ borderBottom: "1px solid #ffffff4c", mt: 6 }}
          >
            <Grid item xs={12} sm={12} md={12} lg={8}>
              <div className={classes.coreFeatures_primary_heading}>
                <h3>Speaker Series</h3>
                <p>Collaborative Workshops</p>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4}>
              <EastIcon
                style={{
                  color: "gold",
                  fontSize: "4rem",
                  float: "right",
                  marginRight: "2rem",
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            sx={{ borderBottom: "1px solid #ffffff4c", mt: 6 }}
          >
            <Grid item xs={12} sm={12} md={12} lg={8}>
              <div className={classes.coreFeatures_primary_heading}>
                <h3>Collaborative Workshops</h3>
                <p>Collaborative Workshops</p>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4}>
              <EastIcon
                style={{
                  color: "gold",
                  fontSize: "4rem",
                  float: "right",
                  marginRight: "2rem",
                }}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default Futures;
