import * as Yup from "yup";

export const contactFormSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is  required"),
  lastName: Yup.string().required("Last Name is required"),
  emailAddress: Yup.string()
    .required("Email is required")
    .email("Invalid email address"),
  phoneNumber: Yup.string()
    .matches(
      /^[0-9]{10}$/,
      "Mobile number should be exactly 10 digits and contain only numbers"
    )
    .required("Phone Number is required"),
  message: Yup.string()
    .required("Message is required")
    .max(200, "'Message must be 200 characters or less'"),
});
