import React from "react";
import { Grid } from "@mui/material";
import classes from "./About.module.css";

const About = () => {
  return (
    <>
      <Grid container spacing={1} id="aboutUsSection">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className={classes.about_section}>
            <h1>About Us</h1>
            <ul>
              <li>
              To provide online learning platform that leverages AI technology to help students excel 
              in their preparation for competitive exams, such as NEET, JEE and various state-specific 
              Common Entrance Tests (CET's) across India
              </li>
              <li>To promote, expand, and develop the education sector.</li>
              <li>
                To lay down standards of quality for educational programs.
              </li>
              <li>
                To enunciate just and adequate principles governing the
                education sector and to set up a code of practices for general
                guidance.
              </li>
              <li>
                To enhance each student's learning journey, we personalize learning 
                experiences and provide targeted support. Our expert tutors offer 
                comprehensive online coaching and exam preparation to help students achieve their academic goals.
                In addition to our competitive exam coaching, we have launched English, Math, 
                and Science Clubs for students from 4th to 10th grade, focusing on application-oriented 
                learning to deepen understanding of core subjects.
              </li>
              {/* <li>
                To prepare, edit, publish, launch, acquire, and distribute
                papers, circulars, brochures, periodicals, pamphlets, magazines,
                journals, gazettes, books, and other literary undertakings
                related to online (Live and Recorded Classes) education in the 
                fields of primary, secondary education, and competitive 
                examinations for Engineering and Medical courses in
                India.
              </li> */}
              <li>
                To provide scholarships, awards, and medals related to
                the examinations held by the Certainty Rank Academy.
              </li>
              <li>
                To create opportunities for professors and research scholars in
                India and abroad for the advancement of education.
              </li>
              <li>
                Finally, to conduct other lawful activities those are
                incidental, conducive, or in the interest of society or the
                objectives mentioned above, directly and indirectly.
              </li>
            </ul>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default About;
