// src/slices/photoSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { authHeader } from "../common/authHeader";

// Define the initial state
const initialState = {
  photo: null,
  status: "idle",
  error: null,
};

// Create an async thunk for the API call
export const downloadPhoto = createAsyncThunk(
  "photo/downloadPhoto",
  async (photoId, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/downloadphoto/${photoId}`,       
        {
          method: "POST",
          headers: {
            ...authHeader(),
            Accept: "application/json", // Ensure 'Accept' header is included if required
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch photo");
      }

      const data = await response.blob();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const photoSlice = createSlice({
  name: "photo",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(downloadPhoto.pending, (state) => {
        state.status = "loading";
      })
      .addCase(downloadPhoto.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.photo = action.payload;
      })
      .addCase(downloadPhoto.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export default photoSlice.reducer;
