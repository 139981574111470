import React from "react";
import AboutPage from "../pages/AboutPage/index";
import NewAccordian from "../common/NewAccordian";
import Proposition from "../pages/AboutPage/Proposition";
import ServicesOffered from "../pages/AboutPage/ServicesOffered";
import PaymentModel from "../authentication/SignUp/PaymenyModal";

const Home = () => {
  return (
    <>
      <div>
        {/* <UserInfo /> */}
        {/* <MiniHeader />
        <Header /> */}
        {/* <Carousel /> */}
        {/* <Banner /> */}
        {/* <Aboutus /> */}
        <AboutPage />
        <ServicesOffered />

        <Proposition />
        <NewAccordian />
        {/* <ServicesOffered /> */}
        {/* <Leadership /> */}
        {/* <OurPrograms /> */}
        {/* <AIPoweredCourse /> */}
        {/* <Enrollment /> */}
        {/* <Vision /> */}
        {/* <OurTeam /> */}
        {/* <ExcutiveDirectors /> */}
        {/* <ContactFooter /> */}
        <PaymentModel />
        {/* <Footer /> */}
      </div>
    </>
  );
};

export default Home;
