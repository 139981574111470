// import React from "react";
// import { Grid } from "@mui/material";
// import classes from "./TransformSkills.module.css";
// import Button from "../../common/Button";

// const TransfornSkills = () => {
//   return (
//     <>
//       <Grid container spacing={1} sx={{ mb: 8 }}>
//         <Grid item xs={12} sm={12} md={12} lg={8}>
//           <h1 className={classes.primary_heading}>
//             Transform Your English Skills
//           </h1>
//           <p className={classes.primary_contnet}>
//             Discover how LinguaBoost's AI-driven programs can make learning
//             English exciting and highly effective. From grammar basics to
//             advanced composition, our courses are designed to inspire and
//             challenge learners of all levels.
//           </p>
//         </Grid>
//       </Grid>

//       <Grid container spacing={3}>
//         <Grid item xs={12} sm={12} md={12} lg={6}>
//           <img
//             src="https://res.cloudinary.com/dbyioi2qq/image/upload/v1713888584/ykn9rymgrqv4qqizvypv.jpg"
//             alt="books"
//             className={classes.books_img}
//           />
//           <h2 className={classes.secondary_heading}>
//             Foundation Building Basics
//           </h2>
//           <p className={classes.secondary_contnet}>
//             Learn sentence structure, nouns, verbs, and adjectives, guiding
//             young learners towards language mastery.
//           </p>
//           <Button className={classes.readmore_btn}>Read More</Button>
//         </Grid>
//         <Grid item xs={12} sm={12} md={12} lg={6}>
//           <img
//             src="https://res.cloudinary.com/dbyioi2qq/image/upload/v1713888584/t42ejyqko0c9t2sakyit.jpg"
//             alt="books"
//             className={classes.books_img}
//           />
//           <h2 className={classes.secondary_heading}>
//             Comprehension Enhancement
//           </h2>
//           <p className={classes.secondary_contnet}>
//             Dive deeper into English with studies on modal verbs, passive voice,
//             and more for middle schoolers.
//           </p>
//           <Button className={classes.readmore_btn}>Read More</Button>
//         </Grid>
//       </Grid>
//     </>
//   );
// };

// export default TransfornSkills;

import React, { useRef } from "react";
import styles from "./TransformSkills.module.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import accordianStyles from "../../common/ScienceAccordian.module.css";
import { Grid, Paper } from "@mui/material";


const TransformSkills = () => {
  const transformSkills = useRef(null);

  

  return (
    <>
      <Grid
        container
        spacing={1}
        sx={{ mb: 2, mt: -10 }}
        ref={transformSkills}
        id="transferskills_content"
      >
        <Grid item xs={12} sm={12} md={12} lg={8}>
          <h1 className={styles.primary_heading}>
            Transform Your English Skills
          </h1>

          <p className={styles.primary_contnet}>
            Discover how LinguaBoost's AI-driven programs can make learning
            English exciting and highly effective. From grammar basics to
            advanced composition, our courses are designed to inspire and
            challenge learners of all levels.
          </p>
        </Grid>
      </Grid>
      {/* <Grid container spacing={0}>
        <Grid item xs={12} sm={12} md={12} lg={5}>
          {getImageToShow()}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={7}>
          <Collapse
            defaultActiveKey={["0"]}
            onChange={onChange}
            accordion
            bordered={false}
            // style={{ marginLeft: "10rem" }}
          >
            {transformSkillContnet.map((item, index) => (
              <Panel
                key={index}
                header={
                  <span
                    style={{
                      fontFamily: "Space",
                      fontSize: "20px",
                      fontWeight: 700,
                      color: "#12141D",
                      letterSpacing: "0.5px",
                    }}
                  >
                    {item.title}
                  </span>
                }
                style={{
                  border: "none",
                  background: "#F5F1EE",
                  borderBottom: "1px solid #ccc",
                }}
                className={classes.borderBottom}
              >
                <ul className={classes.details}>
                  <li>{item.grammer}</li>
                  <li>{item.Vocabulary}</li>
                  <li>{item.Reading}</li>
                  <li>{item.writing}</li>
                  <li>{item.ListeningAndSpeaking}</li>
                </ul>
              </Panel>
            ))}
          </Collapse>
        </Grid>
      </Grid> */}
      <div className={accordianStyles.medium_accordian}>
        <Paper className="accordian_paper">
          <h1 id="syllabus">Syllabus</h1>
          <div style={{ border: "3px solid rgb(250, 250, 250)" }}>
            <Accordion className={accordianStyles.accordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ fontSize: "2.5rem" }} />}
                aria-controls="panel1-content"
                id="panel1-header"
                className={accordianStyles.summary}
              >
                Grammar and Writing Skills (10 Hours){" "}
              </AccordionSummary>
              <AccordionDetails className={accordianStyles.details}>
                This section includes foundational elements of English grammar
                and writing skills. Topics covered are:
                <div className="english_grammer">
                  <h4>
                    <b>Sentence Construction:</b>
                  </h4>
                  <p>Understanding the structure and elements of sentences.</p>
                </div>
                <div className="english_grammer">
                  <h4>
                    <b>Nouns and Their Types:</b>
                  </h4>
                  <p>Detailed study of various kinds of nouns.</p>
                </div>
                <div className="english_grammer">
                  <h4>
                    <b>Number and Gender:</b>
                  </h4>
                  <p>
                    Grammatical number (singular and plural) and gender forms.
                  </p>
                </div>
                <div className="english_grammer">
                  <h4>
                    <b>Pronouns and Their Types:</b>
                  </h4>
                  <p>Exploration of different types of pronouns.</p>
                </div>
                <div className="english_grammer">
                  <h4>
                    <b>Verbs and Tenses:</b>
                  </h4>
                  <p>Comprehensive look at verbs and their tenses.</p>
                </div>
                <div className="english_grammer">
                  <h4>
                    <b>Reported Speech:</b>
                  </h4>
                  <p>Rules and applications of indirect speech.</p>
                </div>
                <div className="english_grammer">
                  <h4>
                    <b>Active and Passive Voice:</b>
                  </h4>
                  <p>
                    Transforming sentences between active and passive voice.
                  </p>
                </div>
                <div className="english_grammer">
                  <h4>
                    <b>Modals:</b>
                  </h4>
                  <p>Usage and functions of modal verbs.</p>
                </div>
                <div className="english_grammer">
                  <h4>
                    <b>Adjectives: </b>
                  </h4>
                  <p>Types and usage of adjectives.</p>
                </div>
                <div className="english_grammer">
                  <h4>
                    <b>Articles: </b>
                  </h4>
                  <p>Definite and indefinite articles.</p>
                </div>
                <div className="english_grammer">
                  <h4>
                    <b>Adverbs: </b>
                  </h4>
                  <p>Forms and uses of adverbs.</p>
                </div>
                <div className="english_grammer">
                  <h4>
                    <b>Prepositions: </b>
                  </h4>
                  <p>Role and types of prepositions.</p>
                </div>
                <div className="english_grammer">
                  <h4>
                    <b>Conjunctions: </b>
                  </h4>
                  <p>Different conjunctions and their uses.</p>
                </div>
                <div className="english_grammer">
                  <h4>
                    <b>Letter and Application Writing: </b>
                  </h4>
                  <p>
                    Structure and style of formal and informal letters and
                    applications.
                  </p>
                </div>
                <div className="english_grammer">
                  <h4>
                    <b>Story Writing: </b>
                  </h4>
                  <p>Elements and crafting of stories.</p>
                </div>
                <div className="english_grammer">
                  <h4>
                    <b>Paragraph and Essay Writing: </b>
                  </h4>
                  <p>Techniques for writing coherent paragraphs and essays.</p>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion className={accordianStyles.accordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ fontSize: "2.5rem" }} />}
                aria-controls="panel1-content"
                id="panel1-header"
                className={accordianStyles.summary}
              >
                Comprehensive Grammar and Writing Skills (15 Hours)
              </AccordionSummary>
              <AccordionDetails className={accordianStyles.details}>
                This segment provides an extensive exploration of grammar and
                writing, including:
                <div className="english_grammer">
                  <h4>
                    <b>Noun, Pronoun, Verb: </b>
                  </h4>
                  <p>In-depth study of these fundamental parts of speech.</p>
                </div>
                <div className="english_grammer">
                  <h4>
                    <b>Tenses and Voice: </b>
                  </h4>
                  <p>
                    Detailed examination of tense usage and active/passive voice
                    transformation.
                  </p>
                </div>
                <div className="english_grammer">
                  <h4>
                    <b>Adjective and Adverb: </b>
                  </h4>
                  <p>Usage and types.</p>
                </div>
                <div className="english_grammer">
                  <h4>
                    <b>Sentences and Phrases: </b>
                  </h4>
                  <p>
                    Crafting and understanding complex sentences and phrases.
                  </p>
                </div>
                <div className="english_grammer">
                  <h4>
                    <b>Subject-Verb Agreement: </b>
                  </h4>
                  <p>Ensuring grammatical consistency.</p>
                </div>
                <div className="english_grammer">
                  <h4>
                    <b>Reported Speech: </b>
                  </h4>
                  <p>Advanced rules and practices.</p>
                </div>
                <div className="english_grammer">
                  <h4>
                    <b>Framing Questions: </b>
                  </h4>
                  <p>Techniques for forming different types of questions.</p>
                </div>
                <div className="english_grammer">
                  <h4>
                    <b>Prepositions and Conjunctions: </b>
                  </h4>
                  <p>Advanced usage.</p>
                </div>
                <div className="english_grammer">
                  <h4>
                    <b>Punctuation: </b>
                  </h4>
                  <p>Types and usage of adjectives.</p>
                </div>
                <div className="english_grammer">
                  <h4>
                    <b>Articles: </b>
                  </h4>
                  <p>Correct punctuation usage.</p>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion className={accordianStyles.accordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ fontSize: "2.5rem" }} />}
                aria-controls="panel1-content"
                id="panel1-header"
                className={accordianStyles.summary}
              >
                Advanced Grammar Concepts (15 Hours){" "}
              </AccordionSummary>
              <AccordionDetails className={accordianStyles.details}>
                This part delves into more advanced grammatical structures and
                their applications:
                <div className="english_grammer">
                  <h4>
                    <b>Determiners and Linking Words: </b>
                  </h4>
                  <p>Usage and types.</p>
                </div>
                <div className="english_grammer">
                  <h4>
                    <b>Adverbs: </b>
                  </h4>
                  <p>Focus on place and type.</p>
                </div>
                <div className="english_grammer">
                  <h4>
                    <b>Tense Forms: </b>
                  </h4>
                  <p>Complex tense forms and their applications.</p>
                </div>
                <div className="english_grammer">
                  <h4>
                    <b>Clauses and Passivation: </b>
                  </h4>
                  <p>Different types of clauses and passive constructions.</p>
                </div>
                <div className="english_grammer">
                  <h4>
                    <b>Comparative and Superlative Adjectives: </b>
                  </h4>
                  <p>Formation and use.</p>
                </div>
                <div className="english_grammer">
                  <h4>
                    <b>Modal Auxiliaries: </b>
                  </h4>
                  <p>Detailed study of modals.</p>
                </div>
                <div className="english_grammer">
                  <h4>
                    <b>Sentence Structure: </b>
                  </h4>
                  <p>Word order and sentence types</p>
                </div>
                <div className="english_grammer">
                  <h4>
                    <b>Reported Speech: </b>
                  </h4>
                  <p>Advanced techniques and rules.</p>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion className={accordianStyles.accordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ fontSize: "2.5rem" }} />}
                aria-controls="panel1-content"
                id="panel1-header"
                className={accordianStyles.summary}
              >
                Comprehensive Grammar and Writing (20 Hours){" "}
              </AccordionSummary>
              <AccordionDetails className={accordianStyles.details}>
                This extensive section covers a broad range of topics necessary
                for mastery of English grammar and writing:
                <div className="english_grammer">
                  <h4>
                    <b>Sentence and Word Order: </b>
                  </h4>
                  <p> Structure and organization.</p>
                </div>
                <div className="english_grammer">
                  <h4>
                    <b>Subject and Predicate: </b>
                  </h4>
                  <p>Detailed understanding.</p>
                </div>
                <div className="english_grammer">
                  <h4>
                    <b>Parts of Speech: </b>
                  </h4>
                  <p>
                    {" "}
                    In-depth study of nouns, pronouns, verbs, adjectives, and
                    adverbs.
                  </p>
                </div>
                <div className="english_grammer">
                  <h4>
                    <b>Tenses and Prepositions: </b>
                  </h4>
                  <p>Comprehensive usage.</p>
                </div>
                <div className="english_grammer">
                  <h4>
                    <b>Conjunctions and Interjections: </b>
                  </h4>
                  <p>Types and uses.</p>
                </div>
                <div className="english_grammer">
                  <h4>
                    <b>Articles: </b>
                  </h4>
                  <p>Definite and indefinite.</p>
                </div>
                <div className="english_grammer">
                  <h4>
                    <b>Punctuation and Capital Letters: </b>
                  </h4>
                  <p>Correct usage in writing.</p>
                </div>
                <div className="english_grammer">
                  <h4>
                    <b>Voice and Speech: </b>
                  </h4>
                  <p>Active/passive voice and direct/indirect speech.</p>
                </div>
                <div className="english_grammer">
                  <h4>
                    <b>Comprehension and Creative Writing: </b>
                  </h4>
                  <p>Skills for understanding and crafting texts.</p>
                </div>
                <div className="english_grammer">
                  <h4>
                    <b>Story and Essay Writing: </b>
                  </h4>
                  <p>Techniques and examples.</p>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion className={accordianStyles.accordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ fontSize: "2.5rem" }} />}
                aria-controls="panel1-content"
                id="panel1-header"
                className={accordianStyles.summary}
              >
                Integrated Language Skills (20 Hours){" "}
              </AccordionSummary>
              <AccordionDetails className={accordianStyles.details}>
                This section emphasizes the integration of reading and writing
                skills with a strong grammatical foundation:
                <div className="english_grammer">
                  <h4>
                    <b>Reading Comprehension: </b>
                  </h4>
                  <p>
                    Skills such as conceptual understanding, decoding,
                    analyzing, inferring, interpreting, and vocabulary building
                  </p>
                </div>
                <div className="english_grammer">
                  <h4>
                    <b>Writing Skills and Grammar: </b>
                  </h4>
                  <p>
                    Expressing opinions, reasoning, justifying, illustrating,
                    appropriateness of style and tone, using the correct format.
                  </p>
                </div>
                <div className="english_grammer">
                  <h4>
                    <b>Language through Literature: </b>
                  </h4>
                  <p>
                    Appreciating and applying literary conventions, extracting
                    relevant information, identifying central and sub-themes.
                  </p>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion className={accordianStyles.accordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ fontSize: "2.5rem" }} />}
                aria-controls="panel1-content"
                id="panel1-header"
                className={accordianStyles.summary}
              >
                Advanced Integrated Language Skills (20 Hours){" "}
              </AccordionSummary>
              <AccordionDetails className={accordianStyles.details}>
                This segment focuses on advanced comprehension and writing
                skills:
                <div className="english_grammer">
                  <h4>
                    <b>Reading Comprehension: </b>
                  </h4>
                  <p>Similar to section 5, emphasizing advanced skills.</p>
                </div>
                <div className="english_grammer">
                  <h4>
                    <b>Writing Skills and Grammar: </b>
                  </h4>
                  <p>
                    Creative expression, reasoning, justifying, illustrating,
                    and fluency in using appropriate styles and formats.
                  </p>
                </div>
                <div className="english_grammer">
                  <h4>
                    <b>Language through Literature: </b>
                  </h4>
                  <p>
                    Advanced skills in recalling, reasoning, appreciating
                    literary works, identifying themes.
                  </p>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </Paper>
      </div>
    </>
  );
};

export default TransformSkills;
