// src/components/UploadPicture.js

import React, { useState } from "react";
import user from "../../assets/images/user.68cb0bff29e4bb329a2d (1).png";
import { Grid, TextField, IconButton, InputAdornment } from "@mui/material";
import { ErrorMessage, Field } from "formik";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Button from "../../common/Button";

const UploadPicture = ({
  formStep,
  handlePrevious,
  handleFormSubmit,
  setFieldValue,
}) => {
  const [imageUrl, setImageUrl] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleChange = (e) => {
    const selectedFile = e.target.files[0];
    setFieldValue("profilePic", selectedFile);
    setImageUrl(URL.createObjectURL(selectedFile));
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleSubmit = () => {
    handleFormSubmit();
  };

  return (
    <>
      {formStep === 3 && (
        <>
          <Grid
            container
            spacing={2}
            sx={{ marginLeft: { xs: "2rem", sm: "2rem" } }}
          >
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <div className="user_image_box">
                <img
                  src={imageUrl === null ? user : imageUrl}
                  alt=""
                  className="user_image"
                />
                <h1>Select a file</h1>
                <input
                  onChange={handleChange}
                  type="file"
                  name="profilePic"
                  id="profilePic"
                  accept="image/png, image/jpeg"
                  placeholder="choose"
                  className="hidden"
                />
                <p>Click to upload picture</p>
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <h3 className="form_label">Password</h3>
              <Field name="password">
                {({ field }) => (
                  <TextField
                    {...field}
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    fullWidth
                    inputProps={{
                      style: { fontSize: "1.5rem" },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? (
                              <Visibility style={{ fontSize: "2.3rem" }} />
                            ) : (
                              <VisibilityOff style={{ fontSize: "2.3rem" }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </Field>
              <ErrorMessage name="password" component="div" className="error" />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <h3 className="form_label">Confirm Password</h3>
              <Field name="confirmPassword">
                {({ field }) => (
                  <TextField
                    {...field}
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder="Confirm Password"
                    fullWidth
                    inputProps={{
                      style: { fontSize: "1.5rem" },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowConfirmPassword}
                            edge="end"
                          >
                            {showConfirmPassword ? (
                              <Visibility style={{ fontSize: "2.3rem" }} />
                            ) : (
                              <VisibilityOff style={{ fontSize: "2.3rem" }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </Field>
              <ErrorMessage
                name="confirmPassword"
                component="div"
                className="error"
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <div className="pre_nxt_btn_box">
                <Button className="previous_btn" onClick={handlePrevious}>
                  Previous
                </Button>

                <Button className="next_btn" onClick={handleSubmit}>
                  Submit
                </Button>
              </div>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default UploadPicture;
