import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { showToast } from "../utils/Toasts";

export const postContactData = createAsyncThunk(
  "contact/postContactData",
  async (userData, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/contactus`,
        userData
      );

      if (response.status === 201) {
        const responseData = response.data;
        showToast("Thank you for reaching out ! We will contact you soon.");
        return { payload: responseData.success };
      } else {
        showToast(response);
      }
    } catch (error) {
      showToast(error);
    }
  }
);

const contactFormSlice = createSlice({
  name: "contact",
  initialState: {
    loading: false,
    success: false,
  },
  reducers: {
    setSuccess: (state, action) => {
      state.success = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postContactData.pending, (state) => {
        state.loading = true;
      })
      .addCase(postContactData.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(postContactData.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { setSuccess } = contactFormSlice.actions;
export default contactFormSlice.reducer;
