import React, { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { fetchPdf } from "../Redux/pdfSlice";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  Container,
  Paper,
  ListItemSecondaryAction,
  IconButton,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { useParams } from "react-router-dom";

const PdfDownloader = () => {
  const { programName } = useParams();

  const capitalizeAllLetters = useCallback((string) => {
    return string.toUpperCase();
  }, []);

  const capitalizedProgramName = useMemo(
    () => capitalizeAllLetters(programName),
    [programName, capitalizeAllLetters]
  );

  const dispatch = useDispatch();

  const handleDownload = (fileName) => {
    dispatch(fetchPdf(fileName));
  };

  // useEffect(() => {
  //   if (pdfUrl) {
  //     const link = document.createElement("a");
  //     link.href = pdfUrl.url;
  //     link.setAttribute("download", pdfUrl.fileName);
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //     URL.revokeObjectURL(pdfUrl.url);
  //   }
  // }, [pdfUrl]);

  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Container maxWidth="sm">
        <Paper elevation={10} sx={{ p: 3 }}>
          <Typography variant="h4" sx={{ mb: 3, textAlign: "center" }}>
            Download Files
          </Typography>
          <List>
            {capitalizedProgramName === "NEET" && (
              <>
                <ListItem sx={{ borderBottom: "1px solid #ddd" }}>
                  <ListItemText
                    primary="NEET_Syllabus_2024"
                    primaryTypographyProps={{ fontSize: "2rem" }}
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" color="primary">
                      <DownloadIcon
                        style={{ fontSize: "2.5rem" }}
                        onClick={() =>
                          handleDownload("NEET_Syllabus_2024.pdf")
                        }
                      />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem sx={{ borderBottom: "1px solid #ddd" }}>
                  <ListItemText
                    primary="NEET Question Papers - 2022"
                    primaryTypographyProps={{ fontSize: "2rem" }}
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" color="primary">
                      <DownloadIcon
                        style={{ fontSize: "2.5rem" }}
                        onClick={() => handleDownload("NEET-QP-2022.pdf")}
                      />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem sx={{ borderBottom: "1px solid #ddd" }}>
                  <ListItemText
                    primary="NEET Physics Paper with Solutions - 2023"
                    primaryTypographyProps={{ fontSize: "2rem" }}
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" color="primary">
                      <DownloadIcon
                        style={{ fontSize: "2.5rem" }}
                        onClick={() =>
                          handleDownload("NEET_PHYSICS-AP-2023.pdf")
                        }
                      />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </>
            )}
            {capitalizedProgramName === "JEE" && (
              <>
                <ListItem sx={{ borderBottom: "1px solid #ddd" }}>
                  <ListItemText
                    primary="Physics-JEE-Main and KCET"
                    primaryTypographyProps={{ fontSize: "2rem" }}
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" color="primary">
                      <DownloadIcon
                        style={{ fontSize: "2.5rem" }}
                        onClick={() =>
                          handleDownload("JEEM-KCET-Physics-2024.pdf")
                        }
                      />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem sx={{ borderBottom: "1px solid #ddd" }}>
                  <ListItemText
                    primary="Chemistry-JEE-Main and KCET"
                    primaryTypographyProps={{ fontSize: "2rem" }}
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" color="primary">
                      <DownloadIcon
                        style={{ fontSize: "2.5rem" }}
                        onClick={() =>
                          handleDownload("JEEM-KCET-Chemistry-2024.pdf")
                        }
                      />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </>
            )}
            {capitalizedProgramName === "KCET" && (
              <>
                <ListItem sx={{ borderBottom: "1px solid #ddd" }}>
                  <ListItemText
                    primary="Final-KCET Syllabus - 2024"
                    primaryTypographyProps={{ fontSize: "2rem" }}
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" color="primary">
                      <DownloadIcon
                        style={{ fontSize: "2.5rem" }}
                        onClick={() => handleDownload("KCET_Syllabus_2024.pdf")}
                      />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem sx={{ borderBottom: "1px solid #ddd" }}>
                  <ListItemText
                    primary="KCET-Physics Question Paper - 2024"
                    primaryTypographyProps={{ fontSize: "2rem" }}
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" color="primary">
                      <DownloadIcon
                        style={{ fontSize: "2.5rem" }}
                        onClick={() =>
                          handleDownload("KCET_Physics_QP_ 2024.pdf")
                        }
                      />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem sx={{ borderBottom: "1px solid #ddd" }}>
                  <ListItemText
                    primary="KCET-Chemistry Question Paper - 2024"
                    primaryTypographyProps={{ fontSize: "2rem" }}
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" color="primary">
                      <DownloadIcon
                        style={{ fontSize: "2.5rem" }}
                        onClick={() =>
                          handleDownload("KCET_Chemistry_ QP_2024.pdf")
                        }
                      />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem sx={{ borderBottom: "1px solid #ddd" }}>
                  <ListItemText
                    primary="KCET-Maths Question Paper - 2024"
                    primaryTypographyProps={{ fontSize: "2rem" }}
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" color="primary">
                      <DownloadIcon
                        style={{ fontSize: "2.5rem" }}
                        onClick={() => handleDownload("KCET_Maths_QP_2024.pdf")}
                      />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem sx={{ borderBottom: "1px solid #ddd" }}>
                  <ListItemText
                    primary="KCET-Biology Question Paper - 2024"
                    primaryTypographyProps={{ fontSize: "2rem" }}
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" color="primary">
                      <DownloadIcon
                        style={{ fontSize: "2.5rem" }}
                        onClick={() =>
                          handleDownload("KCET_Biology_QP_2024.pdf")
                        }
                      />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </>
            )}

            {capitalizedProgramName === "8TH STANDARD" && (
              <>
                <ListItem sx={{ borderBottom: "1px solid #ddd" }}>
                  <ListItemText
                    primary="Science-CBSE KSEEB"
                    primaryTypographyProps={{ fontSize: "2rem" }}
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" color="primary">
                      <DownloadIcon
                        style={{ fontSize: "2.5rem" }}
                        onClick={() =>
                          handleDownload("8th-Science-CBSC_KSEEB-2024.pdf")
                        }
                      />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem sx={{ borderBottom: "1px solid #ddd" }}>
                  <ListItemText
                    primary="Maths-CBSE KSEEB"
                    primaryTypographyProps={{ fontSize: "2rem" }}
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" color="primary">
                      <DownloadIcon
                        style={{ fontSize: "2.5rem" }}
                        onClick={() =>
                          handleDownload("8th-Maths-CBSC_KSEEB-2024.pdf")
                        }
                      />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem sx={{ borderBottom: "1px solid #ddd" }}>
                  <ListItemText
                    primary="English-CBSE KSEEB"
                    primaryTypographyProps={{ fontSize: "2rem" }}
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" color="primary">
                      <DownloadIcon
                        style={{ fontSize: "2.5rem" }}
                        onClick={() =>
                          handleDownload("8th-English-CBSC_KSEEB-2024.pdf")
                        }
                      />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </>
            )}

            {capitalizedProgramName === "9TH STANDARD" && (
              <>
                <ListItem sx={{ borderBottom: "1px solid #ddd" }}>
                  <ListItemText
                    primary="Science-CBSE KSEEB"
                    primaryTypographyProps={{ fontSize: "2rem" }}
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" color="primary">
                      <DownloadIcon
                        style={{ fontSize: "2.5rem" }}
                        onClick={() =>
                          handleDownload("9th-Science-CBSC_KSEEB-2024.pdf")
                        }
                      />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem sx={{ borderBottom: "1px solid #ddd" }}>
                  <ListItemText
                    primary="Maths-CBSE KSEEB"
                    primaryTypographyProps={{ fontSize: "2rem" }}
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" color="primary">
                      <DownloadIcon
                        style={{ fontSize: "2.5rem" }}
                        onClick={() =>
                          handleDownload("9th-Maths-CBSC_KSEEB-2024.pdf")
                        }
                      />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem sx={{ borderBottom: "1px solid #ddd" }}>
                  <ListItemText
                    primary="English-CBSE KSEEB"
                    primaryTypographyProps={{ fontSize: "2rem" }}
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" color="primary">
                      <DownloadIcon
                        style={{ fontSize: "2.5rem" }}
                        onClick={() =>
                          handleDownload("9th-English-CBSC_KSEEB-2024.pdf")
                        }
                      />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </>
            )}
            {capitalizedProgramName === "10TH STANDARD" && (
              <>
                <ListItem sx={{ borderBottom: "1px solid #ddd" }}>
                  <ListItemText
                    primary="10th Syllabus"
                    primaryTypographyProps={{ fontSize: "2rem" }}
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" color="primary">
                      <DownloadIcon
                        style={{ fontSize: "2.5rem" }}
                        onClick={() =>
                          handleDownload("10th_Syllabus_CBSE_2204.pdf")
                        }
                      />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem sx={{ borderBottom: "1px solid #ddd" }}>
                  <ListItemText
                    primary="Science-CBSE KSEEB"
                    primaryTypographyProps={{ fontSize: "2rem" }}
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" color="primary">
                      <DownloadIcon
                        style={{ fontSize: "2.5rem" }}
                        onClick={() =>
                          handleDownload("10th_Science_CBSC_KSEEB_2024.pdf")
                        }
                      />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem sx={{ borderBottom: "1px solid #ddd" }}>
                  <ListItemText
                    primary="Maths-CBSE KSEEB"
                    primaryTypographyProps={{ fontSize: "2rem" }}
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" color="primary">
                      <DownloadIcon
                        style={{ fontSize: "2.5rem" }}
                        onClick={() =>
                          handleDownload("10th_Maths_CBSC_KSEEB_2024.pdf")
                        }
                      />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </>
            )}
          </List>
        </Paper>
      </Container>
    </Box>
  );
};

export default PdfDownloader;
